import React from "react";
import {useFormik} from "formik";
import { useAlert } from "react-alert";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import {Card, Col, Row} from "react-bootstrap";
import * as Yup from "yup";
import {FormattedMessage} from "react-intl";
import {Save} from '@material-ui/icons';
import QRCode from 'qrcode.react';
import { Button, TextField } from "@material-ui/core";

import { useSubheader } from "../../../../_metronic/layout";
import useIsNew from "../../../hooks/UseIsNew";
import useDoctor from "../hooks/UseDoctor";
import { useTeams } from "../../Team";
import SectionHeader from "../../../components/layout/SectionHeader";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import * as DoctorService from "../../../services/DoctorService";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";

function DoctorPage({intl, ...props}) {
    // state
    const [isNewDoctor, existingDoctorId] = useIsNew("/doctor-new", "/doctor-edit/:id");
    const alert = useAlert();
    const history = useHistory();
    const subheader = useSubheader();
    const teams = useTeams();
    const doctor = useDoctor(existingDoctorId);
    const isLoading = ((isNewDoctor && !teams) || (!isNewDoctor && (!teams || !doctor)));
    const qrCode = `otpauth://totp/Patient%20monitoring:${doctor?.email}?secret=${doctor?.twoFactorSecret}&issuer=Formula%20medicine`;

    // effects
    useEffect(() => {
        async function populateFields() {
            if (teams) {
                if (doctor) {
                    formik.setFieldValue('lastName', doctor.lastName);
                    formik.setFieldValue('firstName', doctor.firstName);
                    formik.setFieldValue('tipoMedico', doctor.tipoMedico);
                    formik.setFieldValue('email', doctor.email);
                    formik.setFieldValue('voipUser', doctor.voipUser);
                    formik.setFieldValue('voipPassword', doctor.voipPassword);
                    function filterTeamsForDoctor() {
                        // Prevent page failure in case doctor.teams is undefined
                        if (doctor.teams) {
                            return teams.filter(
                                (team) => (doctor.teams.find((teamId) => team.id === teamId) !== undefined)
                            )
                        }
                        return [];
                    }
                    const doctorsTeams = filterTeamsForDoctor();
                    formik.setFieldValue("teams", doctorsTeams);
                } else {
                    formik.resetForm();
                }
            }
        }
        populateFields();
    }, [teams, doctor]);

    useEffect(() => {
        if (isNewDoctor){
            subheader.setTitle(intl.formatMessage({ id: "PAGES.DOCTOR_NEW" }));
        } else {
            subheader.setTitle(intl.formatMessage({ id: "PAGES.DOCTOR_EDIT" }));
        }
    });

    // handlers

    async function handleSubmit(values) {
        let send = {
            ...values,
            teams: values.teams.map((team) => team.id),
        }
        let result;
        let success = false;
        if (isNewDoctor) {
            result = await DoctorService.addDoctor(send);
            if (result.status === Constants.STATUTS_201) {
                let doctor = await result.json();
                history.push(`/doctor-edit/${doctor.id}`);
                success = true;
            }
        } else {
            result = await DoctorService.updateDoctor(existingDoctorId, send);
            if (result.status === Constants.STATUTS_200) {
                success = true;
            }
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    // formik

    let passwordSchema = Yup.string();
    if (isNewDoctor) {
        passwordSchema = passwordSchema.required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' }));
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        tipoMedico: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'ERRORS.WRONG_EMAIL_FORMAT' }))
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        password: passwordSchema,
        voipUser: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        voipPassword: passwordSchema,
        teams: Yup.array(),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            tipoMedico: '',
            email: '',
            password: '',
            voipUser: '0',
            voipPassword: '0',
            teams: [],
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    return (
        <Card>
            {isLoading && <LoadingSpinner />}
            <form onSubmit={formik.handleSubmit}>
                <Card.Body>
                    <SectionHeader messageId="GENERAL.STRUCTUR"/>
                    <Row>
                        <Col sm={6} md={4}>
                            <TextField
                                label={`${intl.formatMessage({ id: 'FIELD.LASTNAME' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="lastName"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("lastName")}
                            />
                            {formik.touched.lastName && formik.errors.lastName ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.lastName}</div>
                                </div>
                                : null}
                        </Col>
                        <Col sm={6} md={4}>
                            <TextField
                                label={`${intl.formatMessage({ id: 'FIELD.FIRSTNAME' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="firstName"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("firstName")}
                            />
                            {formik.touched.firstName && formik.errors.firstName ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.firstName}</div>
                                </div>
                                : null}
                        </Col>
                        <Col sm={6} md={4}>
                            <TextField
                                label={`${intl.formatMessage({ id: 'FIELD.TYPE' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="email"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("tipoMedico")}
                            />
                            {formik.touched.tipoMedico && formik.errors.tipoMedico ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.tipoMedico}</div>
                                </div>
                                : null}
                        </Col>
                        <Col sm={6} md={4}>
                            <TextField
                                label={`${intl.formatMessage({ id: 'USER.EMAIL' })} *`}
                                autoComplete="false"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="email"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                                : null}
                        </Col>
                        <Col sm={6} md={4}>
                            <TextField
                                label={intl.formatMessage({ id: 'USER.PASSWORD' }) + (isNewDoctor ? " *" : "")}
                                type="password"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="password"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("password")}
                            />
                            {formik.touched.password && formik.errors.password ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.password}</div>
                                </div>
                                : null}
                        </Col>
                        <Col>
                            <FormikAutocomplete
                                options={teams || []}
                                labelName="name"
                                fieldName="teams"
                                formik={formik}
                                multiple
                                label={`${intl.formatMessage({ id: 'FIELD.TEAMS' })}`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} md={4}>
                            <TextField
                                hidden
                                label={`${intl.formatMessage({ id: 'FIELD.VOIP_INTERNAL' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="voipUser"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("voipUser")}
                            />
                            {formik.touched.voipUser && formik.errors.voipUser ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.voipUser}</div>
                                </div>
                                : null}
                        </Col>
                        <Col sm={6} md={4}>
                            <TextField
                                hidden
                                label={intl.formatMessage({ id: 'FIELD.VOIP_PASSWORD' }) + (isNewDoctor ? " *" : "")}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="voipPassword"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("voipPassword")}
                            />
                            {formik.touched.voipPassword && formik.errors.voipPassword ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.voipPassword}</div>
                                </div>
                                : null}
                        </Col>
                    </Row>
                    {!isNewDoctor &&
                        <>
                            <SectionHeader messageId="GENERAL.QR_CODE"/>
                            <Row>
                                <Col md={4}>
                                    <QRCode value={qrCode} size={290} level={"H"} includeMargin={true} />
                                </Col>
                            </Row>
                        </>
                    }
                </Card.Body>
                <Card.Footer>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!formik.isValid || !formik.dirty}
                        endIcon={<Save/>}
                    >
                        <FormattedMessage id="ACTIONS.SAVE" />
                    </Button>

                    <span className="text-muted float-right mt-4">* <FormattedMessage id="GENERAL.REQUIRED_FIELDS" /></span>
                </Card.Footer>
            </form>
        </Card>
    )
}

export default injectIntl(DoctorPage);
