import {useState, useEffect, useCallback} from "react";

import * as Constants from "../Constants";

/**
 * Hook that enables the pagination of data returned by a datasource function.
 * The datasource function must accept an object with properties page, limit,
 * and whatever other properties are within the filter.
 * The datasource function should return a promise from a fetch or equivalent.
 * The contents of the result must be a json that contains a data array and a totalElements
 * field.
 * 
 * The filter must be an object, whose properties will be spread in the call to the datasource function. 
 * 
 * This hook returns an object with many useful properties.
 * 
 * rows is the data from the datasource for the latest request
 * 
 * page is the current page number
 * 
 * setPage allows setting the page that should be requested
 * 
 * filter is the currently set filter
 * 
 * setFilter sets the filter
 * 
 * totalElements is the total number of elements for the current filters
 * 
 * commit allows refreshing the search.
 * After setting new filters and changing page, call commit to perform the new search.
 * 
 */
export default function usePagination(dataSourceFunction) {
    // state
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [filter, setFilter] = useState({});
    const [search, setSearch] = useState(false);

    // effects
    useEffect(() => {
        async function fetchRows() {
            const result = await dataSourceFunction(
                {
                    page: page,
                    limit: Constants.rowsPerPage,
                    ...filter,
                }
            );
            if (result.status === 200) {
                const resp = await result.json();
                if (resp.data.length === 0) {
                    setRows([]);
                    setTotalElements(0);
                } else {
                    setRows(resp.data);
                    setTotalElements(resp.totalElements);
                }
            } else {
                setRows([]);
            }
        }

        async function doSearch() {
            if (search) {
                await fetchRows();
                setSearch(false);
            }
        }
        
        doSearch();
    }, [search, dataSourceFunction, page, filter]);

    const commit = useCallback(() => {
        setSearch(true);
    }, []);

    return {rows, page, setPage, filter, setFilter, commit, totalElements};
}
