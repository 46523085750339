import React from "react";
import { Button, ButtonGroup, TextField } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { Col, Row } from "react-bootstrap";
import { ErrorMessage, FieldArray, useFormikContext } from "formik";

import FormikAutocomplete from "./FormikAutocomplete";
import { usePharmacons } from "../../modules/Pharmacon";
import { injectIntl, useIntl } from "react-intl";
import { FormikErrorComponent } from "./FormikErrorComponent";

function TherapyInput({...props}) {

    const drugs = usePharmacons();
    const formik = useFormikContext();
    const intl = useIntl();

    return (
        <FieldArray
            name={"therapies"}
            render={arrayHelpers => (
                <>
                {formik.values.therapies.map( (val, index) =>
                    <Row key={index}>
                        <Col md={6}>
                            <FormikAutocomplete
                                options={drugs || []}
                                fieldName={`therapies[${index}].pharmacon`}
                                formik={formik}
                                labelName="name"
                                label={intl.formatMessage({id: "FIELD.PHARMACON"})}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <ErrorMessage name={`therapies[${index}].pharmacon`} component={FormikErrorComponent}/>
                        </Col>
                        <Col>
                            <TextField
                                label={intl.formatMessage({id: "FIELD.POSOLOGY"})}
                                {...formik.getFieldProps(`therapies[${index}].posology`)}
                                onChange={formik.handleChange}
                                value={formik.values.therapies[index].posology}
                                multiline
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <ErrorMessage name={`therapies[${index}].posology`} component={FormikErrorComponent}/>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <ButtonGroup color="primary" margin="normal">
                            <Button variant="outlined" onClick={() => arrayHelpers.push({posology: ""})}><Add/></Button>
                            <Button variant="outlined" onClick={() => arrayHelpers.pop()}><Remove/></Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                </>
            )}
        />
    )
}

export default injectIntl(TherapyInput);
