import React, { useEffect, useState } from 'react';
import { injectIntl } from "react-intl";

import * as StringUtils from '../../StringUtils';

import Autocomplete from '@material-ui/lab/Autocomplete';


import {
    toAbsoluteUrl
} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import {
    TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

import * as UtilService from '../../services/UtilService';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function DrugsTab({ intl, ...props }) {
    const classes = useStyles();

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [drugs, setDrugs] = useState([]);
    const [selectedDrug, setSelectedDrug] = useState(null);

    const [selectedDrugs, setSelectedDrugs] = useState([]);

    const chooseFarmaco = async (e, value) => {
        if (!StringUtils.isEmpty(value) && value.length >= 3) {
            const listDrugs = await (await UtilService.getFarmaci({ descrizione: value })).json();
            setDrugs(listDrugs.data);
        }
    }

    const ChangeSelectedFarmaco = (e, value) => {
        if (value) {
            setSelectedDrug({
                ...selectedDrug,
                description: value.descrizione,
                package: value.confezione,
                minsanCode: value.codMin,
                activePrincipleCode: value.principioAttivo,
                activePrincipleDescription: value.principioAttivoDescrizioneEstesa,
                company: value.descrizioneDitta
            });

            setSelectedDrugs(selectedDrugs => [...selectedDrugs, { farmaco: value.descrizione, confezione: value.confezione }]);
        } else {
            setSelectedDrug(null);
        }
        setDrugs([])
    }

    const updateFieldChanged = index => e => {
        let newArr = [...selectedDrugs];
        switch (e.target.name) {
            case 'quantita':
                newArr[index].quantita = e.target.value;
                break;
            case 'inizioDa':
                newArr[index].inizioDa = e.target.value;
                break;
            case 'posologia':
                newArr[index].posologia = e.target.value;
                break;
            case 'note':
                newArr[index].Note = e.target.value;
                break;
            default:
        }

        setSelectedDrugs(newArr);
    }

    const removeFromSelectedDrugs = index => e => {
        let newArr = [...selectedDrugs];
        if (index > -1) {
            newArr.splice(index, 1);
        }

        setSelectedDrugs(newArr);
    }


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Cerca farmaco</h6>
                        <hr style={{ margin: "0" }} />
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Autocomplete
                                id="combo-box-demo"
                                onInputChange={chooseFarmaco}
                                onChange={ChangeSelectedFarmaco}
                                onClose={() => { setDrugs([]) }}
                                options={drugs}
                                noOptionsText=""
                                getOptionLabel={(option) => option.descrizione + '*' + option.confezione + ' - ' + option.principioAttivoDescrizioneEstesa}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={`Inserire codice o descrizione farmaco`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                />}
                            />

                        </div>
                        {/* {selectedDrug !== null ?
                            <div className="col-md-6">
                                <h6 className="text-primary">Hai selezionato:</h6>
                                <div className="labelFarmaci">Farmaco:</div>
                                {selectedDrug.description + '*' + selectedDrug.package}

                                <div className="labelFarmaci">AIC/Minsan:</div>
                                {selectedDrug.minsanCode}

                                <div className="labelFarmaci">Principio Attivo:</div>
                                {selectedDrug.activePrincipleCode + '-' + selectedDrug.activePrincipleDescription}

                                <div className="labelFarmaci">Ditta:</div>
                                {selectedDrug.company}
                            </div>
                            : null} */}
                    </div>
                </div>

                {selectedDrugs.length > 0 ?
                    <div className="col-12">
                        <div className="my-4">
                            <h6 className="text-primary" style={{ margin: "0" }}>Nuova terapia</h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <TableContainer>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Farmaco</TableCell>
                                        <TableCell align="left">Quantità</TableCell>
                                        <TableCell align="left">Inizio da</TableCell>
                                        <TableCell align="left">Posologia</TableCell>
                                        <TableCell align="left">Note</TableCell>
                                        <TableCell align="left">Azioni</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedDrugs.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left">{row.farmaco + '*' + row.confezione}</TableCell>
                                            <TableCell align="left"><TextField
                                                id="outlined-normal"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                name="quantita"
                                                onChange={updateFieldChanged(index)}
                                                value={row.quantita}
                                            /></TableCell>
                                            <TableCell align="left"><TextField
                                                id="outlined-normal"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                name="inizioDa"
                                                onChange={updateFieldChanged(index)}
                                                value={row.inizioDa}
                                            /></TableCell>
                                            <TableCell align="left"><TextField
                                                id="outlined-normal"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                name="posologia"
                                                onChange={updateFieldChanged(index)}
                                                value={row.posologia}
                                            /></TableCell>
                                            <TableCell align="left"><TextField
                                                id="outlined-normal"
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                name="note"
                                                onChange={updateFieldChanged(index)}
                                                value={row.note}
                                            /></TableCell>
                                            <TableCell align="left">
                                                <button
                                                    title="Elimina"
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                                    onClick={removeFromSelectedDrugs(index)}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                    </span>
                                                </button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </div>
                    : null}
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Terapie</h6>
                        <hr style={{ margin: "0" }} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="labelInterventi ml-0">Terapia ORALE</div>
                    <div className="d-flex mb-3">
                        <div className="labelInterventi ml-0">Farmaco:</div> CARDICOR 28CPR RIV 5MG 1/2 UNITA <div className="labelInterventi">Quantità: </div> 2 <div className="labelInterventi">Inizio da:</div> 07/05/2019 <div className="labelInterventi">Posologia:</div> ogni giorno alle 07:00, 19:00 <div className="labelInterventi">Note:</div> Il paziente deve essere controllato tra un mese.
                        <div style={{ position: "absolute", right: "15px" }}>
                            <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                            </span>
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                            </span>
                        </div>
                    </div>
                    <div className="d-flex mb-3">
                        <div className="labelInterventi ml-0">Farmaco:</div> CRESTOR 28CPR RIV 20MG 1 UNITA <div className="labelInterventi">Quantità: </div> 2 <div className="labelInterventi">Inizio da:</div> 07/05/2019 <div className="labelInterventi">Posologia:</div> ogni giorno alle 07:00, 19:00 <div className="labelInterventi">Note:</div> Il paziente deve essere controllato tra un mese.
                        <div style={{ position: "absolute", right: "15px" }}>
                            <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                            </span>
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                            </span>
                        </div>
                    </div>
                    <div className="d-flex mb-3">
                        <div className="labelInterventi ml-0">Farmaco:</div> ZANTAC 20CPR RIV 300MG 1 UNITA <div className="labelInterventi">Quantità: </div> 2 <div className="labelInterventi">Inizio da:</div> 07/05/2019 <div className="labelInterventi">Posologia:</div> ogni giorno alle 07:00, 19:00 <div className="labelInterventi">Note:</div> Il paziente deve essere controllato tra un mese.
                        <div style={{ position: "absolute", right: "15px" }}>
                            <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                            </span>
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(DrugsTab);