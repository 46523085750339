import { useEffect, useState } from "react";
import * as Constants from "../Constants";

/**
 * 
 * @param {string} resultField if the resource is not at the top level of the result, the name of the property containing the resut.
 */
export default function useResources(dataSource, resultField = undefined) {
    const [resources, setResources] = useState(null);

    useEffect(() => {
        async function fetchResources() {
            const fetchResult = await dataSource();
            if (fetchResult.status === Constants.STATUTS_200) {
                const obj = await fetchResult.json()
                if (resultField) {    
                    setResources(obj[resultField]);
                } else {
                    setResources(obj);
                }
            }
        };
        fetchResources();
    }, [dataSource, resultField]);

    return resources;
}
