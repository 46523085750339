import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { format as dateFormat } from 'date-fns';
import * as C from '../Constants';
import { FormattedMessage, injectIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';

import * as AlertService from '../AlertService';
import { useAlert } from 'react-alert';

import * as Service from '../services/AlertService';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function AlertCustomization({ intl }) {
    const classes = useStyles();
    const alert = useAlert();

    const [doctorLastEdit, setDoctorLastEdit] = useState('');
    const [timeLastEdit, setTimeLastEdit] = useState('');

    useEffect(() => {
        getCurrentAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const newAlertSchema = Yup.object().shape({
        lteHrYellow: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lteHrRed: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gteHrYellow: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gteHrRed: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lteBrYellow: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lteBrRed: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gteBrYellow: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gteBrRed: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gteSttYellow: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gteSttRed: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lteSpo2Yellow: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lteSpo2Red: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        disconnectionHours: Yup.number()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    });

    const formik = useFormik({
        initialValues: {
            lteHrYellow: 0,
            lteHrRed: 0,
            gteHrYellow: 0,
            gteHrRed: 0,
            lteBrYellow: 0,
            lteBrRed: 0,
            gteBrYellow: 0,
            gteBrRed: 0,
            gteSttYellow: 0,
            gteSttRed: 0,
            lteSpo2Yellow: 0,
            lteSpo2Red: 0,
            disconnectionHours: 0,
        },
        validationSchema: newAlertSchema,
        onSubmit: values => {
            updateAlertsSettings(values);
        },
    });

    const updateAlertsSettings = async (values) => {
        const payload = {
            "disconnectionHours": values.disconnectionHours,
            "alertOnSummary": {
                "HR": {
                    "gte": {
                        "1": values.gteHrRed,
                        "2": values.gteHrYellow
                    },
                    "lte": {
                        "1": values.lteHrRed,
                        "2": values.lteHrYellow
                    }
                },
                "BR": {
                    "gte": {
                        "1": values.gteBrRed,
                        "2": values.gteBrYellow
                    },
                    "lte": {
                        "1": values.lteBrRed,
                        "2": values.lteBrYellow
                    }
                },
                "SpO2": {
                    "lte": {
                        "1": values.lteSpo2Red,
                        "2": values.lteSpo2Yellow
                    }
                },
                "ST": {
                    "gte": {
                        "1": values.gteSttRed,
                        "2": values.gteSttYellow
                    }
                }
            }
        }


console.log(payload);
        const rawData = await Service.updateAlertsSettings(payload);
        if (rawData.status === C.STATUTS_201 || rawData.status === C.STATUTS_200) {
            AlertService.success(alert);
            // window.location.reload();
        } else if (rawData.status === C.STATUTS_404) {
            AlertService.info(alert, "L'alert non è stato modificato");
        } else {
            AlertService.error(alert);
        }
    }

    const getCurrentAlerts = async () => {
        const rawAlerts = await Service.getCurrentAlertsSettings();
        const alerts = await rawAlerts.json();

        if (alerts.data) {
            setDoctorLastEdit(`${alerts.data.doctor.firstName} ${alerts.data.doctor.lastName}`)
            setTimeLastEdit(dateFormat(new Date(alerts.data.startTime), 'dd/MM/yyyy HH:mm'));
            fillForm(alerts.data.parameters);
        };

    }

    const fillForm = (data) => {
        console.log(data.alertOnSummary);
        Object.keys(data.alertOnSummary).forEach(function (parameter) {
            switch (parameter) {
                case C.MEASUREMENTS_TYPE_HR:
                    //Minore o uguale
                    formik.setFieldValue('lteHrYellow', data.alertOnSummary[parameter].lte[2]);
                    formik.setFieldValue('lteHrRed', data.alertOnSummary[parameter].lte[1]);
                    //Maggiore o uguale
                    formik.setFieldValue('gteHrYellow', data.alertOnSummary[parameter].gte[2]);
                    formik.setFieldValue('gteHrRed', data.alertOnSummary[parameter].gte[1]);
                    break;
                case C.MEASUREMENTS_TYPE_BR:
                    //Minore o uguale
                    formik.setFieldValue('lteBrYellow', data.alertOnSummary[parameter].lte[2]);
                    formik.setFieldValue('lteBrRed', data.alertOnSummary[parameter].lte[1]);

                    //Maggiore o uguale
                    formik.setFieldValue('gteBrYellow', data.alertOnSummary[parameter].gte[2]);
                    formik.setFieldValue('gteBrRed', data.alertOnSummary[parameter].gte[1]);
                    break;
                case C.MEASUREMENTS_TYPE_GENERIC_ST:
                    //Maggiore o uguale
                    formik.setFieldValue('gteSttYellow', data.alertOnSummary[parameter].gte[2]);
                    formik.setFieldValue('gteSttRed', data.alertOnSummary[parameter].gte[1]);
                    break;
                case C.MEASUREMENTS_TYPE_SPO2:
                    //Minore o uguale
                    formik.setFieldValue('lteSpo2Yellow', data.alertOnSummary[parameter].lte[2]);
                    formik.setFieldValue('lteSpo2Red', data.alertOnSummary[parameter].lte[1]);
                    break;
                default:
                    break;

            }
        })
        formik.setFieldValue('disconnectionHours', data.disconnectionHours);

    }


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-custom gutter-b" style={{ height: "260px" }}>
                            <div className="card-body">
                                <span className="svg-icon svg-icon-3x svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Heart.svg")}></SVG>
                                    <p className="text-muted font-weight-bold font-size-lg mt-1">Heart Rate</p>
                                </span>
                                <p className="font-weight-bold font-size-lg mt-1">Maggiore o uguale:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="gte-hr-yellow"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="gteHrYellow"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("gteHrYellow")}
                                        />
                                        {formik.touched.gteHrYellow && formik.errors.gteHrYellow ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.gteHrYellow}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="gte-hr-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="gteHrRed"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("gteHrRed")}
                                        />
                                        {formik.touched.gteHrRed && formik.errors.gteHrRed ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.gteHrRed}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                <br />
                                <p className="font-weight-bold font-size-lg mt-1">Minore o uguale:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="lte-hr-yellow"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="lteHrYellow"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("lteHrYellow")}
                                        />
                                        {formik.touched.lteHrYellow && formik.errors.lteHrYellow ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.lteHrYellow}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="lte-hr-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="lteHrRed"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("lteHrRed")}
                                        />
                                        {formik.touched.lteHrRed && formik.errors.lteHrRed ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.lteHrRed}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-custom gutter-b" style={{ height: "260px" }}>
                            <div className="card-body">
                                <span className="svg-icon svg-icon-3x svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Weather/Wind.svg")}></SVG>
                                    <p className="text-muted font-weight-bold font-size-lg mt-1">Breathing Rate</p>
                                </span>
                                <p className="font-weight-bold font-size-lg mt-1">Maggiore o uguale:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="gte-br-yellow"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="gteBrYellow"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("gteBrYellow")}
                                        />
                                        {formik.touched.gteBrYellow && formik.errors.gteBrYellow ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.gteBrYellow}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="gte-br-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="gteBrRed"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("gteBrRed")}
                                        />
                                        {formik.touched.gteBrRed && formik.errors.gteBrRed ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.gteBrRed}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                                <br />
                                <p className="font-weight-bold font-size-lg mt-1">Minore o uguale:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="lte-br-yellow"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="lteBrYellow"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("lteBrYellow")}
                                        />
                                        {formik.touched.lteBrYellow && formik.errors.lteBrYellow ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.lteBrYellow}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="lte-br-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="lteBrRed"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("lteBrRed")}
                                        />
                                        {formik.touched.lteBrRed && formik.errors.lteBrRed ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.lteBrRed}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-custom gutter-b" style={{ height: "260px" }}>
                            <div className="card-body">
                                <span className="svg-icon svg-icon-3x svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Weather/Temperature-half.svg")}></SVG>
                                    <p className="text-muted font-weight-bold font-size-lg mt-1">Body Temperature</p>
                                </span>
                                <p className="font-weight-bold font-size-lg mt-1">Maggiore o uguale:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="gte-stt-yellow"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="gteSttYellow"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("gteSttYellow")}
                                        />
                                        {formik.touched.gteSttYellow && formik.errors.gteSttYellow ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.gteSttYellow}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="gte-stt-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="gteSttRed"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("gteSttRed")}
                                        />
                                        {formik.touched.gteSttRed && formik.errors.gteSttRed ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.gteSttRed}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-custom gutter-b" style={{ height: "260px" }}>
                            <div className="card-body">
                                <span className="svg-icon svg-icon-3x svg-icon-primary">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Saturation.svg")}></SVG>
                                    <p className="text-muted font-weight-bold font-size-lg mt-1">Oxygen Saturation</p>
                                </span>
                                <p className="font-weight-bold font-size-lg mt-1">Minore o uguale:</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-warning">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="lte-spo2-yellow"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="lteSpo2Yellow"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("lteSpo2Yellow")}
                                        />
                                        {formik.touched.lteSpo2Yellow && formik.errors.lteSpo2Yellow ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.lteSpo2Yellow}</div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-md-6">
                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                                        </span>
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="lte-spo2-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="lteSpo2Red"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("lteSpo2Red")}
                                        />
                                        {formik.touched.lteSpo2Red && formik.errors.lteSpo2Red ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.lteSpo2Red}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-custom gutter-b" style={{ height: "140px" }}>
                            <div className="card-body">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span className="svg-icon svg-icon-3x svg-icon-primary">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Alarm-clock.svg")}></SVG>
                                        <p className="text-muted font-weight-bold font-size-lg mt-1">Ore paziente disconesso</p>
                                    </span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="patient-disconnected-tooltip">
                                                Verrà inviato un alert se un paziente risulta disconnesso per più ore di quelle impostate.
                                            </Tooltip>
                                        }
                                    >
                                        <span className="svg-icon svg-icon-3x svg-icon-primary">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}></SVG>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField
                                            style={{ marginBottom: "-30px" }}
                                            id="stt-red"
                                            variant="standard"
                                            type="number"
                                            size="small"
                                            name="disconnectionHours"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps("disconnectionHours")}
                                        />
                                        {formik.touched.disconnectionHours && formik.errors.disconnectionHours ?
                                            <div className="fv-plugins-message-container mt-5 ml-5">
                                                <div className="fv-help-block">{formik.errors.disconnectionHours}</div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <Button type="submit" variant="contained" color="primary" size="small" className={classes.button}
                            disabled={!formik.isValid || !formik.dirty}
                        >
                            <FormattedMessage id="ACTIONS.SAVE" />
                            <SaveIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
                        </Button>
                    </div>
                    <div className="col-md-4">
                        <p>Ultima Modifica: <b>{doctorLastEdit}</b> il <b>{timeLastEdit}</b></p>
                    </div>
                </div>
            </form>
        </>
    );
}

export default injectIntl(AlertCustomization);