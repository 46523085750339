/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  toAbsoluteUrl
} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export function StickyToolbarVoip({ ...props }) {
  return (
    <>
      {
        props.isVisibleStickiToolbarVoip ?

          <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4 voipMinimizeActions">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="layout-tooltip">{`${props.patientDetails.firstName} ${props.patientDetails.lastName}`}</Tooltip>}
            >
              <li className="nav-item mb-2" data-placement="left">
                <button
                  title="Notifica Paziente"
                  className="btn btn-icon btn-sm"
                  onClick={() => { props.maximize(); }}
                >
                  <span class="symbol symbol-30 symbol-light-success mb-2 btn-hover-success">
                    <span class="font-weight-bold symbol-label">{`${props.patientDetails.firstName[0]} ${props.patientDetails.lastName[0]}`}
                    </span>
                    <div className="symbol-badge badge-success">
                      &nbsp;
                      </div>
                  </span>
                </button>
              </li>
            </OverlayTrigger>

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="layout-tooltip">Apri finestra chiamata</Tooltip>}
            >
              <li className="nav-item mb-2" data-placement="left">
                <button
                  title="Notifica Paziente"
                  className="btn btn-icon btn-primary btn-sm"
                  onClick={() => { props.maximize(); }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/maximize.svg")} />
                  </span>
                </button>
              </li>
            </OverlayTrigger>

            {props.isCallMuted ?
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="hangout-tooltip">Attiva audio</Tooltip>}
              >
                <li className="nav-item mb-2" data-placement="left">
                  <button
                    title="Notifica Paziente"
                    className="btn btn-icon btn-primary btn-sm"
                    onClick={() => { props.unmute(); }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/mic-mute.svg")} />
                    </span>
                  </button>
                </li>
              </OverlayTrigger>
              :
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="hangout-tooltip">Muta audio</Tooltip>}
              >
                <li className="nav-item mb-2" data-placement="left">
                  <button
                    title="Notifica Paziente"
                    className="btn btn-icon btn-primary btn-sm"
                    onClick={() => { props.mute(); }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/mic-on.svg")} />
                    </span>
                  </button>
                </li>
              </OverlayTrigger>
            }

            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="hangout-tooltip">Chiudi chiamata</Tooltip>}
            >
              <li className="nav-item mb-2" data-placement="left">
                <button
                  title="Notifica Paziente"
                  className="btn btn-icon btn-danger btn-sm"
                  onClick={() => { props.endCall(); }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/telephone-out.svg")} />
                  </span>
                </button>
              </li>
            </OverlayTrigger>
          </ul>

          : null
      }
    </>
  );
}
