import React from "react";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/InsertDriveFile';

import * as Constants from "../../../Constants";

export default function UserDocumentListItem({userDocument, handleOpenDocument, handleDeleteDocument, user}) {
    return (
        <ListItem button divider onClick={() => handleOpenDocument(userDocument)}>
            <ListItemAvatar>
                <Avatar>
                    <FileIcon/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography variant="h6">
                        {userDocument.userProvidedName}
                    </Typography>
                }
            />
            {user.role === Constants.ROLE_ADMIN &&
            <ListItemSecondaryAction onClick={() => handleDeleteDocument(userDocument)} >
                <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
            }
        </ListItem>
    )
}
