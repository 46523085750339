import React, { useEffect, useState } from 'react';

import * as C from '../Constants';

import * as PatientService from '../services/PatientService';

import { injectIntl } from "react-intl";

import { VictoryChart, VictoryLine, VictoryAxis, VictoryScatter } from 'victory';


function PatientHistoricMonitoringMobileChart({ intl, ...props }) {

    const [hrChartDomain, setHrChartDomain] = useState({ x: [0, 100000] });
    const [spo2ChartDomain, setSpo2ChartDomain] = useState({ x: [0, 100000] });
    const [btChartDomain, setBtChartDomain] = useState({ x: [0, 100000] });
    const [brChartDomain, setBrChartDomain] = useState({ x: [0, 100000] });

    const [hrData, setHrData] = useState([]);
    const [brData, setBrData] = useState([]);
    const [spo2Data, setSpo2Data] = useState([]);
    const [sttData, setSttData] = useState([]);

    const getSummaryMeasurement = async (patientId) => {
        const rawResp = await PatientService.getSummaryMeasureByPatient(patientId, 8, null);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            return resp;
        } else {
            return [];
        }
    }

    useEffect(() => {
        getSummaryMeasurement(props.patientId).then(resp => {
            // let timestampArray = [];

            //Ciclo per tutti i timestamp
            Object.keys(resp).forEach(function (timestamp) {
                // timestampArray.push(parseInt(timestamp));
                //Per ogni timestamp, ciclo poi su tutti i tipi di valore.
                Object.keys(resp[timestamp]).forEach(function (typeMeasure) {
                    switch (typeMeasure) {
                        case C.MEASUREMENTS_TYPE_HR:
                            setHrData(oldHrData => [...oldHrData, { "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) }]);
                            break;
                        case C.MEASUREMENTS_TYPE_BR:
                            setBrData(oldBrData => [...oldBrData, { "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) }]);
                            break;
                        case C.MEASUREMENTS_TYPE_GENERIC_ST:
                            setSttData(oldSttData => [...oldSttData, { "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) }]);
                            break;
                        case C.MEASUREMENTS_TYPE_SPO2:
                            setSpo2Data(oldSpo2Data => [...oldSpo2Data, { "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) }]);
                            break;
                        default:
                            break;
                    }
                });
            });

            //Setto porzione da visualizzare per il grafico
            setHrChartDomain({ x: [new Date().setHours((new Date().getHours() - 8)), new Date()] });
            setSpo2ChartDomain({ x: [new Date().setHours((new Date().getHours() - 8)), new Date()] });
            setBtChartDomain({ x: [new Date().setHours((new Date().getHours() - 8)), new Date()] });
            setBrChartDomain({ x: [new Date().setHours((new Date().getHours() - 8)), new Date()] });

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="row m-0 mobileHistoric">
                <div className="col-md-6" style={{}}>
                    <div style={{ display: "flex", backgroundColor: "black", color: "white", borderRadius: "10px", margin: "15px 0", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "15%" }}>
                            <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{C.MEASUREMENTS_TYPE_HR}</div>
                            <div style={{ fontSize: "1.0rem" }}>{C.UNIT_MEASURE_HR}</div>
                        </div>
                        <VictoryChart domain={hrChartDomain}
                            scale={{ x: "time", y: "linear" }}>
                            <VictoryAxis
                                dependentAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: '#FCF4A3' },
                                }}
                                tickValues={[90, 110]}
                                tickFormat={t => `${Math.round(t)}`}
                                standalone={false}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: 'white' },
                                }}
                                standalone={false}
                                tickFormat={(x) => x.getHours() + ':' + x.getMinutes() + x.getMinutes()}
                            />
                            <VictoryLine
                                style={{ data: { stroke: "white", strokeWidth: 1 } }}
                                data={hrData}
                            />
                            <VictoryScatter
                                symbol={"circle"}
                                size={3}
                                style={{
                                    data: {
                                        fill: ({ datum }) =>
                                            (datum.y < C.valueRefMinHR || datum.y > C.valueRefMaxHR) ? 'red' : (datum.y >= C.valueRefMedHR && datum.y <= C.valueRefMaxHR) ? 'orange' : 'green',
                                    }
                                }}
                                data={hrData}
                            />
                        </VictoryChart>
                    </div>

                    <div style={{ display: "flex", backgroundColor: "black", color: "white", borderRadius: "10px", margin: "15px 0", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "15%" }}>
                            <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{C.MEASUREMENTS_TYPE_BR}</div>
                            <div style={{ fontSize: "1.0rem" }}>{C.UNIT_MEASURE_BR}</div>
                        </div>
                        <VictoryChart domain={brChartDomain}
                            scale={{ x: "time", y: "linear" }}

                            style={{
                                axis: { stroke: 'white', strokeWidth: 1, touchAction: 'auto' },
                                tickLabels: { fill: '#FCF4A3' },
                            }}


                        >
                            <VictoryAxis
                                dependentAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: '#FCF4A3' },
                                }}
                                tickValues={[8, 13]}
                                tickFormat={t => `${Math.round(t)}`}
                                standalone={false}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: 'white' },
                                }}
                                standalone={false}
                                tickFormat={(x) => x.getHours() + ':' + x.getMinutes() + x.getMinutes()}
                            />
                            <VictoryLine
                                style={{ data: { stroke: "white", strokeWidth: 1 } }}
                                data={brData}
                            />
                            <VictoryScatter
                                symbol={"circle"}
                                size={3}
                                style={{
                                    data: {
                                        fill: ({ datum }) =>
                                            (datum.y < C.valueRefMinBR || datum.y > C.valueRefMaxBR) ? 'red' : (datum.y >= C.valueRefMedBR && datum.y <= C.valueRefMaxBR) ? 'orange' : 'green'
                                    }
                                }}
                                data={brData}
                            />
                        </VictoryChart>
                    </div>
                </div>

                <div className="col-md-6" style={{}}>

                    <div style={{ display: "flex", backgroundColor: "black", color: "white", borderRadius: "10px", margin: "15px 0", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "15%" }}>
                            <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>BT</div>
                            <div style={{ fontSize: "1.0rem" }}>&#8451;</div>
                        </div>
                        <VictoryChart domain={btChartDomain}
                            scale={{ x: "time", y: "linear" }}>
                            <VictoryAxis
                                dependentAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: '#FCF4A3' },
                                }}
                                tickValues={[37]}
                                tickFormat={t => `${Math.round(t)}`}
                                standalone={false}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: 'white' },
                                }}
                                standalone={false}
                                tickFormat={(x) => x.getHours() + ':' + x.getMinutes() + x.getMinutes()}
                            />
                            <VictoryLine
                                style={{ data: { stroke: "white", strokeWidth: 1 } }}
                                data={sttData}
                            />
                            <VictoryScatter
                                symbol={"circle"}
                                size={3}
                                style={{
                                    data: {
                                        fill: ({ datum }) =>
                                            (datum.y < C.valueRefMinSTT || datum.y > C.valueRefMaxSTT) ? 'red' : (datum.y >= C.valueRefMedSTT && datum.y <= C.valueRefMaxSTT) ? 'orange' : 'green'
                                    }
                                }}
                                data={sttData}
                            />
                        </VictoryChart>
                    </div>

                    <div style={{ display: "flex", backgroundColor: "black", color: "white", borderRadius: "10px", margin: "15px 0", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "15%" }}>
                            <div style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{C.MEASUREMENTS_TYPE_SPO2}</div>
                            <div style={{ fontSize: "1.0rem" }}>{C.UNIT_MEASURE_SPO2}</div>
                        </div>
                        <VictoryChart domain={spo2ChartDomain}
                            scale={{ x: "time", y: "linear" }}>
                            <VictoryAxis
                                dependentAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: '#FCF4A3' },
                                }}
                                tickValues={[90, 95]}
                                tickFormat={t => `${Math.round(t)}`}
                                standalone={false}
                            />
                            <VictoryAxis
                                style={{
                                    axis: { stroke: 'white', strokeWidth: 1 },
                                    tickLabels: { fill: 'white' },
                                }}
                                standalone={false}
                                tickFormat={(x) => x.getHours() + ':' + x.getMinutes() + x.getMinutes()}
                            />
                            <VictoryLine
                                style={{ data: { stroke: "white", strokeWidth: 1 } }}
                                data={spo2Data}
                            />
                            <VictoryScatter
                                symbol={"circle"}
                                size={3}
                                style={{
                                    data: {
                                        fill: ({ datum }) =>
                                            datum.y < C.valueRefMinSpO2 ? 'red' : datum.y <= C.valueRefMedSpO2 ? 'orange' : 'green',
                                    }
                                }}
                                data={spo2Data}
                            />
                        </VictoryChart>
                    </div>



                </div>
            </div>
        </>
    );
}

export default injectIntl(PatientHistoricMonitoringMobileChart);