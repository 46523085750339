import React, { useEffect, useState } from 'react';
import { injectIntl } from "react-intl";

import * as StringUtils from '../../StringUtils';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    toAbsoluteUrl
} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import {
    TextField
} from "@material-ui/core";

import * as UtilService from '../../services/UtilService';

function SurgeryTab({ intl, ...props }) {

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [surgeries, setSurgeries] = useState([]);
    // const [selectedDrug, setSelectedDrug] = useState(null);

    // const [selectedDrugs, setSelectedDrugs] = useState([]);

    const chooseSurgery = async (e, value) => {
        if (!StringUtils.isEmpty(value) && value.length >= 3) {
            const listSurgeries = await (await UtilService.getInterventi({ findAnywhere: value })).json();
            setSurgeries(listSurgeries.data);
        }
    }

    // const ChangeSelectedFarmaco = (e, value) => {
    //     if (value) {
    //         setSelectedDrug({
    //             ...selectedDrug,
    //             description: value.descrizione,
    //             package: value.confezione,
    //             minsanCode: value.codMin,
    //             activePrincipleCode: value.principioAttivo,
    //             activePrincipleDescription: value.principioAttivoDescrizioneEstesa,
    //             company: value.descrizioneDitta
    //         });

    //         setSelectedDrugs(selectedDrugs => [...selectedDrugs, { farmaco: value.descrizione, confezione: value.confezione }]);
    //     } else {
    //         setSelectedDrug(null);
    //     }
    //     setDrugs([])
    // }




    return (
        <>
            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Interventi</h6>
                <hr style={{ margin: "0" }} />
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Autocomplete
                        id="combo-box-demo"
                        onInputChange={chooseSurgery}
                        // onChange={ChangeSelectedFarmaco}
                        onClose={() => { setSurgeries([]) }}
                        options={surgeries}
                        noOptionsText=""
                        getOptionLabel={(option) => option.codiceintervento + ' - ' + option.descrizioneintervento}
                        renderInput={(params) => <TextField
                            {...params}
                            label={`Inserisci codice o descrizione intervento`}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />}
                    />
                </div>
                <div className="col-md-2">
                    <TextField
                        id="outlined-normal"
                        label={`Data intervento`}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        name="caregiverPhone"
                    />
                </div>
                <div className="col-md-3">
                    <TextField
                        id="outlined-normal"
                        label={`Note`}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        name="caregiverPhone"
                    />
                </div>
                <div className="col-md-3">
                    <button
                        type="button"
                        disabled={true}
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 mt-5"
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
                        </span>
                    </button>
                </div>
            </div>
            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Interventi subiti</h6>
                <hr style={{ margin: "0" }} />
            </div>
            <div className="row">
                <div className="col-md-12 d-flex mb-3">
                    <div className="labelInterventi ml-0">Codice:</div> 13.90 <div className="labelInterventi">Intervento:</div> INTERVENTI SUL CRISTALLINO, NON CLASSIFICATI ALTROVE <div className="labelInterventi">Data:</div> 05-03-1997 <div className="labelInterventi">Note:</div> Il paziente non ricorda il giorno esatto
                                    <div style={{ position: "absolute", right: "15px" }}>
                        <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                        </span>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                        </span>
                    </div>
                </div>
                <div className="col-md-12 d-flex mb-3">
                    <div className="labelInterventi ml-0">Codice:</div> 35.55 <div className="labelInterventi">Intervento:</div> RIPARAZIONE DI DIFETTO DEL SETTO INTERVENTRICOLARE CON PROTESI, TECNICA CHIUSA <div className="labelInterventi">Data:</div> 05-05-1985 <div className="labelInterventi">Note:</div> Intervento andato con successo
                                    <div style={{ position: "absolute", right: "15px" }}>
                        <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                        </span>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                        </span>
                    </div>
                </div>
                <div className="col-md-12 d-flex mb-3">
                    <div className="labelInterventi ml-0">Codice:</div> 32.23 <div className="labelInterventi">Intervento:</div> ABLAZIONE A CIELO APERTO DI LESIONE O TESSUTO POLMONARE <div className="labelInterventi">Data:</div> 05-05-1985 <div className="labelInterventi">Note:</div> Intervento è stato lungo ma si è risolto tutto
                                    <div style={{ position: "absolute", right: "15px" }}>
                        <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                        </span>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(SurgeryTab);