import { Card, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react"
import { FormattedMessage, injectIntl } from "react-intl";
import {useHistory} from "react-router-dom";
import { useFormik } from "formik";
import { Save } from "@material-ui/icons";
import * as Yup from "yup";
import {TextField, Button} from "@material-ui/core";
import { useAlert } from "react-alert";

import SectionHeader from "../../../components/layout/SectionHeader";
import * as GrandPrixService from "../../../services/GrandPrixService";
import * as Constants from "../../../Constants"
import * as AlertService from "../../../AlertService"
import useIsNew from "../../../hooks/UseIsNew";
import FormErrorBlock from "../../../components/form-control/FormErrorBlock";
import { dateToISODateString } from "../../../Utils";
import useGrandPrix from "../hooks/UseGrandPrix";

function GrandPrixPage({intl, ...props}) {

    // state
    let [isNewGrandPrix, existingGrandPrixId] = useIsNew("/grand-prix-new", "/grand-prix-edit/:id");
    const grandPrix = useGrandPrix(existingGrandPrixId);
    
    const alert = useAlert();
    const history = useHistory();

    useEffect(() => {
        async function populateFields() {
            if (grandPrix) {
                formik.setFieldValue("name", grandPrix.name);

                let startString = dateToISODateString(new Date(grandPrix.startDate));
                formik.setFieldValue("startDate", startString);

                let endString = dateToISODateString(new Date(grandPrix.endDate));
                formik.setFieldValue("endDate", endString);
            } else {
                formik.resetForm();
            }
        };
        populateFields();
    }, [grandPrix]);

    // handlers
    async function handleSubmit(values) {
        let result;
        let success = false;
        if (isNewGrandPrix) {
            result = await GrandPrixService.addGrandPrix(values);
            if (result.status === Constants.STATUTS_201) {
                let grandPrix = await result.json();
                history.push(`/grand-prix-edit/${grandPrix.id}`);
                success = true
            }
        } else {
            result = await GrandPrixService.updateGrandPrix(existingGrandPrixId, values);
            if (result.status === Constants.STATUTS_200) {
                success = true
            }
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    // formik
    const formSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        startDate: Yup.date().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        endDate: Yup.date().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            startDate: "",
            endDate: "",
        },
        validationSchema: formSchema,
        onSubmit: handleSubmit,
    });

    return (
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <Card.Body>
                    <SectionHeader messageId="GENERAL.DATA"/>
                    <Row>
                        <Col md={4}>
                            <TextField
                                label={intl.formatMessage({id: "FIELD.GRAND_PRIX_NAME"})}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                {...formik.getFieldProps("name")}
                            />
                            <FormErrorBlock
                                formik={formik}
                                fieldName="name"
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                label={intl.formatMessage({id: "FIELD.GRAND_PRIX_START_DATE"})}
                                type={"date"}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{shrink: true,}}
                                {...formik.getFieldProps("startDate")}
                            />
                            <FormErrorBlock
                                formik={formik}
                                fieldName="startDate"
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                label={intl.formatMessage({id: "FIELD.GRAND_PRIX_END_DATE"})}
                                type={"date"}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{shrink: true,}}
                                {...formik.getFieldProps("endDate")}
                            />
                            <FormErrorBlock
                                formik={formik}
                                fieldName="endDate"
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!formik.isValid || !formik.dirty}
                        endIcon={<Save/>}
                    >
                        <FormattedMessage id="ACTIONS.SAVE" />
                    </Button>
                </Card.Footer>
            </form>
        </Card>
    )
}

export default injectIntl(GrandPrixPage); 
