import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, injectIntl } from "react-intl";
import { Publish } from "@material-ui/icons";
import { Button, Input } from "@material-ui/core";

import { Card, CardBody, CardFooter } from "../../../../_metronic/_partials/controls";
import UserDocumentList from "../components/UserDocumentList";
import useUser from "../../../hooks/UseUser";
import usePagination from "../../../hooks/UsePagination";
import * as UserDocumentService from "../../../services/UserDocumentService";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";
import { ConfirmOrCancelDialog } from "../../../components/dialogs/ConfirmOrCancelDialog";

function UserDocumentListPage({intl, ...props}) {
    const user = useUser();
    const alert = useAlert();
    const {rows, commit} = usePagination(UserDocumentService.getAllUserDocuments);
    const [loading, setLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    async function handleOpenDocument(userDocument) {
        if (userDocument) {
            setLoading(true);
            const response = await UserDocumentService.getUserDocument(userDocument.id);
            if (response.status === Constants.STATUTS_200) {
                const pdfURL = URL.createObjectURL(await response.blob());
                window.open(pdfURL);
                setTimeout(() => URL.revokeObjectURL(pdfURL), 60000);
            } else {
                AlertService.error(alert, (await response.json()).message);
            }
            setLoading(false);
        }
    }

    async function handleOpenDeleteDialog(userDocument) {
        setSelectedDocument(userDocument);
        setDeleteDialogOpen(true);
    }

    async function handleCloseDeleteDialog() {
        setDeleteDialogOpen(false);
        setSelectedDocument({});
    }

    async function handleDeleteDocument() {
        if (selectedDocument) {
            let result;
            let success = false;
            result = await UserDocumentService.deleteUserDocument(selectedDocument.id)
            if (result.status === Constants.STATUTS_200) {
                success = true;
            }
            
            if (success) {
                AlertService.success(alert);
            } else {
                AlertService.error(alert, (await result.json()).message);
            }
            commit();
        } else {
            AlertService.error(alert, "No document selected. Report this to application maintainers");
        }

        setDeleteDialogOpen(false);
    }

    async function handleUploadDocument(file) {
        let result;
        let success = false;
        result = await UserDocumentService.addUserDocument(file);
        if (result.status === Constants.STATUTS_201) {
            success = true;
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    async function handleSelectUploadFile(event) {
        setLoading(true);
        if (event.target.files.length > 0) {
            await handleUploadDocument(event.target.files[0]);
        }
        commit();
        setLoading(false);
    }

    return (
        <>
        {loading && <LoadingSpinner/>}
        <Card>
            <CardBody>
                <UserDocumentList
                    rows={rows}
                    handleOpenDocument={handleOpenDocument}
                    handleDeleteDocument={handleOpenDeleteDialog}
                    user={user}
                />
            </CardBody>
            {user.role === Constants.ROLE_ADMIN &&
            <CardFooter>
                <Button
                    component="label"
                    color="primary"
                    variant="contained"
                    endIcon={<Publish/>}
                    margin="normal"
                >
                    <Input
                        type="file"
                        inputProps={{
                            accept: ".pdf",
                        }}
                        style={{display: "none"}}
                        onChange={handleSelectUploadFile}
                    />
                    Upload
                </Button>
            </CardFooter>
            }
        </Card>
        <ConfirmOrCancelDialog
            title={
                <FormattedMessage
                id="MENU.DELETE_USER_DOCUMENT_DIALOG_TITLE"
                values={{name: selectedDocument.userProvidedName}}
                />
            }
            content={
                <FormattedMessage
                id="MENU.DELETE_USER_DOCUMENT_DIALOG_CONTENT"
                />
            }
            cancelMessage={
                <FormattedMessage
                id="ACTIONS.CANCEL"
                />
            }
            confirmMessage={
                <FormattedMessage
                id="ACTIONS.CONFIRM"
                />
            }
            danger={true}
            open={deleteDialogOpen}
            handleClose={handleCloseDeleteDialog}
            handleConfirm={handleDeleteDocument}
        />
        </>
    )
}

export default injectIntl(UserDocumentListPage);
