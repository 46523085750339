import React, { useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { useFormik } from "formik";
import SVG from "react-inlinesvg";
import {
    TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination
} from "@material-ui/core";

import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import * as TeamService from "./../../../services/TeamService";
import * as Constants from "../../../Constants";
import usePagination from "../../../hooks/UsePagination";

function TeamList({intl, ...props}) {
    // state
    const {rows, page, setPage, setFilter, commit, totalElements} = usePagination(TeamService.findTeamsByFilter);

    // effects

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    //formik
    const formik = useFormik({
        initialValues: {
            name: "",
        },
        onSubmit: values => {
            setPage(0);
            setFilter(values);
            commit();
        },
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        commit();
    };

    return(
        <Card>
            <CardBody>
                {/* START FILTRO */}
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="name"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("name")}
                            />
                        </div>
                        <div className="col-lg-3 mt-5">
                            <button
                                type="submit"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                    />
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
                {/* END FILTRO */}

                {/* START TABLE */}
                <TableContainer>
                    <Table aria-label="team table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_TEAM_NAME" /></TableCell>
                                <TableCell align="center"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <NavLink
                                            title="Modifica Team"
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            to={`/team-edit/${row.id}`}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                />
                                            </span>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[Constants.rowsPerPage]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={Constants.rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
                {/* END TABLE */}
            </CardBody>
        </Card>
    );
}

export default injectIntl(TeamList);
