import { useEffect, useState } from "react";
import * as Constants from "../Constants";

/**
 * 
 * @param {string} id 
 * @param {function} dataSource 
 * @returns 
 */
export default function useResource(id, dataSource, resultField = undefined) {
    const [resource, setResource] = useState(null);

    useEffect(() => {
        async function fetchResource() {
            if (id) {
                const fetchResult = await dataSource(id);
                if (fetchResult.status === Constants.STATUTS_200) {
                    const obj = await fetchResult.json()
                    if (resultField) {    
                        setResource(obj[resultField]);
                    } else {
                        setResource(obj);
                    }
                }
            } else {
                setResource(null);
            }
        };
        fetchResource();
    }, [id]);

    return resource;
}
