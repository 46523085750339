import React from "react";
import {Row, Col} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    zeroMargin: {
        margin: 0,
    },
});
/**
 * A component which shows a localized message in a header with a horizontal rule.
 * 
 * @param {object} props
 * @param {string} messageId The id of the localized message to display in this header
 */
function SectionHeader({messageId}) {

    const classes = useStyles();

    return (
        <Row>
            <Col sm={12} className="mt-4">
                <h6 className={`text-primary ${classes.zeroMargin}`}><FormattedMessage id={messageId} /></h6>
                <hr className={classes.zeroMargin} />
            </Col>
        </Row>
    )
}

SectionHeader.propTypes = {
    messageId: PropTypes.string.isRequired,
}

export default SectionHeader;
