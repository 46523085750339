import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import * as Constants from '../Constants';

import { Card, CardBody } from "../../_metronic/_partials/controls";
import {
    toAbsoluteUrl
} from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import * as DoctorService from '../services/DoctorService';

import {
    TextField, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TablePagination
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function DoctorList({ intl }) {

    const classes = useStyles();


    useEffect(() => {
        search(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [findAnywhere, setFindAnywhere] = useState('');

    const [rows, setRows] = useState([]);

    const search = async (page) => {
        const filter = {
            "findAnywhere": findAnywhere,
            "page": page,
            "limit": Constants.rowsPerPage,
        }

        const rawResp = await DoctorService.findDoctorsByFilter(filter);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            if (resp.data.length === 0) {
                setRows([]);
                setTotalElements(0);
            } else {
                setRows(resp.data);
                setTotalElements(resp.totalElements);
            }
        } else {
            setRows([]);
        }
    }

    // Table pagination properties
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        search(newPage);
    };

    return (
        <Card>
            <CardBody>
                {/* START FILTRO */}
                <div className="form-group row">
                    <div className="col-lg-4">
                        <TextField
                            id="outlined-normal"
                            label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                            margin="normal"
                            variant="outlined"
                            size="small"
                            name="searchText"
                            onChange={(e) => { setFindAnywhere(e.target.value); }}
                        />
                    </div>
                    <div className="col-lg-3 mt-5">
                        <button
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => { search(0); }}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                />
                            </span>
                        </button>
                    </div>
                </div>
                {/* END FILTRO */}

                {/* START FILTRO */}
                <TableContainer>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LASTNAME" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FIRTSTNAME" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_EMAIL" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.DOCTOR_TYPE" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">{row.lastName}</TableCell>
                                    <TableCell align="left">{row.firstName}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">{row.tipoMedico}</TableCell>
                                    <TableCell align="left">
                                        {/* TODO modificare navigazione per renderla react style (al momento con il link carica di nuovo tutto l'applicativo) */}
                                        <NavLink
                                            title="Modifica Medico"
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                            to={`/doctor-edit/${row.id}`}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                />
                                            </span>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[Constants.rowsPerPage]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={Constants.rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
                {/* END FILTRO */}
            </CardBody>
        </Card>
    );
}

export default injectIntl(DoctorList);