import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { BrowserView, MobileView } from "react-device-detect";

import * as C from '../Constants';

import { format as dateFormat } from 'date-fns';

import * as PatientService from '../services/PatientService';
import * as AlertService from '../services/AlertService';

import * as Utils from '../Utils';

import { NavLink } from "react-router-dom";

import { VictoryContainer, VictoryChart, VictoryLine, VictoryAxis, VictoryScatter, VictoryTooltip } from 'victory'
import { VictoryZoomContainer } from 'victory-zoom-container'

import { useSubheader } from "../../_metronic/layout";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

function PatientHistoricMeasureDetail({ intl, ...props }) {
    const suhbeader = useSubheader();
    const patientId = props.match.params.id;

    const [selectedPatient, setSelectedPatient] = useState({});
    const [valuesRef, setValuesRef] = useState({});
    suhbeader.setTitle(`${intl.formatMessage({ id: 'PAGES.PATIENT_HISTORIC_MONITORING' })} - ${selectedPatient.firstName} ${selectedPatient.lastName}`);

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({});
    // holds the timer for setTimeout and clearInterval
    // the number of ms the window size must stay the same size before the
    // dimension state variable is reset
    const resizeDimensions = () => {
        // For some reason targetRef.current.getBoundingClientRect was not available
        // I found this worked for me, but unfortunately I can't find the
        // documentation to explain this experience
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                ecgCoordinate: parseInt(((targetRef.current.offsetWidth * 60) / 100)),
                impCoordinate: parseInt(((targetRef.current.offsetWidth * 60) / 100) / 10)
            });
        }
    }
    // This sets the dimensions on the first render
    useLayoutEffect(() => {
        resizeDimensions();
    }, []);

    useEffect(() => {
        let resizeEvent = () => setTimeout(resizeDimensions, C.resetTimeoutResizeVictory);
        // every time the window is resized, the timer is cleared and set again
        // the net effect is the component will only reset after the window size
        // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
        // redrawing of the component for more complex components such as charts
        window.addEventListener('resize', resizeEvent);
        window.addEventListener('click', resizeEvent);

        const defineSelectedPatient = async () => {
            const data = await (await PatientService.findPatientById(patientId)).json();
            setSelectedPatient(data);
        }

        defineSelectedPatient();

        return () => {
            window.removeEventListener('resize', resizeEvent);
            window.removeEventListener('click', resizeEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);






    // START CSS
    const [isSelectedOneRange, setIsSelectedOneRange] = useState(false);
    const [isSelectedEightRange, setIsSelectedEightRange] = useState(false);
    const [isSelectedSixteenRange, setIsSelectedSixteenRange] = useState(false);
    const [isSelectedTwentyfourRange, setIsSelectedTwentyfourRange] = useState(true);

    const [isFullscreenMonitor, setIsFullscreenMonitor] = useState(false);
    // END CSS

    useEffect(() => {
        const defineValuesRef = async () => {
            let refs = {};
            const rawAlerts = await AlertService.getCurrentAlertsSettings();
            const alerts = await rawAlerts.json();
            const params = alerts.data.parameters.alertOnSummary;
            Object.keys(params).forEach(function (parameter) {
                switch (parameter) {
                    case C.MEASUREMENTS_TYPE_HR:
                        refs.lteHrYellow = params[parameter].lte[2];
                        refs.lteHrRed = params[parameter].lte[1];
                        refs.gteHrYellow = params[parameter].gte[2];
                        refs.gteHrRed = params[parameter].gte[1];
                        break;
                    case C.MEASUREMENTS_TYPE_BR:
                        refs.lteBrYellow = params[parameter].lte[2];
                        refs.lteBrRed = params[parameter].lte[1];
                        refs.gteBrYellow = params[parameter].gte[2];
                        refs.gteBrRed = params[parameter].gte[1];
                        break;
                    case C.MEASUREMENTS_TYPE_GENERIC_ST:
                        refs.gteSttYellow = params[parameter].gte[2];
                        refs.gteSttRed = params[parameter].gte[1];
                        break;
                    case C.MEASUREMENTS_TYPE_SPO2:
                        refs.lteSpo2Yellow = params[parameter].lte[2];
                        refs.lteSpo2Red = params[parameter].lte[1];
                        break;
                    default:
                        break;

                }
            })
            setValuesRef(refs);
        }

        const defineSelectedPatient = async () => {
            const data = await (await PatientService.findPatientById(patientId)).json();
            setSelectedPatient(data);
        }

        defineSelectedPatient();
        defineValuesRef();
        setCharts({ hours: null, timestamp: null }, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [summaryDomainFrom, setSummaryDomainFrom] = useState(new Date());
    const [summaryDomainTo, setSummaryDomainTo] = useState(new Date());
    const [summaryDomainCenter, setSummaryDomainCenter] = useState(new Date().setHours((new Date().getHours() - 12)));

    //Punti di delimitazione per non far buggare i grafici
    const [hrDelimiterScatter, setHrDelimiterScatter] = useState([]);
    const [spo2DelimiterScatter, setSpo2DelimiterScatter] = useState([]);
    const [sttDelimiterScatter, setSttDelimiterScatter] = useState([]);
    const [brDelimiterScatter, setBrDelimiterScatter] = useState([]);


    //Dati effettivi
    const [hrChartDomain, setHrChartDomain] = useState({ x: [0, 100000] });
    const [spo2ChartDomain, setSpo2ChartDomain] = useState({ x: [0, 100000] });
    const [btChartDomain, setBtChartDomain] = useState({ x: [0, 100000] });
    const [brChartDomain, setBrChartDomain] = useState({ x: [0, 100000] });

    const [hrData, setHrData] = useState([]);
    const [brData, setBrData] = useState([]);
    const [spo2Data, setSpo2Data] = useState([]);
    const [sttData, setSttData] = useState([]);

    const getSummaryMeasurement = async (hours, timestamp) => {
        const rawResp = await PatientService.getSummaryMeasureByPatient(patientId, hours, timestamp);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            return resp;
        } else {
            return [];
        }
    }

    const setCharts = (params, firstCall) => {

        let tmpHrData = [];
        let tmpBrData = [];
        let tmpSttData = [];
        let tmpSpo2Data = [];

        getSummaryMeasurement(params.hours, params.timestamp).then(resp => {
            //Ciclo per tutti i timestamp
            Object.keys(resp).forEach(function (timestamp) {
                //Per ogni timestamp, ciclo poi su tutti i tipi di valore.
                Object.keys(resp[timestamp]).forEach(function (typeMeasure) {
                    switch (typeMeasure) {
                        case C.MEASUREMENTS_TYPE_HR:
                            tmpHrData.push({ "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) });
                            break;
                        case C.MEASUREMENTS_TYPE_BR:
                            tmpBrData.push({ "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) });
                            break;
                        case C.MEASUREMENTS_TYPE_GENERIC_ST:
                            tmpSttData.push({ "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) });
                            break;
                        case C.MEASUREMENTS_TYPE_SPO2:
                            tmpSpo2Data.push({ "x": parseInt(timestamp), "y": (resp[timestamp][typeMeasure]) });
                            break;
                        default:
                            break;
                    }
                });
            });

            setHrData(tmpHrData);
            setBrData(tmpBrData);
            setSttData(tmpSttData);
            setSpo2Data(tmpSpo2Data);

            if (firstCall) {
                var startTime = new Date();
                var endTime = new Date();
                startTime.setHours(endTime.getHours() - 24);

                setHrChartDomain({ x: [startTime, endTime] });
                setSpo2ChartDomain({ x: [startTime, endTime] });
                setBtChartDomain({ x: [startTime, endTime] });
                setBrChartDomain({ x: [startTime, endTime] });
                setSummaryDomainFrom(startTime);


                //Setto punto iniziale e punto finale per i grafici
                var startDate = new Date();
                var endDate = new Date();
                startDate.setHours((startDate.getHours() - 48));

                setHrDelimiterScatter([
                    { "x": parseInt(startDate.getTime()), "y": 90 },
                    { "x": parseInt(endDate.getTime()), "y": 90 }
                ]);

                setSpo2DelimiterScatter([
                    { "x": parseInt(startDate.getTime()), "y": 95 },
                    { "x": parseInt(endDate.getTime()), "y": 95 }
                ]);

                setSttDelimiterScatter([
                    { "x": parseInt(startDate.getTime()), "y": 37 },
                    { "x": parseInt(endDate.getTime()), "y": 37 }
                ]);

                setBrDelimiterScatter([
                    { "x": parseInt(startDate.getTime()), "y": 15 },
                    { "x": parseInt(endDate.getTime()), "y": 15 }
                ]);
            }

        });


    }

    const changeSummaryChartsDomain = (domain) => {

        //Faccio scorrere i quattro grafici in maniera simultanea
        setHrChartDomain({ x: domain.x, y: C.yScaleHR });
        setSpo2ChartDomain({ x: domain.x, y: C.yScaleSpo2 });
        setBtChartDomain({ x: domain.x, y: C.yScaleSTT });
        setBrChartDomain({ x: domain.x, y: C.yScaleBR });

        let center = new Date(domain.x[1]);
        center.setHours((center.getHours() - 12));

        if (new Date(center).getTime() - new Date(hrDelimiterScatter[0].x).getTime() <= 86400000
            || new Date(hrDelimiterScatter[1].x).getTime() - new Date(center).getTime() <= 86400000
        ) {
            setCharts({ hours: null, timestamp: center.getTime() }, false);
            var startDate = new Date(domain.x[0]);
            var endDate = new Date(domain.x[1]);
            startDate.setHours((startDate.getHours() - 36));
            endDate.setHours((endDate.getHours() + 36));
            endDate = endDate < new Date() ? endDate : new Date();

            setHrDelimiterScatter([
                { "x": parseInt(startDate.getTime()), "y": 90 },
                { "x": parseInt(endDate.getTime()), "y": 90 }
            ]);

            setSpo2DelimiterScatter([
                { "x": parseInt(startDate.getTime()), "y": 95 },
                { "x": parseInt(endDate.getTime()), "y": 95 }
            ]);

            setSttDelimiterScatter([
                { "x": parseInt(startDate.getTime()), "y": 37 },
                { "x": parseInt(endDate.getTime()), "y": 37 }
            ]);

            setBrDelimiterScatter([
                { "x": parseInt(startDate.getTime()), "y": 15 },
                { "x": parseInt(endDate.getTime()), "y": 15 }
            ]);

        }

        setSummaryDomainFrom(new Date(domain.x[0]));
        setSummaryDomainTo(new Date(domain.x[1]));
        setSummaryDomainCenter(center);
    }

    const [isCallingDetail, setIsCallingDetail] = useState(false);

    //Liste Contenenti Tutti i valori, con relativo timestamp, per un determinato lasso di tempo. Le liste si riaggiorneranno ad ogni chiamata
    const [actTimeFrame, setActTimeFrame] = useState([]);
    const [brTimeFrame, setBrTimeFrame] = useState([]);
    const [hrTimeFrame, setHrTimeFrame] = useState([]);
    const [speedTimeFrame, setSpeedTimeFrame] = useState([]);
    const [stfTimeFrame, setStfTimeFrame] = useState([]);
    const [sttTimeFrame, setSttTimeFrame] = useState([]);
    const [spo2TimeFrame, setSpo2TimeFrame] = useState([]);
    const [rrTimeFrame, setRrTimeFrame] = useState([]);


    // const [monitoredMeasurementTime, setMonitoredMeasurementTime] = useState('');
    const [monitoredTimestamp, setMonitoredTimestamp] = useState(0);
    const [monitoredAct, setMonitoredAct] = useState('');
    const [monitoredBr, setMonitoredBr] = useState(0);
    const [monitoredEcg, setMonitoredEcg] = useState([]);
    const [monitoredHr, setMonitoredHr] = useState(0);
    const [monitoredImp, setMonitoredImp] = useState([]);
    const [monitoredSpeed, setMonitoredSpeed] = useState(0);
    const [monitoredStf, setMonitoredStf] = useState('-');
    const [monitoredStt, setMonitoredStt] = useState('-');
    const [monitoredSpo2, setMonitoredSpo2] = useState('-');
    const [monitoredRr, setMonitoredRr] = useState(0);

    const [isMonitoring, setIsMonitoring] = useState(false);

    const HideMonitoringPanel = () => {
        setMonitoredAct('');
        setMonitoredBr(0);
        setMonitoredEcg([]);
        setMonitoredHr(0);
        setMonitoredImp([]);
        setMonitoredSpeed(0);
        setMonitoredStf('-');
        setMonitoredStt('-');
        setMonitoredSpo2('-');
        setMonitoredRr(0);
        setIsMonitoring(false);
    }

    //Cambio il range di ore visibili per il grafico summary
    const changeVisibleSummaryRange = (newRange) => {
        //Cambio il CSS
        switch (newRange) {
            case 1:
                setIsSelectedOneRange(true);
                setIsSelectedEightRange(false);
                setIsSelectedSixteenRange(false);
                setIsSelectedTwentyfourRange(false);
                break;
            case 8:
                setIsSelectedOneRange(false);
                setIsSelectedEightRange(true);
                setIsSelectedSixteenRange(false);
                setIsSelectedTwentyfourRange(false);
                break;
            case 16:
                setIsSelectedOneRange(false);
                setIsSelectedEightRange(false);
                setIsSelectedSixteenRange(true);
                setIsSelectedTwentyfourRange(false);
                break;
            case 24:
                setIsSelectedOneRange(false);
                setIsSelectedEightRange(false);
                setIsSelectedSixteenRange(false);
                setIsSelectedTwentyfourRange(true);
                break;
            default:
                break;
        }

        const toAddOrRemove = newRange;
        let center = new Date(summaryDomainCenter);
        const startTime = center.setHours((center.getHours() - toAddOrRemove));
        const endTime = center.setHours((center.getHours() + toAddOrRemove));

        //Cambio le variabili di tempo
        setSummaryDomainFrom(startTime);
        setSummaryDomainTo(endTime);

        //Cambio il range dei grafici
        setHrChartDomain({ x: [startTime, endTime] });
        setSpo2ChartDomain({ x: [startTime, endTime] });
        setBtChartDomain({ x: [startTime, endTime] });
        setBrChartDomain({ x: [startTime, endTime] });
    };

    //START DETAIL

    const getDetailMeasureByPatient = async (timestamp) => {
        const rawData = await PatientService.getDetailMeasureByPatient(patientId, timestamp.getTime(), 40);
        const data = await rawData.json();

        // const data = getMockDetailValues(); 

        setMonitoredAct('');
        setMonitoredBr(0);
        setMonitoredEcg([]);
        setMonitoredHr(0);
        setMonitoredImp([]);
        setMonitoredSpeed(0);
        setMonitoredStf('-');
        setMonitoredStt('-');
        setMonitoredSpo2('-');
        setMonitoredRr(0);

        // let ecgLenght = 0;
        // let impLenght = 0;

        let start = new Date(timestamp);
        let end = new Date(timestamp);
        start.setSeconds((start.getSeconds() - 5));
        end.setSeconds((end.getSeconds()));
        // end.setSeconds((end.getSeconds() + 5));
        setStartTimeIndicator(new Date(start));
        setTimeIndicator(new Date(timestamp));
        setEndTimeIndicator(new Date(end));
        setEcgChartDomain({ x: [start.getTime(), end.getTime()] });
        setImpChartDomain({ x: [start.getTime(), end.getTime()] });

        setMeasures(data.values);

        setIsMonitoring(true);
        resizeDimensions();

        //Conservo il timestamp di dettaglio per la stampa
        setMonitoredTimestamp(timestamp.getTime());
    }

    const setMeasures = (measures) => {

        Object.keys(measures).forEach(function (key) {
            switch (key) {
                case C.MEASUREMENTS_TYPE_ACT:
                    setActTimeFrame(measures[key]);
                    const nearestAct = getNearestValue(timeIndicator, actTimeFrame)
                    setMonitoredAct(nearestAct != null ? nearestAct : '-');
                    break;
                case C.MEASUREMENTS_TYPE_BR:
                    setBrTimeFrame(measures[key]);
                    const nearestBr = getNearestValue(timeIndicator, brTimeFrame);
                    setMonitoredBr(nearestBr != null ? nearestBr : '-');
                    break;
                case C.MEASUREMENTS_TYPE_ECG:
                    let listCoordinateEcg = [];
                    Object.keys(measures[key]).forEach(function (elem) {
                        listCoordinateEcg.push({ "x": parseInt(elem), "y": (measures[key][elem]) });
                    })
                    setMonitoredEcg(listCoordinateEcg);
                    break;
                case C.MEASUREMENTS_TYPE_HR:
                    setHrTimeFrame(measures[key]);
                    const nearestHr = getNearestValue(timeIndicator, hrTimeFrame);
                    setMonitoredHr(nearestHr != null ? nearestHr : '-');
                    break;
                case C.MEASUREMENTS_TYPE_IMP:
                    let listCoordinateImp = [];
                    Object.keys(measures[key]).forEach(function (elem) {
                        listCoordinateImp.push({ "x": parseInt(elem), "y": (measures[key][elem]) });
                    })
                    setMonitoredImp(listCoordinateImp);
                    break;
                case C.MEASUREMENTS_TYPE_SPEED:
                    setSpeedTimeFrame(measures[key]);
                    const nearestSpeed = getNearestValue(timeIndicator, speedTimeFrame);
                    setMonitoredSpeed(nearestSpeed != null ? nearestSpeed : '-');
                    break;
                case C.MEASUREMENTS_TYPE_ST_F:
                    setStfTimeFrame(measures[key]);
                    const nearestStf = getNearestValue(timeIndicator, stfTimeFrame);
                    setMonitoredStf(nearestStf != null ? nearestStf : '-');
                    break;
                case C.MEASUREMENTS_TYPE_ST_T:
                    setSttTimeFrame(measures[key]);
                    const nearestStt = getNearestValue(timeIndicator, sttTimeFrame);
                    setMonitoredStt(nearestStt != null ? nearestStt : '-');
                    break;
                case C.MEASUREMENTS_TYPE_SPO2:
                    setSpo2TimeFrame(measures[key]);
                    const nearestSpo2 = getNearestValue(timeIndicator, spo2TimeFrame);
                    setMonitoredSpo2(nearestSpo2 != null ? nearestSpo2 : '-');
                    break;
                case C.MEASUREMENTS_TYPE_RR:
                    setRrTimeFrame(measures[key]);
                    const nearestRr = getNearestValue(timeIndicator, rrTimeFrame);
                    setMonitoredRr(nearestRr != null ? nearestRr : '-');
                    break;
                default:
                    break;
            }
        });

        setIsCallingDetail(false);
    }

    const getNearestValue = (timestamp, arr) => {
        if (arr[timestamp]) {
            return arr[timestamp];
        }

        let listKeys = [];
        listKeys.push(parseInt(timestamp));
        Object.keys(arr).forEach(function (key) {
            listKeys.push(parseInt(key));
        });
        listKeys.sort();

        const timestampIndex = listKeys.indexOf(parseInt(timestamp));

        return timestampIndex > 0 ? arr[listKeys[(parseInt(timestampIndex) - 1)]] : null;
    }

    const [ecgChartDomain, setEcgChartDomain] = useState();
    const [impChartDomain, setImpChartDomain] = useState();

    const [startTimeIndicator, setStartTimeIndicator] = useState();
    const [timeIndicator, setTimeIndicator] = useState();
    const [endTimeIndicator, setEndTimeIndicator] = useState();

    const changeChartsDomain = (domain) => {
        setGrabClass("cursor-grabbing");
        //Faccio scorrere i due grafici in maniera simultanea
        setEcgChartDomain({ x: domain.x, y: C.yScaleECG });
        setImpChartDomain({ x: domain.x, y: C.yScaleIMP });

        let now = domain.x[1] - domain.x[0];
        now = (now / 2);
        now = now + domain.x[0];



        console.log("INIZIO", dateFormat(new Date(domain.x[0]), 'hh:ss:SS'));
        console.log("FINE", dateFormat(new Date(domain.x[1]), 'hh:ss:SS'));

        //In questo punto andrà la chiamata. Verrà eseguita solo quando ci stiamo avvicinando di 10 secondi indietro o avanti nel tempo.
        if ((now - monitoredEcg[0].x <= 10000 || monitoredEcg[monitoredEcg.length - 1].x - now <= 10000) && !isCallingDetail) {
            // const measures = getMockValues();
            setIsCallingDetail(true);
            recallDetail(now);
        }
        // if ((monitoredEcg[0].x - now <= 10000 || now - monitoredEcg[monitoredEcg.length - 1].x <= 10000) && !isCallingDetail) {
        //     // const measures = getMockValues();
        //     setIsCallingDetail(true);
        //     recallDetail(now);
        // }

        setStartTimeIndicator(domain.x[0]);
        setTimeIndicator(parseInt(now));
        setEndTimeIndicator(domain.x[1]);

        updateMeasurements();

    }

    const recallDetail = async (timestamp) => {
        const rawData = await PatientService.getDetailMeasureByPatient(patientId, Math.floor(timestamp), 40);
        const data = await rawData.json();
        setMeasures(data.values);
    }

    const updateMeasurements = () => {
        const nearestAct = getNearestValue(timeIndicator, actTimeFrame)
        setMonitoredAct(nearestAct != null ? nearestAct : '-');
        const nearestBr = getNearestValue(timeIndicator, brTimeFrame);
        setMonitoredBr(nearestBr != null ? nearestBr : '-');
        const nearestHr = getNearestValue(timeIndicator, hrTimeFrame);
        setMonitoredHr(nearestHr != null ? nearestHr : '-');
        const nearestSpeed = getNearestValue(timeIndicator, speedTimeFrame);
        setMonitoredSpeed(nearestSpeed != null ? nearestSpeed : '-');
        const nearestStf = getNearestValue(timeIndicator, stfTimeFrame);
        setMonitoredStf(nearestStf != null ? nearestStf : '-');
        const nearestStt = getNearestValue(timeIndicator, sttTimeFrame);
        setMonitoredStt(nearestStt != null ? nearestStt : '-');
        const nearestSpo2 = getNearestValue(timeIndicator, spo2TimeFrame);
        setMonitoredSpo2(nearestSpo2 != null ? nearestSpo2 : '-');
        const nearestRr = getNearestValue(timeIndicator, rrTimeFrame);
        setMonitoredRr(nearestRr != null ? nearestRr : '-');
    }

    //END DETAIL


    const [grabClass, setGrabClass] = useState("cursor-grab");

    return (
        <>
            {!isMonitoring ?
                <>

                <BrowserView>
                    <div className={`row shadow-xs hmContainer ${isFullscreenMonitor ? 'fullScreenMonitor' : ''}`}>

                        {/* RIGA 0 */}
                        <div className="col-12 p-0 h-auto">
                            <div className="hmTopBarPatient d-md-none d-lg-none d-xl-none"><div className="font-size-h4">{selectedPatient.firstName} {selectedPatient.lastName}</div><div>48 anni</div></div>
                            <div className="hmTopBar">
                                <div className="barContainer hmBR">
                                    <div className="d-flex">
                                        <div className="d-md-none d-lg-none d-xl-none fixBorder"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status-Clock.svg")} /></div>
                                        <div className="d-none d-md-block"><FormattedMessage id="HISTORIC_MONITORING.TIME_MEASURE" /></div>
                                        <div className="barDate">
                                            <div>
                                                <span>{dateFormat(new Date(summaryDomainFrom), 'dd/MM/yyyy')}</span>
                                                <span>{dateFormat(new Date(summaryDomainFrom), 'HH:mm:ss')}</span>
                                            </div>
                                            <div><span>-</span></div>
                                            <div>
                                                <span>{dateFormat(new Date(summaryDomainTo), 'dd/MM/yyyy')}</span>
                                                <span>{dateFormat(new Date(summaryDomainTo), 'HH:mm:ss')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className={`barNavTime ${isSelectedOneRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(1) }}>1h</div>
                                        <div className={`barNavTime ${isSelectedEightRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(8) }}>8h</div>
                                        <div className={`barNavTime ${isSelectedSixteenRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(16) }}>16h</div>
                                        <div className={`barNavTime fixBDesktop ${isSelectedTwentyfourRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(24) }}>24h</div>
                                    </div>
                                </div>
                                <div className="barContainer hmBL">
                                    <div>
                                        <div className="d-flex">
                                            <span className="d-md-none d-lg-none d-xl-none fixBorder"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status-Monitored.svg")} /></span>
                                            <span className="d-none d-md-block">Monitoraggio: </span>

                                            <span className="barMonitoredLabel">{Utils.formatTypeMonitoring(selectedPatient.typeMonitoring)}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="barNavTime brLeft d-md-none d-lg-none d-xl-none"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status-Bell-Solid.svg")} /></div>
                                        <div className="barNavTime fixBorder"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status.svg")} /></div>
                                        <div className="barNavTime" onClick={() => { props.voipCall(selectedPatient) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Voip.svg")} /></div>
                                        <div className="barNavTime d-none d-md-block" onClick={() => { setIsFullscreenMonitor(!isFullscreenMonitor) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-EspandiFinestra.svg")} /></div>
                                        <div className="barNavTime"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-GPS.svg")} /></div>
                                        <div className="barNavTime"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/PowerOff.svg")} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* RIGA 0 */}

                        {/* RIGA 1 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Heart.svg")} /></div>
                                    <div>{C.MEASUREMENTS_TYPE_HR}</div>
                                    <div>{C.UNIT_MEASURE_HR}</div>
                                </div>
                                <VictoryChart
                                    width="2000"
                                    domain={{ y: C.yScaleHR }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={hrChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '28px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleHR[0], valuesRef.hrYellow, valuesRef.hrRed, C.yScaleHR[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryAxis
                                        independentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0.5 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '28px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        orientation="top"
                                        standalone={false}
                                        tickFormat={(x) => dateFormat(new Date(x), 'HH:mm')}
                                    />
                                    {/* <VictoryAxis dependentAxis
                                        style={{
                                            axis: { strokeWidth: 0 }
                                        }}
                                        standalone={false}
                                        tickValues={null}
                                        tickFormat={() => null}
                                    /> */}
                                    <VictoryLine
                                        name="chartLine"
                                        // interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={hrData}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    (datum.y >= valuesRef.gteHrRed || datum.y <= valuesRef.lteHrRed) ? 'red'
                                                        : (datum.y >= valuesRef.gteHrYellow || datum.y <= valuesRef.lteHrYellow) ? 'orange'
                                                            : 'green',
                                            },
                                        }}
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                                onClick: () => {
                                                    return [
                                                        {
                                                            target: "data",
                                                            mutation: (props) => {
                                                                getDetailMeasureByPatient(new Date(props.datum.x));
                                                            }
                                                        }
                                                    ];
                                                }
                                            }
                                        }]}
                                        data={hrData}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy - HH:mm:ss')} \n ${datum.y} bpm`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        (datum.y >= valuesRef.gteHrRed || datum.y <= valuesRef.lteHrRed) ? 'red'
                                                            : (datum.y >= valuesRef.gteHrYellow || datum.y <= valuesRef.lteHrYellow) ? 'orange'
                                                                : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    // datum.y >= valuesRef.hrRed ? 'red' : datum.y >= valuesRef.hrYellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={hrDelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 1 */}

                        {/* RIGA 2 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Breath.svg")} /></div>
                                    <div>{C.MEASUREMENTS_TYPE_BR}</div>
                                    <div>{C.UNIT_MEASURE_BR}</div>
                                </div>
                                <VictoryChart
                                    width="2000"
                                    domain={{ y: C.yScaleBR }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={brChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleBR[0], valuesRef.brYellow, valuesRef.brRed, C.yScaleBR[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryLine
                                        name="chartLine"
                                        interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={brData}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    (datum.y >= valuesRef.gteBrRed || datum.y <= valuesRef.lteBrRed) ? 'red'
                                                        : (datum.y >= valuesRef.gteBrYellow || datum.y <= valuesRef.lteBrYellow) ? 'orange'
                                                            : 'green',

                                            },
                                        }}
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                                onClick: () => {
                                                    return [
                                                        {
                                                            target: "data",
                                                            mutation: (props) => {
                                                                getDetailMeasureByPatient(new Date(props.datum.x));
                                                            }
                                                        }
                                                    ];
                                                }
                                            }
                                        }]}
                                        data={brData}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss')} \n ${datum.y} rpm`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        (datum.y >= valuesRef.gteBrRed || datum.y <= valuesRef.lteBrRed) ? 'red'
                                                            : (datum.y >= valuesRef.gteBrYellow || datum.y <= valuesRef.lteBrYellow) ? 'orange'
                                                                : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    // datum.y >= valuesRef.brRed ? 'red' : datum.y >= valuesRef.brYellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={brDelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 2 */}

                        {/* RIGA 3 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Temperature.svg")} /></div>
                                    <div>BT</div>
                                    <div>&#8451;</div>
                                </div>
                                <VictoryChart
                                    width="2000"
                                    domain={{ y: C.yScaleSTT }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={btChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleSTT[0], valuesRef.sttYellow, valuesRef.sttRed, C.yScaleSTT[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryLine
                                        name="chartLine"
                                        interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={sttData}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    datum.y >= valuesRef.gteSttRed ? 'red' : datum.y >= valuesRef.gteSttYellow ? 'orange' : 'green',
                                            },
                                        }}
                                        data={sttData}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss')} \n ${datum.y} °C`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        datum.y >= valuesRef.gteSttRed ? 'red' : datum.y >= valuesRef.gteSttYellow ? 'orange' : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    // datum.y >= valuesRef.sttRed ? 'red' : datum.y >= valuesRef.sttYellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={sttDelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 3 */}

                        {/* RIGA 4 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Spo2.svg")} /></div>
                                    <div>{C.MEASUREMENTS_TYPE_SPO2}</div>
                                    <div>{C.UNIT_MEASURE_SPO2}</div>
                                </div>
                                <VictoryChart
                                    width="2000"
                                    domain={{ y: C.yScaleSpo2 }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={spo2ChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleSpo2[0], valuesRef.spo2Yellow, valuesRef.spo2Red, C.yScaleSpo2[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryLine
                                        name="chartLine"
                                        interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={spo2Data}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    datum.y <= valuesRef.lteSpo2Red ? 'red' : datum.y <= valuesRef.lteSpo2Yellow ? 'orange' : 'green',
                                            },
                                        }}
                                        data={spo2Data}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss')} \n ${datum.y}%`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        datum.y <= valuesRef.lteSpo2Red ? 'red' : datum.y <= valuesRef.lteSpo2Yellow ? 'orange' : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    //datum.y >= valuesRef.spo2Red ? 'red' : datum.y >= valuesRef.spo2Yellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={spo2DelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 4 */}

                    </div>
                </BrowserView>
                <MobileView>
                    <div className={`row shadow-xs hmContainer ${isFullscreenMonitor ? 'fullScreenMonitor' : ''}`}>

                        {/* RIGA 0 */}
                        <div className="col-12 p-0 h-auto">
                            <div className="hmTopBarPatient d-md-none d-lg-none d-xl-none"><div className="font-size-h4">{selectedPatient.firstName} {selectedPatient.lastName}</div><div>48 anni</div></div>
                            <div className="hmTopBar">
                                <div className="barContainer hmBR">
                                    <div className="d-flex">
                                        <div className="d-md-none d-lg-none d-xl-none fixBorder"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status-Clock.svg")} /></div>
                                        <div className="d-none d-md-block"><FormattedMessage id="HISTORIC_MONITORING.TIME_MEASURE" /></div>
                                        <div className="barDate">
                                            <div>
                                                <span>{dateFormat(new Date(summaryDomainFrom), 'dd/MM/yyyy')}</span>
                                                <span>{dateFormat(new Date(summaryDomainFrom), 'HH:mm:ss')}</span>
                                            </div>
                                            <div><span>-</span></div>
                                            <div>
                                                <span>{dateFormat(new Date(summaryDomainTo), 'dd/MM/yyyy')}</span>
                                                <span>{dateFormat(new Date(summaryDomainTo), 'HH:mm:ss')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className={`barNavTime ${isSelectedOneRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(1) }}>1h</div>
                                        <div className={`barNavTime ${isSelectedEightRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(8) }}>8h</div>
                                        <div className={`barNavTime ${isSelectedSixteenRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(16) }}>16h</div>
                                        <div className={`barNavTime fixBDesktop ${isSelectedTwentyfourRange ? 'barNavTimeSelected' : ''}`} onClick={() => { changeVisibleSummaryRange(24) }}>24h</div>
                                    </div>
                                </div>
                                <div className="barContainer hmBL">
                                    <div>
                                        <div className="d-flex" style={{borderLeft: 'none'}}>
                                            <span className="d-md-none d-lg-none d-xl-none fixBorder"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status-Monitored.svg")} /></span>
                                            <span className="d-none d-md-block">Monitoraggio: </span>

                                            <span className="barMonitoredLabel">{Utils.formatTypeMonitoring(selectedPatient.typeMonitoring)}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="barNavTime brLeft d-md-none d-lg-none d-xl-none"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status-Bell-Solid.svg")} /></div>
                                        <div className="barNavTime fixBorder"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Status.svg")} /></div>
                                        <div className="barNavTime" onClick={() => { props.voipCall(selectedPatient) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Voip.svg")} /></div>
                                        <div className="barNavTime d-none d-md-block" onClick={() => { setIsFullscreenMonitor(!isFullscreenMonitor) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-EspandiFinestra.svg")} /></div>
                                        <div className="barNavTime"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-GPS.svg")} /></div>
                                        <div className="barNavTime"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/PowerOff.svg")} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* RIGA 0 */}

                        {/* RIGA 1 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Heart.svg")} /></div>
                                    <div>{C.MEASUREMENTS_TYPE_HR}</div>
                                    <div>{C.UNIT_MEASURE_HR}</div>
                                </div>
                                <VictoryChart
                                    width="800"
                                    domain={{ y: C.yScaleHR }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={hrChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleHR[0], valuesRef.hrYellow, valuesRef.hrRed, C.yScaleHR[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryAxis
                                        independentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0.5 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        orientation="top"
                                        standalone={false}
                                        tickFormat={(x) => dateFormat(new Date(x), 'HH:mm')}
                                    />
                                    {/* <VictoryAxis dependentAxis
                                        style={{
                                            axis: { strokeWidth: 0 }
                                        }}
                                        standalone={false}
                                        tickValues={null}
                                        tickFormat={() => null}
                                    /> */}
                                    <VictoryLine
                                        name="chartLine"
                                        // interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={hrData}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    (datum.y >= valuesRef.gteHrRed || datum.y <= valuesRef.lteHrRed) ? 'red'
                                                        : (datum.y >= valuesRef.gteHrYellow || datum.y <= valuesRef.lteHrYellow) ? 'orange'
                                                            : 'green',
                                            },
                                        }}
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                                onClick: () => {
                                                    return [
                                                        {
                                                            target: "data",
                                                            mutation: (props) => {
                                                                getDetailMeasureByPatient(new Date(props.datum.x));
                                                            }
                                                        }
                                                    ];
                                                }
                                            }
                                        }]}
                                        data={hrData}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy - HH:mm:ss')} \n ${datum.y} bpm`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        (datum.y >= valuesRef.gteHrRed || datum.y <= valuesRef.lteHrRed) ? 'red'
                                                            : (datum.y >= valuesRef.gteHrYellow || datum.y <= valuesRef.lteHrYellow) ? 'orange'
                                                                : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    // datum.y >= valuesRef.hrRed ? 'red' : datum.y >= valuesRef.hrYellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={hrDelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 1 */}

                        {/* RIGA 2 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Breath.svg")} /></div>
                                    <div>{C.MEASUREMENTS_TYPE_BR}</div>
                                    <div>{C.UNIT_MEASURE_BR}</div>
                                </div>
                                <VictoryChart
                                    width="800"
                                    domain={{ y: C.yScaleBR }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={brChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleBR[0], valuesRef.brYellow, valuesRef.brRed, C.yScaleBR[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryLine
                                        name="chartLine"
                                        interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={brData}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    (datum.y >= valuesRef.gteBrRed || datum.y <= valuesRef.lteBrRed) ? 'red'
                                                        : (datum.y >= valuesRef.gteBrYellow || datum.y <= valuesRef.lteBrYellow) ? 'orange'
                                                            : 'green',

                                            },
                                        }}
                                        events={[{
                                            target: "data",
                                            eventHandlers: {
                                                onClick: () => {
                                                    return [
                                                        {
                                                            target: "data",
                                                            mutation: (props) => {
                                                                getDetailMeasureByPatient(new Date(props.datum.x));
                                                            }
                                                        }
                                                    ];
                                                }
                                            }
                                        }]}
                                        data={brData}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss')} \n ${datum.y} rpm`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        (datum.y >= valuesRef.gteBrRed || datum.y <= valuesRef.lteBrRed) ? 'red'
                                                            : (datum.y >= valuesRef.gteBrYellow || datum.y <= valuesRef.lteBrYellow) ? 'orange'
                                                                : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    // datum.y >= valuesRef.brRed ? 'red' : datum.y >= valuesRef.brYellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={brDelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 2 */}

                        {/* RIGA 3 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Temperature.svg")} /></div>
                                    <div>BT</div>
                                    <div>&#8451;</div>
                                </div>
                                <VictoryChart
                                    width="800"
                                    domain={{ y: C.yScaleSTT }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={btChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleSTT[0], valuesRef.sttYellow, valuesRef.sttRed, C.yScaleSTT[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryLine
                                        name="chartLine"
                                        interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={sttData}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    datum.y >= valuesRef.gteSttRed ? 'red' : datum.y >= valuesRef.gteSttYellow ? 'orange' : 'green',
                                            },
                                        }}
                                        data={sttData}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss')} \n ${datum.y} °C`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        datum.y >= valuesRef.gteSttRed ? 'red' : datum.y >= valuesRef.gteSttYellow ? 'orange' : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    // datum.y >= valuesRef.sttRed ? 'red' : datum.y >= valuesRef.sttYellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={sttDelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 3 */}

                        {/* RIGA 4 */}
                        <div className="strip col-12">
                            <div>
                                <div>
                                    <div className="stripColIcon"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Spo2.svg")} /></div>
                                    <div>{C.MEASUREMENTS_TYPE_SPO2}</div>
                                    <div>{C.UNIT_MEASURE_SPO2}</div>
                                </div>
                                <VictoryChart
                                    width="800"
                                    domain={{ y: C.yScaleSpo2 }}
                                    scale={{ x: "time" }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            allowZoom={false}
                                            zoomDomain={spo2ChartDomain}
                                            onZoomDomainChange={changeSummaryChartsDomain.bind(this)}
                                        />}>
                                    <VictoryAxis
                                        dependentAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 0 },
                                            tickLabels: { fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fill: '#FCF4A3' },
                                        }}
                                        tickValues={[C.yScaleSpo2[0], valuesRef.spo2Yellow, valuesRef.spo2Red, C.yScaleSpo2[1]]}
                                        tickFormat={t => `${Math.round(t)}`}
                                        standalone={false}
                                    />
                                    <VictoryLine
                                        name="chartLine"
                                        interpolation="catmullRom"
                                        style={{ data: { stroke: '#FFFFFF', strokeWidth: 4 } }}
                                        data={spo2Data}
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={8}
                                        style={{
                                            data: {
                                                fill: ({ datum }) =>
                                                    datum.y <= valuesRef.lteSpo2Red ? 'red' : datum.y <= valuesRef.lteSpo2Yellow ? 'orange' : 'green',
                                            },
                                        }}
                                        data={spo2Data}
                                        labels={({ datum }) => `${dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss')} \n ${datum.y}%`}
                                        labelComponent={
                                            <VictoryTooltip
                                                // className="cursor-pointer"
                                                flyoutStyle={{
                                                    stroke: ({ datum }) =>
                                                        datum.y <= valuesRef.lteSpo2Red ? 'red' : datum.y <= valuesRef.lteSpo2Yellow ? 'orange' : 'green',
                                                    strokeWidth: 3,
                                                    fill: '#000000',
                                                }}
                                                style={{
                                                    // fill: ({ datum }) =>
                                                    //datum.y >= valuesRef.spo2Red ? 'red' : datum.y >= valuesRef.spo2Yellow ? 'orange' : 'green',
                                                    fontFamily: 'poppins',
                                                    fontSize: 24,
                                                    fill: '#FFFFFF',
                                                }}
                                                cornerRadius={5}
                                            />
                                        }
                                    />
                                    <VictoryScatter
                                        symbol={"circle"}
                                        size={0}
                                        data={spo2DelimiterScatter}
                                    />
                                </VictoryChart>
                            </div>
                        </div>
                        {/* RIGA 4 */}

                    </div>
                </MobileView>
                </>                    
            : //DIVIDER
                <>
                    <BrowserView >
                        <div className={`hmContainer ${isFullscreenMonitor ? 'fullScreenMonitor' : ''}`}>
                            {/* HTML DA RIVEDERE */}
                            <div className="hmTopBar">
                                <div className="hmServerTime">
                                    <span className="barMonitoredLabel text-white">
                                        <span style={{ color: "#FCF4A3" }}>{dateFormat(new Date(startTimeIndicator), 'dd/MM/yyyy HH:mm:ss:SS')}</span>
                                    </span>
                                    <span className="barMonitoredLabel text-white">
                                        <FormattedMessage id="HISTORIC_MONITORING.TIME_MEASURE" />: <span style={{ color: "#FCF4A3" }}>{dateFormat(new Date(timeIndicator), 'dd/MM/yyyy HH:mm:ss')}</span>
                                    </span>
                                    <span className="barMonitoredLabel text-white">
                                        <span style={{ color: "#FCF4A3" }}>{dateFormat(new Date(endTimeIndicator), 'dd/MM/yyyy HH:mm:ss:SS')}</span>
                                    </span>
                                </div>
                                <div className="hmWarningContainer">
                                    <div className="hmWarningDefault"></div>
                                </div>
                                <div className="hmTopBarActionMenu">
                                    <div className="barNavTime align-items-center" ><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Status.svg")} /></div>
                                    <div className="barNavTime align-items-center" ><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/BatteryFull.svg")} /></div>
                                    <div className="barNavTime align-items-center" onClick={() => { setIsFullscreenMonitor(!isFullscreenMonitor); }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Expand.svg")} /></div>
                                </div>
                            </div>
                            <div className="hmMainArea">
                                {/* <div className="hmGraphBox"> */}
                                <div className="hmGraphBox" ref={targetRef} >
                                    <div className="lineStatus"></div>
                                    <VictoryChart
                                        padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                                        height={dimensions.height}
                                        width={dimensions.width}
                                        domain={{ y: C.yScaleECG }}
                                        scale={{ x: "time" }} containerComponent={<VictoryZoomContainer
                                            className={grabClass}
                                            allowZoom={true}
                                            zoomDomain={ecgChartDomain}
                                            events={{
                                                onClick: () => setGrabClass("cursor-grab")
                                            }}
                                            onZoomDomainChange={changeChartsDomain.bind(this)} />}
                                    >
                                        <VictoryAxis dependentAxis
                                            style={{
                                                axis: { stroke: '#FCF4A3', strokeWidth: 0 },
                                            }}
                                            offsetX={750}
                                            // domain={[0, 30]}
                                            standalone={false}
                                            tickValues={null}
                                            tickFormat={(y) => null}
                                        />
                                        {/* <VictoryAxis crossAxis
                                    style={{
                                        axis: { stroke: '#FFFFFF', strokeWidth: 1 },
                                        tickLabels: { fill: 'white' },
                                    }}
                                    domain={[0, 30]}
                                    standalone={false}
                                    // tickValues={monitoredChartTickValues}
                                    // tickFormat={(x) => x / 1000 + "''"}
                                    tickValues={null}
                                    tickFormat={(x) => null}
                                /> */}
                                        <VictoryLine style={{ data: { stroke: "#02FC02", strokeWidth: 2 } }} data={monitoredEcg} />
                                    </VictoryChart>




                                </div>
                                {/* <div className="hmGraphBox" ref={targetRef}> */}
                                <div className="hmGraphBox" >
                                    <div className="lineStatus"></div>
                                    <VictoryChart
                                        padding={{ top: 20, bottom: 20, right: 10, left: 10 }}
                                        height={dimensions.height}
                                        width={dimensions.width}
                                        domain={{ y: C.yScaleIMP }}
                                        containerComponent={
                                            <VictoryZoomContainer
                                                className={grabClass}
                                                allowZoom={true}
                                                zoomDomain={impChartDomain}
                                                events={{
                                                    onClick: () => setGrabClass("cursor-grab")
                                                }}
                                                onZoomDomainChange={changeChartsDomain.bind(this)} />}
                                    >
                                        <VictoryAxis dependentAxis
                                            // crossAxis
                                            style={{
                                                // grid: {stroke: '#FFFFFF', strokeWidth: 0.3},
                                                axis: { stroke: '#FCF4A3', strokeWidth: 0 },
                                                // ticks: {stroke: '#FFFFFF', size:5},
                                                // tickLabels: { fill: 'white' },
                                            }}
                                            offsetX={750}
                                            // domain={[0, 30]}
                                            standalone={false}
                                            tickValues={null}
                                            tickFormat={(y) => null}
                                        />
                                        {/* <VictoryAxis crossAxis
                                        style={{
                                            axis: { stroke: '#FFFFFF', strokeWidth: 1 },
                                            tickLabels: { fill: 'white' },
                                        }}
                                        domain={[0, 30]}
                                        standalone={false}
                                        // orientation="top"
                                        tickValues={monitoredChartTickValues}
                                        tickFormat={(x) => x / 1000 + "''"}
                                        tickValues={null}
                                        tickFormat={(x) => null}
                                    /> */}
                                        <VictoryLine interpolation={"natural"} containerComponent={<VictoryContainer responsive={false} />} style={{ data: { stroke: "#FAFA04", strokeWidth: 2 } }} data={monitoredImp} />
                                    </VictoryChart>






                                </div>

                                {/* <div className="hmGraphBox d-flex hmGraphBoxOverflow" ref={targetRef}> */}
                                <div className="hmGraphBox d-flex hmGraphBoxOverflow" >

                                    {/* hmBorderWARNING */}
                                    <div className="hmMeasureContainerRow colorOrange ">
                                        <div className="hmMeasureCol">
                                            <div>
                                                <div className="hmMeasureTitle">
                                                    <FormattedMessage id="MEASURE.TITLE.BODY_TEMP" />
                                                </div>
                                                39<br />36
                                </div>
                                            <div>
                                                <SVG className="fillOrange" src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Temperature.svg")} />
                                            </div>
                                        </div>
                                        <div className="hmMeasureCol2">
                                            <div className="hmMeasureUnit">
                                                &#8451;
                                </div>
                                            <div className="hmMeasureParameter" style={{ fontSize: '5rem', lineHeight: '8rem' }}>
                                                {monitoredStt}
                                            </div>
                                            <div style={{ position: 'absolute', bottom: '21px', left: '55px' }}>stt Time</div>
                                        </div>
                                        <div className="hmMeasureCol3">
                                            <div>
                                                <span className="hmMeasureTitle2">
                                                    <FormattedMessage id="MEASURE.TITLE.SKIN_TEMP" />
                                                </span>&#8451;
                                </div>
                                            <div className="hmMeasureParameter2">
                                                {monitoredStf}
                                            </div>
                                        </div>
                                    </div>

                                    {/* hmBorderWARNING */}
                                    <div className="hmMeasureContainerRow colorRed ">

                                    </div>

                                    {/* hmBorderWARNING */}
                                    <div className="hmMeasureContainerRow colorRed">

                                    </div>

                                </div>
                            </div>
                            <div className="hmRightArea">

                                {/* hmBorderWARNING */}
                                <div className="hmMeasureContainer colorGreen ">
                                    <div className="hmMeasureCol">
                                        <div>
                                            <div className="hmMeasureTitle">
                                                <FormattedMessage id="MEASURE.TITLE.HR" />
                                            </div>
                                            130<br />70
                                </div>
                                        {/* <div style={{ animation: 'blinker 1000ms linear infinite' }}> */}
                                        <div>
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/ECG-Heart.svg")} />
                                        </div>
                                    </div>
                                    <div className="hmMeasureCol2">
                                        <div className="hmMeasureUnit">
                                            <FormattedMessage id="MEASURE.MEASURE_UNIT.BPM" />
                                        </div>
                                        <div className="hmMeasureParameter">
                                            {monitoredHr}
                                        </div>
                                    </div>
                                    <div className="hmMeasureCol3">
                                        <div>
                                            <span className="hmMeasureTitle2">
                                                <FormattedMessage id="MEASURE.TITLE.RR" />
                                            </span><FormattedMessage id="MEASURE.MEASURE_UNIT.MS" />
                                        </div>
                                        <div className="hmMeasureParameter2">
                                            {monitoredRr}
                                        </div>
                                    </div>
                                </div>

                                {/* hmBorderWARNING */}
                                <div className="hmMeasureContainer colorYellow ">
                                    <div className="hmMeasureCol">
                                        <div>
                                            <div className="hmMeasureTitle">
                                                <FormattedMessage id="MEASURE.TITLE.BR" />
                                            </div>
                                            8<br />30
                                </div>
                                    </div>
                                    <div className="hmMeasureCol2">
                                        <div className="hmMeasureUnit">
                                            <FormattedMessage id="MEASURE.MEASURE_UNIT.BREATHS_MIN" />
                                        </div>
                                        <div className="hmMeasureParameter">
                                            {monitoredBr}
                                        </div>
                                    </div>
                                    <div className="hmMeasureCol3"></div>
                                </div>
                                {/* hmBorderWARNING */}
                                <div className="hmMeasureContainer colorCyan">
                                    <div className="hmMeasureCol">
                                        <div>
                                            <div className="hmMeasureTitle">
                                                <FormattedMessage id="MEASURE.TITLE.SPO2" />
                                            </div>
                                            100<br />90
                                </div>
                                    </div>
                                    <div className="hmMeasureCol2">
                                        <div className="hmMeasureUnit">
                                            <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                        </div>
                                        <div className="hmMeasureParameter">
                                            {monitoredSpo2 !== null && monitoredSpo2 !== undefined && monitoredSpo2 !== 0 ? monitoredSpo2 : '-'}
                                        </div>
                                    </div>
                                    <div className="hmMeasureCol3"> spo2Time </div>
                                    <div className="hmMeasureCol4"></div>
                                </div>

                                {/* hmBorderWARNING */}
                                <div className="hmMeasureContainer colorPurple ">
                                    <div className="hmMeasureCol">
                                        <div>
                                            <div className="hmMeasureTitle">
                                                <FormattedMessage id="MEASURE.TITLE.ACTIVITY" />
                                            </div>
                                            {monitoredAct === '-' ? '-' : intl.formatMessage({ id: `MEASURE.TYPE_ACTIVITY_` + monitoredAct })}
                                        </div>
                                    </div>
                                    <div className="hmMeasureCol2">
                                        <div className="hmIconParameter">
                                            {/* {Utils.getActivityIcon(act)} */}
                                        </div>
                                    </div>
                                    <div className="hmMeasureCol3">
                                        <div>
                                            <FormattedMessage id="MEASURE.TITLE.SPEED" />
                                            <br /><FormattedMessage id="MEASURE.MEASURE_UNIT.KM_H" />
                                        </div>
                                        <div className="hmMeasureParameter2">
                                            {monitoredSpeed}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hmSideControl">
                                <button className="hmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Chart-View.svg")} style={{ marginLeft: '3px', height: '43px', width: '43px' }} /></button>
                                <button className="hmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Grid-View.svg")} style={{ marginLeft: '3px' }} /></button>
                                <button className="hmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Historic-Alert.svg")} style={{ marginLeft: '3px' }} /></button>
                                <button className="hmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Notification.svg")} style={{ marginLeft: '6px', height: '30px', width: '30px' }} /></button>
                                <button className="hmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Notification-Alert.svg")} style={{ marginLeft: '3px' }} /></button>
                                <button className="hmSideButton" onClick={() => { props.voipCall(selectedPatient) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/VOIP.svg")} /></button>
                                {/* <button className="hmSideButton"> */}
                                <a
                                    // Constants.BACKEND_URL + Constants.API_PATIENT+`/${idPatient}`+Constants.API_MEASUREMENT+`/${timestamp}/${type}/csv`
                                    href={`${C.BACKEND_URL}${C.API_PATIENT}/${patientId}${C.API_MEASUREMENT}/${monitoredTimestamp}/ECG/csv`}
                                    title="Stampa CSV"
                                    className="hmSideButton"
                                    download
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/ExportCSV.svg")} style={{ marginLeft: '12px', marginTop: '6px', width: '40px' }} />
                                </a>
                                {/* </button> */}
                                <button className="hmSideButton" onClick={HideMonitoringPanel} aria-label="Torna indietro">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/PowerOff.svg")} style={{ marginLeft: '3px' }} />
                                </button>
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView >
                        <div style={{backgroundColor: 'black', borderRadius: '5px'}}>
                    <div style={{height: '36px', borderBottom: '1px solid white', color: 'white', padding: '10px 0 0 10px', fontWeight: '500'}}>
                        {selectedPatient.firstName} {selectedPatient.lastName}
                    </div>
                    <div style={{height: '40px', borderBottom: '1px solid white'}}>
                        <div style={{ display: 'flex', float: 'left', paddingTop: '7px', paddingLeft: '5px', color: 'white'}}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/MobileLiveServerTimeIcon.svg")} style={{ marginRight: '18px'}} />
                            <div style={{ marginTop: '3px'}} >
                                {/* <FormattedMessage id="HISTORIC_MONITORING.TIME_MEASURE" />: */}
                                <span style={{ color: "#FCF4A3" }}>{dateFormat(new Date(timeIndicator), 'dd/MM/yyyy HH:mm:ss')}</span>
                            </div>
                        </div>

                        <div className="d-flex float-right">
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white', padding: '5px'}}>
                                {/* lmGeneralStatusDANGERMobile oppure lmGeneralStatusWARNINGMobile */}
                                <div className="lmGeneralStatusDANGERMobile">

                                </div>
                            </div>
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white'}}>
                                {/* TO DO, se aggiorniamo la pagina ritorna sulla vista principale */}
                                <NavLink title="Monitoraggio Live" to={`/patient-historic-monitoring`}>
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/PowerOff.svg")} style={{ marginLeft: '10px', marginTop: '2px' }} />
                                </NavLink>
                            </div>
                        </div>


                    </div>
                    <div style={{height: '40px', borderBottom: '1px solid white'}}>
                        <div style={{ display: 'flex', float: 'left', paddingTop: '9px', paddingLeft: '5px', color: 'white'}}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/MobileLiveMonitoredForIcon.svg")} style={{ marginRight: '10px' }} />
                            <div style={{ marginTop: '3px'}} >Covid 19</div>
                        </div>
                        <div className="d-flex float-right">
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white'}}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Notification-Alert.svg")} style={{ marginTop: '5px', marginLeft: '12px' }} />
                            </div>
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white'}}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/BatteryFull.svg")} style={{ width: '50px', height: '30px', margin: '4px 0 0 2px' }} />
                            </div>
                        </div>

                    </div>

                    <div style={{height: '196px', borderBottom: '1px solid white'}}>
                        {/* <div className="lmBorderWARNINGMobile"> */}
                        <div className="">
                            <div style={{height: '120px', position: 'relative'}} ref={targetRef}>
                                <div className="lineStatus h-25px"></div>
                                <VictoryChart
                                    padding={{ top: 20, bottom: 20, right: 10, left: 10 }}
                                    height={dimensions.height}
                                    width={dimensions.width}
                                    domain={{ y: C.yScaleECG }}
                                    scale={{ x: "time" }} containerComponent={<VictoryZoomContainer
                                        className={grabClass}
                                        allowZoom={true}
                                        zoomDomain={ecgChartDomain}
                                        events={{
                                            onClick: () => setGrabClass("cursor-grab")
                                        }}
                                        onZoomDomainChange={changeChartsDomain.bind(this)} />}
                                >
                                    <VictoryAxis dependentAxis
                                        style={{
                                            axis: { stroke: '#FCF4A3', strokeWidth: 0 },
                                        }}
                                        offsetX={750}
                                        // domain={[0, 30]}
                                        standalone={false}
                                        tickValues={null}
                                        tickFormat={(y) => null}
                                    />
                                    {/* <VictoryAxis crossAxis
                                    style={{
                                        axis: { stroke: '#FFFFFF', strokeWidth: 1 },
                                        tickLabels: { fill: 'white' },
                                    }}
                                    domain={[0, 30]}
                                    standalone={false}
                                    // tickValues={monitoredChartTickValues}
                                    // tickFormat={(x) => x / 1000 + "''"}
                                    tickValues={null}
                                    tickFormat={(x) => null}
                                    /> */}
                                    <VictoryLine style={{ data: { stroke: "#02FC02", strokeWidth: 2 } }} data={monitoredEcg} />
                                </VictoryChart>
                            </div>
                            <div className="lmMeasureHRMobile colorGreen">
                                {/* HR */}
                                <div className="lmMeasureColMobile justify-content-between">
                                    <div>
                                        <div className="lmMeasureTitle1Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.HR" />
                                        </div>
                                        130<br />70 <FormattedMessage id="MEASURE.MEASURE_UNIT.BPM" />
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile lmColHR">
                                    <div className="lmMeasureParameterMobile">
                                        <div>
                                            {monitoredHr}
                                        </div>
                                        <div className="hrContainerIconMobile">
                                            <div className="hrIconMobile">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/ECG-Heart.svg")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile">
                                    <div>
                                        <span className="lmMeasureTitle1Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.RR" />
                                        </span><FormattedMessage id="MEASURE.MEASURE_UNIT.MS" />
                                    </div>
                                    <div className="lmMeasureParameter2">
                                        {monitoredRr}
                                    </div>
                                </div>
                                <div className="lmQIColMobile">
                                    <div className="lmMeasureCol1 mr-2">
                                        {/* <FormattedMessage id="MEASURE.TITLE.HR_QI" />
                                        <br />
                                        hrqi <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                        {hrQi} */}
                                    </div>
                                    <div className="lmIQContainer">
                                        {/* <div className="lmWhiteIQFill" style={{ height: hrQiGraph + '%'}}></div> */}
                                        {/* <div className={`${Utils.getQiClassByLevel(hrQi)}`} style={{ height: hrQiGraph + '%' }}></div>
                                        <div className="lmIQRail" style={{ backgroundImage: "url(" + toAbsoluteUrl(Utils.getQiImageByLevel(hrQi)) + ")" }}></div> */}
                                    </div>
                                </div>
                                {/* HR */}
                            </div>
                        </div>
                    </div>
                    <div style={{height: '200px', borderBottom: '1px solid white'}}>
                        <div style={{height: '120px', position: 'relative'}} ref={targetRef}>
                            <div className="lineStatus h-25px"></div>
                                <VictoryChart
                                    padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                                    height={dimensions.height}
                                    width={dimensions.width}
                                    domain={{ y: C.yScaleIMP }}
                                    containerComponent={
                                        <VictoryZoomContainer
                                            className={grabClass}
                                            allowZoom={true}
                                            zoomDomain={impChartDomain}
                                            events={{
                                                onClick: () => setGrabClass("cursor-grab")
                                            }}
                                            onZoomDomainChange={changeChartsDomain.bind(this)} />}
                                >
                                    <VictoryAxis dependentAxis
                                        // crossAxis
                                        style={{
                                            // grid: {stroke: '#FFFFFF', strokeWidth: 0.3},
                                            axis: { stroke: '#FCF4A3', strokeWidth: 0 },
                                            // ticks: {stroke: '#FFFFFF', size:5},
                                            // tickLabels: { fill: 'white' },
                                        }}
                                        offsetX={750}
                                        // domain={[0, 30]}
                                        standalone={false}
                                        tickValues={null}
                                        tickFormat={(y) => null}
                                    />
                                    {/* <VictoryAxis crossAxis
                                    style={{
                                        axis: { stroke: '#FFFFFF', strokeWidth: 1 },
                                        tickLabels: { fill: 'white' },
                                    }}
                                    domain={[0, 30]}
                                    standalone={false}
                                    // orientation="top"
                                    tickValues={monitoredChartTickValues}
                                    tickFormat={(x) => x / 1000 + "''"}
                                    tickValues={null}
                                    tickFormat={(x) => null}
                                /> */}
                                    <VictoryLine interpolation={"natural"} containerComponent={<VictoryContainer responsive={false} />} style={{ data: { stroke: "#FAFA04", strokeWidth: 2 } }} data={monitoredImp} />
                                </VictoryChart>


                        </div>

                        <div className="lmMeasureContainerMobile">
                            {/* RESP */}
                            {/* <div className="lmPar3Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar3Cols">
                                <div className="lmMeasureColMobile colorYellow">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.BR" />
                                        </div>
                                        8<br />30 <FormattedMessage id="MEASURE.MEASURE_UNIT.BREATHS_MIN" />
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile colorYellow">
                                    <div className="lmMeasureParameterMobile">
                                        <div>
                                            {monitoredBr}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FINE RESP */}

                            {/* SPO2 */}
                            {/* <div className="lmPar3Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar3Cols">
                                <div className="lmMeasureColMobile colorCyan">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.SPO2" />
                                        </div>
                                        100<br />90 <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                        <br />
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile colorCyan">
                                    <div className="lmMeasureParameterMobile">
                                        <div>
                                            {monitoredSpo2 !== null && monitoredSpo2 !== undefined && monitoredSpo2 !== 0 ? monitoredSpo2 : '-'}
                                        </div>
                                    </div>
                                    <div className="text-right mt-2">
                                        {/* {spo2Time} */}
                                    </div>
                                </div>
                            </div>
                            {/* FINE SPO2 */}

                            {/* ACTIVITY */}
                            {/* <div className="lmPar3Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar3Cols">
                                <div className="lmMeasureColMobile colorPurple">
                                    <div style={{marginRight: '3px'}}>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.ACTIVITY" />
                                        </div>
                                        {monitoredAct === '-' ? '-' : intl.formatMessage({ id: `MEASURE.TYPE_ACTIVITY_` + monitoredAct })}
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile colorPurple">
                                {/* <div className="lmMeasureParameterMobile"> */}
                                    <div className="">
                                        <div>
                                            <FormattedMessage id="MEASURE.TITLE.SPEED" />
                                            <br /><FormattedMessage id="MEASURE.MEASURE_UNIT.KM_H" />
                                        </div>
                                        <div className="lmMeasureParameter2">
                                            {monitoredSpeed}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FINE ACTIVITY */}
                        </div>
                    </div>

                    <div style={{height: '78px', borderBottom: '1px solid white'}}>
                        <div className="lmMeasureContainerMobile">

                            {/* TBODY */}
                            {/* <div className="lmPar2Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar2Cols ">
                                <div className="lmMeasureColMobile colorOrange">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.BODY_TEMP" /> &#8451;
                                        </div>
                                        39<br />36
                                    </div>
                                </div>
                                <div className="lmMeasureCol2Mobile">
                                    <div className="lmMeasureParameterMobile colorOrange">
                                        <div>
                                            {monitoredStt}
                                        </div>
                                    </div>
                                    <div className="text-right mt-2 colorOrange">
                                        {/* {sttTime} */}
                                    </div>
                                </div>
                            </div>
                            {/* FINE TBODY */}

                            {/* TSKIN */}
                            {/* <div className="lmPar2Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar2Cols ">
                                <div className="lmMeasureColMobile colorOrange">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.SKIN_TEMP" /> &#8451;
                                        </div>
                                        8<br />30
                                    </div>
                                </div>
                                <div className="lmMeasureCol2Mobile">
                                    <div className="lmMeasureParameterMobile colorOrange">
                                        <div>
                                            {monitoredStf}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FINE TSKIN */}
                        </div>
                    </div>
                </div>
                    </MobileView>
                </>
            }
        </>
    );
}

export default injectIntl(PatientHistoricMeasureDetail);