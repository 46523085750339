import React from "react";
import {Typography} from "@material-ui/core";

import {dateToISODateString} from "../../../Utils";

export default function VisitViewComponent({visit}) {
    if (visit) {
        return (
            <>
            <Typography variant="h5">{visit.patient?.historicFirstName} {visit.patient?.historicLastName}
            , {visit.patient?.historicGender === "Maschio" ? "Male" : "Female"}, born {visit.patient?.historicBirthDate}</Typography>
            <Typography variant="body1">Diagnosis: {visit.pathology?.historicName}</Typography>
            <Typography variant="body1">{visit.findings}</Typography>
            <ol>
                {visit.therapies.map((therapy, index) => 
                    <li key={index}>{therapy.pharmaconHistoricName} {therapy.posology}</li>
                )}
            </ol>
            {visit.notifyTime &&
                <Typography variant="body1">A follow-up visit is required after {(new Date(visit.notifyTime)).toUTCString()}</Typography>
            }
            <Typography variant="body1">{visit.author?.historicName}, {dateToISODateString(new Date(visit.creationDate))}</Typography>
            </>
        )
    }
    return null;
}
