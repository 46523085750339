import * as Constants from '../Constants';

export async function getAllProvince() {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PROVINCE;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function getComuniBySiglaProvincia(siglaProvincia) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_COMUNI + `?provinciasigla=${siglaProvincia}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function getFarmaci(filter) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_FARMACI + `?descrizione=${filter.descrizione}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function getDiagnosi(filter) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_DIAGNOSI + `?findAnywhere=${filter.findAnywhere}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function getInterventi(filter) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_INTERVENTI + `?findAnywhere=${filter.findAnywhere}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}