import React from "react";
import { List } from "@material-ui/core";

import UserDocumentListItem from "./UserDocumentListItem";

/**
 * See definition for a full description.
 * @param {object} props
 * @param {array} props.rows An array of userDocument
 * @param {function} props.handleOpenDocument A handler to run when the user clicks on a document
 * @param {function} props.handleDeleteDocument A handler to run when the user clicks on the delete icon for a document
 * @param {User} props.user The current user, as obtained from useUser
 */
export default function UserDocumentList({rows, ...props}) {

    return (
        <List>
            {rows.map(userDocument => (
                <UserDocumentListItem
                    key={userDocument.id}
                    userDocument={userDocument}
                    {...props}
                />
            ))}
        </List>
    )
}
