import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { useFormik } from "formik";

import { format as dateFormat } from 'date-fns';
import { formatTypeMonitoring } from '../Utils';

import * as Constants from '../Constants';
import { Card, CardBody } from "../../_metronic/_partials/controls";
import PropTypes from 'prop-types';
import {
    toAbsoluteUrl
} from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import * as PatientService from '../services/PatientService';

import {
    
    TextField, MenuItem, FormControl, InputLabel, OutlinedInput, Select, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, TablePagination, 
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';



function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));



function PatientMedicalHistoryCard({ intl }) {
    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        search(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    const [rows, setRows] = useState([]);

    const search = async (page) => {
        const filter = {
            "findAnywhere": formik.getFieldProps("findAnywhere").value,
            "typeMonitoring": formik.getFieldProps("typeMonitoring").value,
            "page": page,
            "limit": Constants.rowsPerPage,
        }

        const rawResp = await PatientService.findPatientsByFilter(filter);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            if (resp.data.length === 0) {
                setRows([]);
                setTotalElements(0);
            } else {
                setRows(resp.data);
                setTotalElements(resp.totalElements);
            }
        } else {
            setRows([]);
        }

        // console.log(rows.length);
        console.log(rows);
    }

    // Table pagination properties
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        search(newPage);
    };

    const formik = useFormik({
        initialValues: {
            findAnywhere: '',
            typeMonitoring: '',
        },
        onSubmit: values => {
            search(0)
        },
    });

    

    return (
        <div>
            <Card>
                <CardBody>
                    {/* START FILTRO */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="findAnywhere"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("findAnywhere")}
                                />
                            </div>
                            <div className="col-lg-3">
                                <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="outlined-tipoMonitoraggio-simple">
                                        <FormattedMessage id="FILTER.SEARCH_TYPE_MONITORING" />
                                    </InputLabel>
                                    <Select
                                        name="typeMonitoring"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeMonitoring")}
                                        input={<OutlinedInput value={formik.typeMonitoring} labelWidth={labelWidth} name="tipoMonitoraggio" id="outlined-tipoMonitoraggio-simple" />}
                                    >
                                        <MenuItem value={''}><FormattedMessage id="FIELD.TYPE_MONITORING_ALL" /></MenuItem>
                                        <MenuItem value={'C19'}><FormattedMessage id="FIELD.TYPE_MONITORING_C19" /></MenuItem>
                                        <MenuItem value={'IRES'}><FormattedMessage id="FIELD.TYPE_MONITORING_IRES" /></MenuItem>
                                        <MenuItem value={'ICAR'}><FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-3 mt-5">
                                <button
                                    type="submit"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* END FILTRO */}

                    {/* START TABLE */}
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LASTNAME" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FIRTSTNAME" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FISCAL_CODE" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_TYPE_MONITORING" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LAST_MEASURE" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">{row.lastName}</TableCell>
                                        <TableCell align="left">{row.firstName}</TableCell>
                                        <TableCell align="left">{row.fiscalCode}</TableCell>
                                        <TableCell align="left">{formatTypeMonitoring(row.typeMonitoring)}</TableCell>
                                        <TableCell align="left">{row.lastMeasurementDate ? dateFormat(new Date(row.lastMeasurementDate), 'dd/MM/yyyy HH:mm') : '---'}</TableCell>
                                        <TableCell align="left">
                                            <NavLink
                                                title="Edit customer"
                                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                                to={`/patient-medical-history-card-detail/${row.id}`}
                                            >

                                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")}
                                                    />
                                                </span>
                                            </NavLink>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[Constants.rowsPerPage]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={Constants.rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                    {/* END TABLE */}
                </CardBody>
            </Card>

           
        </div>
    );
}

export default injectIntl(PatientMedicalHistoryCard);