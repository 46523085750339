import * as Constants from '../Constants';

export async function login(auth) {
    // auth should be {email, password, totp}
    const api = Constants.BACKEND_URL + Constants.API_LOGIN;
    return await fetch(api, {
        method: Constants.METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(auth)
    });
}
