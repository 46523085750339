import React from 'react';
import { CircularProgress } from "@material-ui/core";
import './LoadingSpinner.scss';

function LoadingSpinner() {
    return (
        <div className="loading-spinner-container">
            <div className="d-flex justify-content-center flex-column">
                <CircularProgress id="loading-spinner" />
            </div>
        </div>
    );
}

export default LoadingSpinner;