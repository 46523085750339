import React from "react";
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";
import {Card, Col, Row} from "react-bootstrap";
import {Button, FormControlLabel, Input, Switch, TextField} from "@material-ui/core";
import {Save, Publish} from '@material-ui/icons';
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import { useSubheader } from "../../../../_metronic/layout";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import SectionHeader from "../../../components/layout/SectionHeader";
import { usePathologies, usePathology } from "../../../modules/Pathology";
import TherapyInput from "../../../components/form-control/TherapyInput";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import useIsNew from "../../../hooks/UseIsNew";
import * as VisitService from "../../../services/VisitService";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";
import { useQueryParams } from "../../../hooks/UseQueryParams";
import { usePatient } from "../../Patient";
import { useVisitLocations } from "../../VisitLocation";

function Visit({intl, ...props}) {
    // state
    const pathologies = usePathologies();
    const visitLocations = useVisitLocations();
    /* A trick with useIsNew */
    const [, patientId] = useIsNew("", "/visit/:id");
    const history = useHistory()
    const alert = useAlert();
    const queryParameters = useQueryParams();
    // will be null if previousPathology is not defined
    const previousPathology = usePathology(queryParameters.get("previousPathology"));
    const patient = usePatient(patientId);

    // formik
    const formSchema = Yup.object().shape({
        pathology: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        findings: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        therapies: Yup.array().of(Yup.object().shape({
            pharmacon: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
            posology: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        })),
        needsFollowUp: Yup.boolean().required(),
        notifyTime: Yup.string().when("needsFollowUp", {
            is: true,
            then: Yup.string().required(intl.formatMessage({id: "ERRORS.NOTIFY_TIME_REQUIRED"})),
            otherwise: Yup.string(),
        }),
        follows: Yup.string(),
        location: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    });

    // set subheader
    const subheader = useSubheader();
    subheader.setTitle(intl.formatMessage({id: "PAGES.NEW_VISIT"}));

    async function handleSubmit(values) {
        let send = {
            ...values,
            pathology: values.pathology.id,
            therapies: values.therapies.map((value) => {return {pharmacon: value.pharmacon.id, posology: value.posology}}),
            notifyTime: values.needsFollowUp ? new Date(values.notifyTime).toISOString() : undefined,
            location: values.location.id,
        }
        let result;
        let success = false;
        result = await VisitService.addVisit(patientId, send);
        if (result.status === Constants.STATUTS_201) {
            history.push("/patient-monitored-list");
            success = true;
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    return (
        <>
        <Card>
            <Card.Body>
                <h3 className="h3">
                    {Boolean(previousPathology) ?
                    <FormattedMessage id="GENERAL.FOLLOWUP"/> :
                    <FormattedMessage id="GENERAL.FIRST_VISIT"/>
                    }
                </h3>
                <p>{patient?.firstName} {patient?.lastName}, {patient?.birthDate}</p>
            </Card.Body>
        </Card>
        <Card>
            <Formik
                initialValues={{
                    follows: queryParameters.get("follows") || "",
                    pathology: previousPathology || null,
                    findings: "",
                    therapies: [],
                    needsFollowUp: false,
                    notifyTime: "",
                    location: null,
                }}
                validationSchema={formSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Card.Body>
                            <SectionHeader messageId="GENERAL.EXAMINATION"/>
                            <Row>
                                <Col md={6}>
                                    <FormikAutocomplete
                                        options={pathologies || []}
                                        fieldName="pathology"
                                        formik={formik}
                                        labelName="name"
                                        groupKey="category"
                                        label={intl.formatMessage({ id: 'FIELD.PATHOLOGY' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={Boolean(previousPathology)}
                                    />
                                    <ErrorMessage name={"pathology"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={6}>
                                    <FormikAutocomplete
                                        options={visitLocations || []}
                                        fieldName="location"
                                        formik={formik}
                                        labelName="name"
                                        label={intl.formatMessage({ id: 'FIELD.VISIT_LOCATION' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"location"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.FINDINGS"})}
                                        {...formik.getFieldProps("findings")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"findings"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.THERAPY"/>
                            <TherapyInput/>
                            <SectionHeader messageId="GENERAL.FOLLOWUP"/>
                            <Row>
                                <Col sm={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.needsFollowUp}
                                                name="needsFollowUp"
                                                onChange={formik.handleChange}
                                                color="primary"
                                                margin="normal"
                                            />
                                        }
                                        label={intl.formatMessage({id: "FIELD.FOLLOWUP"})}
                                    />
                                </Col>
                                <Col md={4}>
                                    <TextField
                                        type={"datetime-local"}
                                        disabled={!formik.values.needsFollowUp}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        {...formik.getFieldProps("notifyTime")}
                                    />
                                    <ErrorMessage name={"notifyTime"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.EXAM_UPLOAD"/>
                            <Row>
                                <Col sm={4}>
                                    <Button
                                        component="label"
                                        color="primary"
                                        variant="contained"
                                        endIcon={<Publish/>}
                                        margin="normal"
                                    >
                                        <Input
                                            id="uploadInput"
                                            type="file"
                                            inputProps={{
                                                accept: ".pdf, .jpg",
                                            }}
                                            style={{display: "none"}}
                                            onChange={formik.handleChange}
                                        />
                                        Upload
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={formik.isSubmitting}
                                endIcon={<Save/>}
                            >
                                <FormattedMessage id="ACTIONS.SAVE" />
                            </Button>
                        </Card.Footer>
                    </form>
                )}
            </Formik>
        </Card>
        </>
    )
}

export default injectIntl(Visit);
