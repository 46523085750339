import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";

import * as Constants from "../../../Constants";
import "./ClinicalDiaryTable.css"
import "../../../style/textPreserveNewline.css"
import {useLang} from "../../../../_metronic/i18n";
import { Link } from "react-router-dom";

function ClinicalDiaryTable({rows, totalElements, page, handleChangePage}) {

    const locale = useLang();

    function getTitleElement(activity) {
        if (activity.type === Constants.ACTIVITY_VISIT) {
            return (
                <Link to={`/view-visit/${activity.foreignId}`}>
                    {new Date(activity.date).toLocaleString(locale)}
                </Link>
            )
        }
        // You can add new activity types before this line
        return new Date(activity.date).toLocaleString(locale)
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell align="left"><FormattedMessage id="TABLE.HEADER_DIARY_ENTRY" /></TableCell>
                        <TableCell align="left"><FormattedMessage id="TABLE.HEADER_THERAPY" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow>
                                    <TableCell className="table-column-date" align="left">
                                        {getTitleElement(row)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="text-preserve-newline table-column-diary" align="left">{row.diaryEntry + "\n\n" + row.author.historicName}</TableCell>
                                    <TableCell className="text-preserve-newline table-column-therapy" align="left">{row.therapy}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[Constants.rowsPerPage]}
                component="div"
                count={totalElements}
                rowsPerPage={Constants.rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
            />
        </>
    );
}

export default injectIntl(ClinicalDiaryTable);
