import * as Constants from "../Constants";

import {getAPIIncludeAuth, postAPIIncludeAuth, putAPIIncludeAuth} from "./Shared";

export async function getAllGrandsPrix() {
    const api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX + "?paginationEnabled=false";

    return getAPIIncludeAuth(api);
}

export async function findGrandPrixById(id) {
    const api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX+`/${id}`;
    return getAPIIncludeAuth(api);
}

export async function addGrandPrix(grandPrix) {
    const api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(grandPrix),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    );
}

export async function updateGrandPrix(id, grandPrix) {
    const api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX+`/${id}`;
    return putAPIIncludeAuth(
        api,
        JSON.stringify(grandPrix),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    );
}

export async function findGrandsPrixByFilter(filter) {
    let api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX + `?pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    api = filter.name ? `${api}&name=${filter.name}` : api;
    //TODO add date filters
    return getAPIIncludeAuth(api);
}

export async function activateGrandPrix(id) {
    let api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX + `/activate/${id}`;
    return postAPIIncludeAuth(api);
}

export async function getActiveGrandPrix() {
    const api = Constants.BACKEND_URL + Constants.API_GRAND_PRIX + "/active";
    return getAPIIncludeAuth(api);
}
