import { Button } from "@material-ui/core";
import React from "react";
import { Card } from "react-bootstrap";
import { injectIntl } from "react-intl";
import DownloadIcon from '@material-ui/icons/GetApp';
import EmailIcon from '@material-ui/icons/Email';
import { useAlert } from "react-alert";

import { useVisit, VisitViewComponent } from "..";
import useIsNew from "../../../hooks/UseIsNew";
import * as VisitService from "../../../services/VisitService";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";

function VisitViewPage({intl, ...props}) {
    const [,visitId] = useIsNew("", "/view-visit/:id");
    const visit = useVisit(visitId);
    const alert = useAlert();

    async function handlePrintVisit() {
        const response = await VisitService.printVisit(visitId);
        if (response.status === Constants.STATUTS_200) {
            const pdfURL = URL.createObjectURL(await response.blob());
            window.open(pdfURL);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
        
    }

    async function handleSendMail() {
        const response = await VisitService.sendMail(visitId);
        if (response.status === Constants.STATUTS_201) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
    }

    return (
        <Card>
            <Card.Body>
                <VisitViewComponent visit={visit}/>
            </Card.Body>
            <Card.Footer>
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<DownloadIcon/>}
                    margin="normal"
                    style={{marginRight: "6px"}}
                    onClick={handlePrintVisit}
                >
                    Download
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    endIcon={<EmailIcon/>}
                    margin="normal"
                    onClick={handleSendMail}
                >
                    Email
                </Button>
            </Card.Footer>
        </Card>
    )
}

export default injectIntl(VisitViewPage);
