import React, { useEffect, useState } from 'react';

import { injectIntl } from "react-intl";

import * as Constants from '../Constants';
import * as Utils from '../Utils';
import * as PatientService from '../services/PatientService';


import PatientHistoricMonitoringMobileChart from '../pages/PatientHistoricMonitoringMobileChart';



import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PatientHistoricMonitoringChart({ intl, ...props }) {
    const classes = useStyles();

    const patientId = props.match.params.id;
    const [selectedPatient, setSelectedPatient] = useState('');

    useEffect(() => {
        Utils.AuthIfNotYet(props.match.params.token)
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialize = async () => {
        const rawData = await PatientService.findPatientById(patientId);

        if (rawData.status === Constants.STATUTS_200) {
            const data = await rawData.json();

            const eta = (new Date().getFullYear() - new Date(data.birthDate).getFullYear());
            setSelectedPatient(`${data.firstName} ${data.lastName}, ${eta} ${intl.formatMessage({ id: 'HISTORIC_MONITORING.YEARS_OLD' })} `);
        } else if (rawData.status === Constants.STATUTS_401) {
            sessionStorage.removeItem('loggedUserToken');
            sessionStorage.removeItem('loggedUserEmail');
            sessionStorage.removeItem('loggedUserRole');
            sessionStorage.removeItem('loggedUserName');
            sessionStorage.removeItem('loggedUserSigla');
            window.location.href = '/';
        }

    }


    return (
        <>
            <Dialog id="tabStoricoPazienti" fullScreen open={true} TransitionComponent={Transition} style={{ background: "black" }}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        {/* <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton> */}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                <Typography variant="h6" className={classes.title + " ml-1"}>
                                    {selectedPatient}
                                </Typography>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <PatientHistoricMonitoringMobileChart patientId={patientId} />
            </Dialog>
        </>

    );
}

export default injectIntl(PatientHistoricMonitoringChart);