import React, { useEffect, useState } from 'react';
import { injectIntl } from "react-intl";

import * as Constants from '../Constants';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {
    AppBar, Tabs, Tab,
    Dialog, Slide, Toolbar
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as PatientService from '../services/PatientService';


//Tabs
import SymptomsTab from '../components/covidCard/SymptomsTab';
import TemperatureTab from '../components/covidCard/TemperatureTab';
import SaturationTab from '../components/covidCard/SaturationTab';
import ExamsTab from '../components/covidCard/ExamsTab';
import TherapyTab from '../components/covidCard/TherapyTab';


function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PatientCovidHistoryCardDetail({ intl, ...props }) {
    const patientId = props.match.params.id;
    const [selectedPatient, setSelectedPatient] = useState('');
    useEffect(() => {

        const initialize = async () => {
            const rawData = await PatientService.findPatientById(patientId);
            if (rawData.status === Constants.STATUTS_200) {
                const data = await rawData.json();
                setSelectedPatient(data);
            }
        }
        initialize();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //START DIALOG
    const openDialog = useState(true);


    //END DIALOG


    

    // const lastExam = await (await CovidExamService.getLastExamByPatientId()).json();
    // console.log(lastExam);
    // setSelectedPatientLastExam(lastExam!={} ? );

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);


    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    function getEta() {
        return new Date().getFullYear() - new Date(selectedPatient.birthDate).getFullYear();
    }

    return (
        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    {/* <IconButton edge="start" color="inherit" aria-label="Close" onClick={handleCloseMedicalCardDialog}>
                         <CloseIcon />
                    </IconButton> */}
                    <Typography variant="h6" className={classes.title}>
                        {/* {selectedPatient}, 30/06/2020 TEST RAPIDO - ESITO NEGATIVO */}
                        Scheda Covid di: {`${selectedPatient.firstName} ${selectedPatient.lastName}, ${getEta()} anni`}
                        {/* <span className="svg-icon svg-icon-md svg-icon-danger"><SVG color="green" src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")}/></span> */}
                    </Typography>
                </Toolbar>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Sintomi" />
                    <Tab label="Temperatura" />
                    <Tab label="Saturazione" />
                    <Tab label="Esami Eseguiti" />
                    <Tab label="Terapie" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <SymptomsTab />
                    </div>
                </TabContainer>

                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <TemperatureTab patientId={patientId} />
                    </div>
                </TabContainer>

                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <SaturationTab patientId={patientId} />
                    </div>
                </TabContainer>

                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <ExamsTab patientId={patientId} />
                    </div>
                </TabContainer>

                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <TherapyTab />
                    </div>
                </TabContainer>
            </SwipeableViews>
        </Dialog>
    );
}
export default injectIntl(PatientCovidHistoryCardDetail);