import * as Constants from '../Constants';

export async function getCovidExamsByPatientId(filter) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_COVID_EXAM + `?patientId=${filter.patientId}&pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function addCovidExam(covidExam) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_COVID_EXAM;
    return await fetch(api, {
        method: Constants.METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
        body: JSON.stringify(covidExam)
    });
}

export async function getLastExamByPatientId(patientId) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + `${Constants.API_COVID_EXAM}/${Constants.API_LAST_COVID_EXAM}/${patientId}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        },
    });
}