import { positions, types } from 'react-alert';
import * as StringUtils from './StringUtils';

export function success(useAlert, message) {
    const msg = StringUtils.isEmpty(message) ? 'Operazione conclusa con successo!' : message;
    useAlert.show(msg, {
        type: types.SUCCESS,
        position: positions.BOTTOM_RIGHT,
        timeout: 5000
    });
}

export function error(useAlert, message) {
    const msg = StringUtils.isEmpty(message) ? "Errore durante l'esecuzione dell'operazione!" : message;
    useAlert.show(msg, {
        type: types.ERROR,
        position: positions.BOTTOM_RIGHT,
        timeout: 5000
    });
}

export function info(useAlert, message) {
    useAlert.show(message, {
        type: types.INFO,
        position: positions.BOTTOM_RIGHT,
        timeout: 5000
    });
}

export function warning(useAlert, message) {
    useAlert.show(message, {
        type: 'warning',
        position: positions.BOTTOM_RIGHT,
        timeout: 5000
    });
}


