import * as Constants from "../Constants";

import {getAPIIncludeAuth, postAPIIncludeAuth, putAPIIncludeAuth} from "./Shared";

export async function getAllTeams() {
    const api = Constants.BACKEND_URL + Constants.API_TEAM + "?paginationEnabled=false";

    return getAPIIncludeAuth(api);
}

export async function findTeamById(id) {
    const api = Constants.BACKEND_URL + Constants.API_TEAM+`/${id}`;
    return getAPIIncludeAuth(api);
}

export async function addTeam(team) {
    const api = Constants.BACKEND_URL + Constants.API_TEAM;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(team),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    );
}

export async function updateTeam(id, team) {
    const api = Constants.BACKEND_URL + Constants.API_TEAM+`/${id}`;
    return putAPIIncludeAuth(
        api,
        JSON.stringify(team),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    );
}

export async function findTeamsByFilter(filter) {
    let api = Constants.BACKEND_URL + Constants.API_TEAM + `?pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    api = filter.name ? `${api}&name=${filter.name}` : api;
    return getAPIIncludeAuth(api);
}

