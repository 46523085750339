import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as C from '../Constants';

import * as LiveService from '../services/LiveService';

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { FollowUpTable } from '../modules/Dashboard';
import usePagination from "../hooks/UsePagination";
import * as PatientService from "../services/PatientService";
import {useTeams} from "../modules/Team";
import {PatientTable} from "../modules/Patient";
import { PathologyPieChart } from '../components/data-display/PathologyPieChart';

// import axios from 'axios';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function DoctorDashboard({ intl }) {

    const classes = useStyles();

    const [refHighFever, setRefHighFever] = useState(38);
    const [refLowOxygenSaturation, setRefLowOxygenSaturation] = useState(94);
    const [refLastMeasureOld, setRefLastMeasureOld] = useState(24);

    const [totEnabledPatients, setTotEnabledPatients] = useState(0);
    const [countPatientsOnAir, setCountPatientsOnAir] = useState(0);
    const [countPatientsLastMeasureOld, setCountPatientsLastMeasureOld] = useState(0);
    const [countPatientsHighFever, setCountPatientsHighFever] = useState(0);
    const [countPatientsLowOxygenSaturation, setCountPatientsLowOxygenSaturation] = useState(0);
    const [donutChart, setDonutChart] = useState([]);
    const [followUpRows, setFollowUpRows] = useState([]);

    const {rows, page, setPage, totalElements, commit} = usePagination(PatientService.getVisitedPatients);
    const teams = useTeams();

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        commit();
    };

    useEffect(() => {
        const onChangeData = (type, payload) => {
            switch (type) {
                case C.DASHBOARD_PATIENT_ENABLED:
                    setTotEnabledPatients(payload);
                    break;
                case C.DASHBOARD_PATIENT_ON_AIR:
                    setCountPatientsOnAir(payload);
                    break;
                case C.DASHBOARD_PATIENT_LAST_MEASURE_OLD:
                    setCountPatientsLastMeasureOld(payload);
                    break;
                case C.DASHBOARD_PATIENT_HIGH_TEMP:
                    setCountPatientsHighFever(payload);
                    break;
                case C.DASHBOARD_PATIENT_LOW_SATURATION:
                    setCountPatientsLowOxygenSaturation(payload);
                    break;
                case C.DASHBOARD_WEEK_PATHOLOGY_COUNT:
                    setDonutChart(payload);
                    break;
                case C.DASHBOARD_PATIENT_FOLLOW_UP:
                    setFollowUpRows(payload);
                    break;
                default:
                    break;
            }
        }

        const onChangeAlertParameters = (payload) => {
            setRefHighFever(payload.alertOnSummary.ST.gte[1]);
            setRefLowOxygenSaturation(payload.alertOnSummary.SpO2.lte[1]);
            setRefLastMeasureOld(payload.disconnectionHours);
        }

        const socket = LiveService.liveDashboardChannel(onChangeData, onChangeAlertParameters);
        return () => { socket.close(); }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="row">

                <div className="col-md-4">
                    <div className="row">
                        {/* SEMPRE BIANCO */}
                        <div className="col-md-6 gutter-b">
                            <div className="card card-custom" style={{ height: "180px" }}>
                                <div className="card-body">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary ml-n2">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}></SVG>
                                    </span>
                                    <div className="text-dark font-weight-bolder font-size-h2 mt-3">{totEnabledPatients}</div>
                                    <NavLink className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1" to="patient-live-monitoring?enabled=true" ><FormattedMessage id="DASHBOARD.TOTAL_PATIENTS" /></NavLink>
                                </div>
                            </div>
                        </div>
                        {/* 0 BIANCO E SE > 1 PRIMARY */}
                        <div className="col-md-6 gutter-b">
                            <div className={`card card-custom ${countPatientsOnAir > 0 ? 'bg-primary' : ''}`} style={{ height: "180px" }}>
                                <div className="card-body">
                                    <span className={`svg-icon svg-icon-3x ml-n2 ${countPatientsOnAir > 0 ? 'svg-icon-white' : 'svg-icon-primary'}`}>
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Diagnostics.svg")}></SVG>
                                    </span>
                                    <div className={`font-weight-bolder font-size-h2 mt-3 ${countPatientsOnAir > 0 ? 'text-inverse-primary' : 'text-dark'}`}>{countPatientsOnAir}</div>
                                    <NavLink className={`font-weight-bold font-size-lg mt-1 ${countPatientsOnAir > 0 ? 'text-inverse-primary' : 'text-muted text-hover-primary'}`} to="patient-live-monitoring?online=true" ><FormattedMessage id="DASHBOARD.PATIENTS_NOW_LIVE" /></NavLink>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col gutter-b">
                            <div className="bg-white card pb-4">
                                <Toolbar>
                                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                                        <div className="mt-6">
                                            <FormattedMessage id="DASHBOARD.GRAPH_TITLE" />
                                        </div>
                                    </Typography>
                                </Toolbar>
                                <PathologyPieChart
                                    data={donutChart}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-12 gutter-b">
                            <div className="bg-white card p-2">
                                <div className="px-4">
                                    <Typography className={classes.title} variant="h6" component="div">
                                        <FormattedMessage id="DASHBOARD.TABLE_VISITED_TITLE" />
                                    </Typography>
                                    <Typography className="text-muted" variant="h6" component="div">
                                        <FormattedMessage id="DASHBOARD.TABLE_VISITED_SUBTITLE" />
                                    </Typography>
                                </div>
                                <PatientTable
                                    rows={rows}
                                    teams={teams}
                                    handleChangePage={handleChangePage}
                                    page={page}
                                    totalElements={totalElements}
                                    intl={intl}
                                />
                            </div>
                        </div>
                        <div className="col-12 gutter-b">
                            <div className="bg-white card p-2">
                                <Toolbar>
                                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                                        <div className="row">
                                            <FormattedMessage id="DASHBOARD.TABLE_TITLE" />
                                        </div>
                                        <div className="row">
                                            <span className="text-muted"><FormattedMessage id="DASHBOARD.TABLE_SUBTITLE" /></span>
                                        </div>
                                    </Typography>
                                </Toolbar>
                                <FollowUpTable rows={followUpRows}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                {/* Escamotage per i cookie. */}
                <img alt="" src={C.BACKEND_URL + '/api/login/cookie?token=' + sessionStorage.getItem('loggedUserToken')}></img>
                <img alt="" src={C.BACKEND_URL + '/api/login/status'}></img>
            </div>
        </>
    );
}

export default injectIntl(DoctorDashboard);
