import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { format as dateFormat } from 'date-fns';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as Utils from '../../Utils';
import * as AlertService from '../../services/AlertService';
import ToggleButton from '@material-ui/lab/ToggleButton';

import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import './style.scss'

function HistoricAlertModalBody({ intl, ...props }) {

    //START URGENCY FILTER
    const [selectedUrgencySuccess, setSelectedUrgencySuccess] = useState(true);
    const [selectedUrgencyWarning, setSelectedUrgencyWarning] = useState(true);
    const [selectedUrgencyDanger, setSelectedUrgencyDanger] = useState(true);
    // let selectedUrgencyLevels = new Set();

    const changeUrgencyLevel = (urgencyLevel) => {
        switch (urgencyLevel) {
            case 1:
                if (selectedUrgencyWarning || selectedUrgencyDanger) {
                    setSelectedUrgencySuccess(!selectedUrgencySuccess);
                }
                break;
            case 2:
                if (selectedUrgencySuccess || selectedUrgencyDanger) {
                    setSelectedUrgencyWarning(!selectedUrgencyWarning);
                }
                break;
            case 3:
                if (selectedUrgencySuccess || selectedUrgencyWarning) {
                    setSelectedUrgencyDanger(!selectedUrgencyDanger);
                }
                break;
            default:
                break;
        }
    }

    //END URGENCY FILTER

    //START PAGINATION
    const [pageNumber, setPageNumber] = useState();
    const [totalPages, setTotalPages] = useState();
    const [allowBrowseBack, setAllowBrowseBack] = useState(false);
    const [allowBrowseForeward, setAllowBrowseForeward] = useState(false);

    const definePagination = (page) => {
        setPageNumber(page.numberPage);
        setTotalPages(page.totalPages);
        setAllowBrowseBack(!page.first);
        setAllowBrowseForeward(!page.last);
    }

    const goPageBack = () => {
        getListAlertsByPatient((pageNumber - 1), 5);
    }

    const goPageForeward = () => {
        getListAlertsByPatient((pageNumber + 1), 5);
    }
    //END PAGINATION

    const [listAlerts, setListAlerts] = useState([]);

    useEffect(() => {
        getListAlertsByPatient(0, 5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUrgencySuccess, selectedUrgencyWarning, selectedUrgencyDanger])

    const defineUrgencyLevels = () => {
        let arr = [];
        if (selectedUrgencySuccess) {
            arr.push(3);
        }
        if (selectedUrgencyWarning) {
            arr.push(2);
        }
        if (selectedUrgencyDanger) {
            arr.push(1);
        }
        return arr.join();
    }

    const getListAlertsByPatient = async (pageNumber, pageSize) => {
        const urgencyLevelList = defineUrgencyLevels();
        const rawResp = await AlertService.getListAlertsByPatient(props.patientId, urgencyLevelList, true, pageNumber, pageSize);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            setListAlerts(resp.data.list);
            definePagination(resp.data.page)
        } else {
            setListAlerts([]);
        }
    }

    return (
        <>
            <div className="urcency-button-container">
                <ToggleButton
                    className="urgceny-success"
                    selected={selectedUrgencySuccess}
                    onChange={() => { changeUrgencyLevel(1) }}>
                    <InfoIcon />
                </ToggleButton>
                <ToggleButton
                    className="urgceny-warning"
                    selected={selectedUrgencyWarning}
                    onChange={() => { changeUrgencyLevel(2) }}>
                    <WarningIcon />
                </ToggleButton>
                <ToggleButton
                    className="urgceny-danger"
                    selected={selectedUrgencyDanger}
                    onChange={() => { changeUrgencyLevel(3) }}>
                    <ErrorIcon />
                </ToggleButton>
            </div>
            {listAlerts.map((elem) => (
                <div key={elem.alertID} className="col-md-12">
                    <div className={`card card-custom gutter-b bg-${Utils.getClassByAlertUrgencyLevel(elem.urgencyLevel.id)}`} >
                        <div className="card-body">
                            <div className={`text-inverse-${Utils.getClassByAlertUrgencyLevel(elem.urgencyLevel.id)}`}>
                                {elem.alert}
                            </div>
                            <div className={`text-inverse-${Utils.getClassByAlertUrgencyLevel(elem.urgencyLevel.id)}`}>
                                {dateFormat(new Date(elem.timestamp), 'dd/MM/yyyy HH:mm')}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="d-flex justify-content-between align-items-baseline">
                <button className="btn btn-sm btn-primary mr-2" disabled={!allowBrowseBack}
                    onClick={goPageBack}
                >
                    <span style={{ fill: "white" }} className="svg-icon svg-icon-md svg-icon-secondary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")} />
                    </span>
                </button>
                <button className="btn btn-sm btn-primary mr-2" disabled={!allowBrowseForeward}
                    onClick={goPageForeward}
                >
                    <span style={{ fill: "white" }} className="svg-icon svg-icon-md svg-icon-secondary">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")} />
                    </span>
                </button>
                <div className="text-primary">
                    {`${pageNumber + 1} di ${totalPages}`}
                </div>
            </div>
        </>
    );
}

export default injectIntl(HistoricAlertModalBody);