import React, { useEffect } from "react";
import { Card } from "react-bootstrap";

import { injectIntl, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { ClinicalDiaryTable } from "..";
import { useSubheader } from "../../../../_metronic/layout";
import usePagination from "../../../hooks/UsePagination";
import * as ClinicalDiaryService from "../../../services/ClinicalDiaryService";
import { usePatient } from "../../Patient";

function ClinicalDiaryPage() {
    const {rows, totalElements, page, setPage, setFilter, commit} = usePagination(ClinicalDiaryService.getPatientDiary);
    const params = useParams();
    const subheader = useSubheader();
    const patient = usePatient(params.id);
    const intl = useIntl()

    useEffect(() => {
        async function firstSearch() {
            setFilter({patientId: params.id});
            commit();
        }
        firstSearch();
    }, [commit, setFilter, params]);

    useEffect(() => {
        if (patient) {
            subheader.setTitle(`${intl.formatMessage({id: "PAGES.CLINICAL_DIARY"})} ${patient.firstName} ${patient.lastName}`);
        }
    }, [patient, subheader, intl]);

    async function handleChangePage(event, newPage) {
        setPage(newPage);
        commit();
    }

    return (
        <Card>
            <Card.Body>
                <ClinicalDiaryTable
                    rows={rows}
                    totalElements={totalElements}
                    page={page}
                    handleChangePage={handleChangePage}
                />
            </Card.Body>
        </Card>
    )
}

export default injectIntl(ClinicalDiaryPage);
