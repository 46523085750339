import * as Constants from '../Constants';

async function anyAPIIncludeAuth(api, method, {body = undefined, headers = {}}) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    return await fetch(api, {
        method: method,
        headers: {
            'Authorization': `authToken ${authToken}`,
            ...headers,
        },
        body: body,
    });
}

/**
 * Calls the given API endpoint with the specified method,
 * adds Authorization information recovered from local storage
 * @param {string} api The endpoint to call
 */
export async function getAPIIncludeAuth(api, headers) {
    return await anyAPIIncludeAuth(api, Constants.METHOD_GET, {headers: headers});
}

export async function postAPIIncludeAuth(api, body, headers) {
    return await anyAPIIncludeAuth(api, Constants.METHOD_POST, {body: body, headers: headers});
}

export async function putAPIIncludeAuth(api, body, headers) {
    return await anyAPIIncludeAuth(api, Constants.METHOD_PUT, {body: body, headers: headers});
}

export async function deleteAPIIncludeAuth(api, headers) {
    return anyAPIIncludeAuth(api, Constants.METHOD_DELETE, {headers: headers});
}
