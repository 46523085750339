import * as Constants from "../Constants";

import {getAPIIncludeAuth} from "./Shared";

/**
 * 
 * @param {object} filter
 * @param filter.patientId
 */
export function getPatientDiary(filter) {
    const api = Constants.BACKEND_URL +
    Constants.API_CLINICAL_DIARY +
    `/${filter.patientId}` +
    `?pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    return getAPIIncludeAuth(api);
}
