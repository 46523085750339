import React from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl, checkIsActive } from "../../../_metronic/_helpers";

/**
 * 
 * @param {object} props
 * @param {string} location The root location this link redirects to
 * @param {string} iconPublicPath The path to the SVG icon in the public folder
 * @param {string} messageId The id of the localized message displayed by this link
 */
function AsideMenuListLink(props) {
    
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url)
            ? " menu-item-active menu-item-open "
            : "";
    };

    return (
        <li
            className={`menu-item ${getMenuItemActive(props.location)}`}
            aria-haspopup="true"
        >
            <NavLink className="menu-link" to={props.location}>
                <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl(props.iconPublicPath)} />
                </span>
                <span className="menu-text"><FormattedMessage id={props.messageId} /></span>
            </NavLink>
        </li>
    )
}

AsideMenuListLink.propTypes = {
    location: PropTypes.string.isRequired,
    iconPublicPath: PropTypes.string.isRequired,
    messageId: PropTypes.string.isRequired,
}

export default AsideMenuListLink;
