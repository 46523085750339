import useResources from "../../../hooks/UseResources";
import * as TeamService from "../../../services/TeamService";

/**
 * fetches and returns all teams the user is allowed to see.
 * Returns null until the result is available.
 * 
 * @returns array or null
 */
export default function useTeams() {
    return useResources(TeamService.getAllTeams, "data");
}
