import { connect } from "socket.io-client";
import * as C from '../Constants'

export const liveMeasurementsChannel = (patientId, onChangeData, onChangeServerTime, onSenseTransmissionOff) => {
    return new LiveMeasurementsClient(patientId, onChangeData, onChangeServerTime, onSenseTransmissionOff);
}

class LiveMeasurementsClient {
    constructor(patientId, onChangeData, onChangeServerTime, onSenseTransmissionOff) {
        this.authToken = sessionStorage.getItem('loggedUserToken');
        this.client = connect(`${C.WS_URL}/liveMeasurements`, {
            reconnection: true,
            reconnectionAttempts: Infinity,
            path: "/ws",
            transports: ['websocket'],
            query: {
                authToken: this.authToken,
                patientId: patientId
            }
        });
        this.setChangeDataHandler(onChangeData);
        this.setChangeServerTimeHandler(onChangeServerTime);
        this.setSenseTransmissionOffHandler(onSenseTransmissionOff);
    }

    setChangeDataHandler(onChangeData) {
        this.client.off(C.MEASUREMENTS_TYPE_HR);
        this.client.off(C.MEASUREMENTS_TYPE_RR);
        this.client.off(C.MEASUREMENTS_TYPE_ECG);
        this.client.off(C.MEASUREMENTS_TYPE_BR);
        this.client.off(C.MEASUREMENTS_TYPE_IMP);
        this.client.off(C.MEASUREMENTS_TYPE_ACT);
        this.client.off(C.MEASUREMENTS_TYPE_SPEED);
        this.client.off(C.MEASUREMENTS_TYPE_ST_F);
        this.client.off(C.MEASUREMENTS_TYPE_ST_T);
        this.client.off(C.MEASUREMENTS_TYPE_SPO2);
        this.client.off(C.MEASUREMENTS_TYPE_HR_QI);
        this.client.off(C.MEASUREMENTS_TYPE_BR_QI);
        this.client.off(C.MEASUREMENTS_TYPE_ACT_QI);
        this.client.off(C.MEASUREMENTS_TYPE_HR_SAT);
        this.client.off(C.MEASUREMENTS_TYPE_ARTERY_CHECK);
        this.client.off(C.MEASUREMENTS_TYPE_HR_GEN);
        this.client.off(C.MEASUREMENTS_TYPE_HR_MAD);
        this.client.off(C.MEASUREMENTS_TYPE_BR_GEN);
        this.client.off(C.MEASUREMENTS_TYPE_RR_GEN);
        this.client.off(C.MEASUREMENTS_TYPE_WEIGHT);
        this.client.off(C.MEASUREMENTS_TYPE_BLO_SYST);
        this.client.off(C.MEASUREMENTS_TYPE_BLO_DIAST);
        this.client.off(C.MEASUREMENTS_TYPE_BLO_ARR);

        this.client.on(C.MEASUREMENTS_TYPE_HR, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_HR, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_RR, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_RR, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_ECG, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_ECG, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_BR, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_BR, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_IMP, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_IMP, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_ACT, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_ACT, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_SPEED, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_SPEED, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_ST_F, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_ST_F, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_ST_T, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_ST_T, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_SPO2, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_SPO2, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_HR_QI, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_HR_QI, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_BR_QI, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_BR_QI, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_ACT_QI, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_ACT_QI, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_HR_SAT, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_HR_SAT, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_ARTERY_CHECK, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_ARTERY_CHECK, data);
        });

        this.client.on(C.MEASUREMENTS_TYPE_HR_GEN, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_HR_GEN, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_HR_MAD, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_HR_MAD, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_BR_GEN, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_BR_GEN, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_RR_GEN, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_RR_GEN, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_WEIGHT, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_WEIGHT, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_BLO_SYST, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_BLO_SYST, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_BLO_DIAST, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_BLO_DIAST, data);
        });
        this.client.on(C.MEASUREMENTS_TYPE_BLO_ARR, (data) => {
            onChangeData(C.MEASUREMENTS_TYPE_BLO_ARR, data);
        });
    }

    setChangeServerTimeHandler(onChangeServerTime) {
        this.client.off('serverTime');

        this.client.on('serverTime', (data) => {
            onChangeServerTime(data);
        });
    }

    setSenseTransmissionOffHandler(onSenseTransmissionOff) {
        this.client.off('senseTransmissionOff');

        this.client.on('senseTransmissionOff', () => {
            onSenseTransmissionOff();
        });
    }

    close() {
        this.client.close();
    }

}

export const liveDashboardChannel = (onChangeData, onChangeAlertParameters) => {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const client = connect(`${C.WS_URL}/dashboard`, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        path: "/ws",
        transports: ['websocket'],
        query: {
            authToken: authToken,
        }
    });

    client.on(C.DASHBOARD_PATIENT_ENABLED, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_ENABLED, data);
    });

    client.on(C.DASHBOARD_PATIENT_ON_AIR, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_ON_AIR, data);
    });

    client.on(C.DASHBOARD_PATIENT_LAST_MEASURE_OLD, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_LAST_MEASURE_OLD, data);
    });

    client.on(C.DASHBOARD_PATIENT_HIGH_TEMP, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_HIGH_TEMP, data);
    });

    client.on(C.DASHBOARD_PATIENT_LOW_SATURATION, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_LOW_SATURATION, data);
    });

    client.on(C.DASHBOARD_PATIENT_MONITORING_TYPES, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_MONITORING_TYPES, data);
    });

    client.on(C.DASHBOARD_PATIENT_FOLLOW_UP, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_FOLLOW_UP, data);
    });

    client.on(C.DASHBOARD_PATIENT_ALERT_PARAMETERS, (alertParameters) => {
        onChangeAlertParameters(alertParameters);
    });

    client.on(C.DASHBOARD_WEEK_PATHOLOGY_COUNT, (data) => {
        onChangeData(C.DASHBOARD_WEEK_PATHOLOGY_COUNT, data);
    });

    return client;
};

// export const liveAlertNotificationChannel = (onGetCountAlerts, onIncreaseAlerts, onDecreaseAlerts) => {
export const liveAlertNotificationChannel = (setCountAlerts, setNewAlert) => {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const client = connect(`${C.WS_URL}/alert`, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        path: "/ws",
        transports: ['websocket'],
        query: {
            authToken: authToken,
        }
    });

    client.on('count', (data) => {
        setCountAlerts(data);
    });
    
    client.on('increase', () => {
        setNewAlert(true);
        setCountAlerts(prevCountAlerts => (prevCountAlerts+1));
    });
    
    client.on('decrease', () => {
        setCountAlerts(prevCountAlerts => (prevCountAlerts-1));
    });


    return client;
};
