import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { injectIntl } from "react-intl";
import { Web } from "sip.js";




function VoipDue() {
    const subHeader = useSubheader();
    subHeader.setTitle("Voip Due");

    useEffect(() => {
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        await alice.connect().then(async () => {
            await alice.register().then(async () => {
                console.log("ALICE_____________REGISTRATO");
            });
        });
        // await bob.connect().then(async () => {
        //     await bob.register().then( async () => {
        //         console.log("BOB_____________REGISTRATO");
        //     });;
        // });
    }

    const server = "wss://voip.patientmonitoring.eositaly.it:7443";

    const aliceOptions = {
        aor: "sip:1011@voip.patientmonitoring.eositaly.it",
        delegate: {
            onRegistered: async () => {
                console.log("Alice registrata");
            },
            onServerConnect: async () => {
                console.log("Alice connessa");
            },
            onCallReceived: async () => {
                console.log('Incoming Call!');
                await alice.answer();
            }
        },
        media: {
            constraints: { audio: true, video: true },
            local: {
                video: (document.getElementById('localVideoAlice'))
            },
            remote: {
                video: (document.getElementById('remoteVideoAlice'))
            }
        },
        userAgentOptions: {
            displayName: "ALICE"
        }
    };

    // const bobOptions = {
    //     aor: "sip:1003@voip.patientmonitoring.eositaly.it",
    //     delegate: {
    //         onRegistered: async () => {
    //             console.log("Bob registrato");
    //         },
    //         onCallReceived: async () => {
    //             console.log('Incoming Call!');
    //             await bob.answer();
    //         },
    //         onServerConnect: async () => {
    //             console.log("Bob connesso");
    //         }
    //     },
    //     media: {
    //         constraints: { audio: false, video: true },
    //         local: {
    //             video: (document.getElementById('localVideoBob'))
    //         },
    //         remote: {
    //             video: (document.getElementById('remoteVideoBob'))
    //         }
    //     },
    //     userAgentOptions: {
    //         displayName: "BOB"
    //     }
    // };

    const alice = new Web.SimpleUser(server, aliceOptions);
    // const bob = new Web.SimpleUser(server, bobOptions);


    const call = () => {
        alice.call("sip:1003@voip.patientmonitoring.eositaly.it", undefined, {
            requestDelegate: {
                onReject: (response) => {
                    console.warn(response);
                }
            }
        }).catch((error) => {
            debugger;
            console.error(error);
        });
    }





    return (
        <>
            <video id="remoteVideoAlice"></video>
            <video id="localVideoAlice" muted="muted"></video>
            <video id="remoteVideoBob"></video>
            <video id="localVideoBob" muted="muted"></video>
            <button className="btn-action btn-success" onClick={call}>Call</button>
        </>
    );
}


export default injectIntl(VoipDue);