import React from "react";
import {useLocation} from "react-router-dom";

/**
 * Taken from https://v5.reactrouter.com/web/example/query-parameters
 * because this version of react-router does not have useSearchParams
 */
export function useQueryParams() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
