import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";

import { useFormik } from "formik";

import clsx from 'clsx';
import SaveIcon from '@material-ui/icons/Save';


import {
    Button, Checkbox, FormGroup, FormLabel, FormControlLabel, Radio, RadioGroup, TextField, FormControl
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function GeneralInfoTab({ intl }) {
    const classes = useStyles();

    // const generalInfoSchema = Yup.object().shape({
    //     info: Yup.string(),
    //     fattoriRischio: Yup.string(),
    //     attivitaFisica: Yup.string(),
    //     attivitaLavorativa: Yup.string(),
    //     pressioneDiastolica: Yup.number(),
    //     pressioneSistolica: Yup.number(),
    //     peso: Yup.number(),
    //     altezza: Yup.number(),
    //     bmi: Yup.number(),
    //     consumoTabacco: Yup.string(),
    //     sigaretteGiorno: Yup.string(),
    //     usoStupefacenti: Yup.string(),
    //     noteStupefacenti: Yup.string(),
    //     consumoAlcolici: Yup.string(),
    //     vinoBirra: Yup.string(),
    //     qtVinoBirra: Yup.string(),
    //     superalcolici: Yup.bool(),
    //     qtSuperalcolici: Yup.string(),
    // });

    const formik = useFormik({
        initialValues: {
            info: '',
            fattoriRischio: '',
            attivitaFisica: '',
            attivitaLavorativa: '',
            pressioneDiastolica: null,
            pressioneSistolica: null,
            peso: null,
            altezza: null,
            bmi: null,
            consumoTabacco: '',
            sigaretteGiorno: '',
            usoStupefacenti: '',
            noteStupefacenti: '',
            consumoAlcolici: '',
            vinoBirra: '',
            qtVinoBitta: '',
            superalcolici: false,
            qtSuperalcolici: '',
        },
        // validationSchema: generalInfoSchema,
        onSubmit: values => {
            console.log(values);
        },
    });

    return (
        <>
            <div className="mt-120">
                <form onSubmit={formik.handleSubmit}>

                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Informazioni generali</h6>
                        <hr style={{ margin: "0" }} />
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Informazioni*</FormLabel>
                                <RadioGroup
                                    name="info"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("info")}
                                >
                                    <FormControlLabel value="RIF" control={<Radio />} label="Riferite" />
                                    <FormControlLabel value="RIL" control={<Radio />} label="Rilevate" />
                                    <FormControlLabel value="NRIL" control={<Radio />} label="Non rilevate" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Esposizione fattori a rischio*</FormLabel>
                                <RadioGroup
                                    name="fattoriRischio"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("fattoriRischio")}
                                >
                                    <FormControlLabel value="S" control={<Radio />} label="Sì" />
                                    <FormControlLabel value="N" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Attività fisica*</FormLabel>
                                <RadioGroup
                                    name="attivitaFisica"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("attivitaFisica")}
                                >
                                    <FormControlLabel value="SCA" control={<Radio />} label="Scarsa" />
                                    <FormControlLabel value="SAL" control={<Radio />} label="Saltuaria" />
                                    <FormControlLabel value="INT" control={<Radio />} label="Intensa" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Attività lavorativa</FormLabel>
                                <RadioGroup
                                    name="attivitaLavorativa"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("attivitaLavorativa")}
                                >
                                    <FormControlLabel value="S" control={<Radio />} label="Sì" />
                                    <FormControlLabel value="N" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Misurazioni</h6>
                        <hr style={{ margin: "0" }} />
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <TextField
                                id="outlined-normal"
                                type="number"
                                label="Pressione diastolica (mmHg)*"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="pressioneDiastolica"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("pressioneDiastolica")}
                            />
                        </div>
                        <div className="col-md-3">
                            <TextField
                                id="outlined-normal"
                                type="number"
                                label="Pressione sistolica (mmHg)*"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="pressioneSistolica"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("pressioneSistolica")}
                            />
                        </div>
                        <div className="col-md-2">
                            <TextField
                                id="outlined-normal"
                                type="number"
                                label="Peso (kg)*"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="peso"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("peso")}
                            />
                        </div>
                        <div className="col-md-2">
                            <TextField
                                id="outlined-normal"
                                type="number"
                                label="Altezza (cm)*"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="altezza"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("altezza")}
                            />
                        </div>
                        <div className="col-md-2">
                            <TextField
                                id="outlined-normal"
                                label="BMI*"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="bmi"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("bmi")}
                            />
                        </div>
                    </div>

                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Consumo di sostanze psicoattive</h6>
                        <hr style={{ margin: "0" }} />
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Consumo di tabacco*</FormLabel>
                                <RadioGroup
                                    name="consumoTabacco"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("consumoTabacco")}
                                >
                                    <FormControlLabel value="S" control={<Radio />} label="Sì" />
                                    <FormControlLabel value="N" control={<Radio />} label="No" />
                                    <FormControlLabel value="EX" control={<Radio />} label="Ex fumatore" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Sigarette al giorno</FormLabel>
                                <RadioGroup
                                    name="sigaretteGiorno"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("sigaretteGiorno")}
                                >
                                    <FormControlLabel value="P" control={<Radio />} label="&lt; 5" />
                                    <FormControlLabel value="M" control={<Radio />} label="tra 5 e 20" />
                                    <FormControlLabel value="A" control={<Radio />} label="&gt; 20" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className="col-md-6 mb-2">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Uso di sostanze stupefacenti</FormLabel>
                                <RadioGroup
                                    name="usoStupefacenti"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("usoStupefacenti")}
                                >
                                    <FormControlLabel value="S" control={<Radio />} label="Sì" />
                                    <FormControlLabel value="N" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>

                            <TextField
                                id="outlined-multiline-static"
                                label="Se sì quali"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="noteStupefacenti"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("noteStupefacenti")}
                            />

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Consumo giornaliero di alcolici*</FormLabel>
                                <RadioGroup
                                    name="consumoAlcolici"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("consumoAlcolici")}
                                >
                                    <FormControlLabel value="S" control={<Radio />} label="Sì" />
                                    <FormControlLabel value="N" control={<Radio />} label="No" />
                                    <FormControlLabel value="SP" control={<Radio />} label="Sporadico" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Vino / Birra</FormLabel>
                                <FormGroup
                                    name="vinoBirra"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("vinoBirra")}
                                >
                                    <FormControlLabel value="S" control={<Checkbox />} label="Vino/Birra" />
                                </FormGroup >
                            </FormControl>
                            <TextField
                                id="outlined-normal"
                                label="Quantità"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="qtVinoBirra"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("qtVinoBirra")}
                            />


                        </div>
                        <div className="col-md-3">
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Superalcolici</FormLabel>
                                <FormGroup
                                    name="superalcolici"
                                    row
                                    className={classes.group}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("superalcolici")}
                                >
                                    <FormControlLabel value={true} control={<Checkbox />} label="Superalcolici" />
                                </FormGroup>
                            </FormControl>

                            <TextField
                                id="outlined-normal"
                                label="Quantità"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="qtSuperalcolici"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("qtSuperalcolici")}
                            />


                        </div>
                    </div>
                    <div className="row">
                        <Button type="submit" disabled={false} variant="contained" color="primary" size="small" className={classes.button}>
                            <FormattedMessage id="ACTIONS.SAVE" />
                            <SaveIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default injectIntl(GeneralInfoTab);