import { Button, FormControlLabel, Switch, TextareaAutosize } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import * as Yup from "yup";
import { useAlert } from "react-alert";

import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import { useTeams } from "../../Team";
import { useGrandsPrix } from "../../GrandPrix";
import * as ReportService from "../../../services/ReportService";
import * as Constants from "../../../Constants";
import * as Utils from "../../../Utils";
import * as AlertService from "../../../AlertService";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";

function ReportPage(props) {
    const teams = useTeams();
    const grandsPrix = useGrandsPrix();
    const intl = useIntl();
    const alert = useAlert();

    const formSchema = Yup.object().shape({
        team: Yup.object().nullable().required(intl.formatMessage({id: "ERRORS.REQUIRED_FIELD"})),
        gp: Yup.object().nullable(),
        showTimes: Yup.boolean().required(),
        comments: Yup.string().required(intl.formatMessage({id: "ERRORS.REQUIRED_FIELD"})),
    })

    async function handleSubmit(values) {
        const response = await ReportService.printReport(
            {
                teamId: values.team.id,
                gpId: values.gp?.id,
                showTimes: values.showTimes,
                comments: values.comments,
            }
        );
        if (response.status === Constants.STATUTS_201) {
            Utils.openPDFFromResponse(response);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
    }

    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={{
                        team: null,
                        gp: null,
                        showTimes: true,
                        comments: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={formSchema}
                >
                    {(formik) => (
                        <Row className="d-print-none">
                            <Col md={4}>
                                <FormikAutocomplete
                                    options={teams || []}
                                    formik={formik}
                                    fieldName="team"
                                    labelName="name"
                                    label={intl.formatMessage({id: "FIELD.TEAM"})}
                                />
                                <FormikAutocomplete
                                    options={grandsPrix || []}
                                    formik={formik}
                                    fieldName="gp"
                                    labelName="name"
                                    label={intl.formatMessage({id: "FIELD.GRAND_PRIX"})}
                                />
                            </Col>
                            <Col md={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="showTimes"
                                            color="primary"
                                            margin="normal"
                                            checked={formik.values.showTimes}
                                            onChange={formik.handleChange}
                                        />
                                    }
                                    label="Show times"
                                />
                            </Col>
                            <Col md={12}>
                                <p className="h2 mt-2">Comments</p>
                                <TextareaAutosize
                                    name="comments"
                                    style={{width: "100%"}}
                                    rowsMin={3}
                                    {...formik.getFieldProps("comments")}
                                />
                                <ErrorMessage name={"comments"} component={FormikErrorComponent}/>
                            </Col>
                            <Col md={2}>
                                <Button
                                    variant="contained"
                                    onClick={formik.handleSubmit}
                                    disabled={!formik.isValid || !formik.dirty}
                                >
                                    <FormattedMessage id="ACTIONS.CONFIRM"/>
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    )
}

export default injectIntl(ReportPage);
