import React from "react";

export function FormikErrorComponent({children}) {
    return (
        <div className="fv-plugins-message-container">
            <div className="fv-help-block">
                {children}
            </div>
        </div>
    );
}
