import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import { useFormik } from "formik";

import { format as dateFormat } from 'date-fns';

import { formatTypeMonitoring } from '../Utils';

import { Card, CardBody } from "../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import HistoricAlertModal from '../components/alert/HistoricAlertModal';

import * as C from "../Constants";

import * as PatientService from '../services/PatientService';
// import * as Service from '../services/AlertService';

import {
    TextField, MenuItem, FormControl, InputLabel,
    OutlinedInput, Select, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination
    // ,AppBar, Typography, Slide
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

function PatientHistoricMonitoring({ intl }) {
    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        search(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const [openDialog, setOpenDialog] = useState(false);

    const [rows, setRows] = useState([]);

    // const [selectedPatient, setSelectedPatient] = useState('');

    // const [valuesRef, setValuesRef] = useState({});


    const search = async (page) => {
        const filter = {
            "findAnywhere": formik.getFieldProps("findAnywhere").value,
            "typeMonitoring": formik.getFieldProps("typeMonitoring").value,
            "page": page,
            "limit": C.rowsPerPage,
        }

        const rawResp = await PatientService.findPatientsByFilter(filter);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            if (resp.data.length === 0) {
                setRows([]);
                setTotalElements(0);
            } else {
                setRows(resp.data);
                setTotalElements(resp.totalElements);
            }
        } else {
            setRows([]);
        }

        //Eseguo quì la chiamata relativa ai parametri per gli alert in modo da non appesantire il componente del grafico
        // let refs = {};
        // const rawAlerts = await Service.getCurrentAlertsSettings();
        // const alerts = await rawAlerts.json();
        // const params = alerts.data.parameters;
        // Object.keys(params).forEach(function (parameter) {
        //     switch (parameter) {
        //         case C.MEASUREMENTS_TYPE_HR:
        //             refs.hrYellow = params[parameter][2];
        //             refs.hrRed = params[parameter][1];
        //             break;
        //         case C.MEASUREMENTS_TYPE_BR:
        //             refs.brYellow = params[parameter][2];
        //             refs.brRed = params[parameter][1];
        //             break;
        //         case C.MEASUREMENTS_TYPE_GENERIC_ST:
        //             refs.sttYellow = params[parameter][2];
        //             refs.sttRed = params[parameter][1];
        //             break;
        //         case C.MEASUREMENTS_TYPE_SPO2:
        //             refs.spo2Yellow = params[parameter][2];
        //             refs.spo2Red = params[parameter][1];
        //             break;
        //         default:
        //             break;

        //     }
        // })
        // setValuesRef(refs);
    }

    // const openHistoricMonitoringDialog = (row) => {
    //     setPatientId(row.id);
    //     const eta = (new Date().getFullYear() - new Date(row.birthDate).getFullYear());
    //     setSelectedPatient(`${row.firstName} ${row.lastName}, ${eta} ${intl.formatMessage({ id: 'HISTORIC_MONITORING.YEARS_OLD' })} `);
    //     setOpenDialog(true);
    // }

    // const handleClose = () => {
    //     setOpenDialog(false);
    // }

    //Pagination
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        search(newPage);
    };

    const formik = useFormik({
        initialValues: {
            findAnywhere: '',
            typeMonitoring: '',
        },
        onSubmit: values => {
            search(0)
        },
    });


    //START HISTORIC MONITORING
    // const [patientId, setPatientId] = useState('');

    //END HISTORIC MONITORING


    const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
    const [selectedAlertPatient, setSelectedAlertPatient] = useState({});

    const openAlertDialog = (patient) => {
        setSelectedAlertPatient(patient);
        setIsOpenAlertModal(true);
    }

    const closeAlertDialog = () => {
        setIsOpenAlertModal(false);
    }

    return (
        <>
            <Card>
                <CardBody>
                    {/* START FILTRO */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="findAnywhere"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("findAnywhere")}
                                />
                            </div>
                            <div className="col-lg-3">
                                <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="outlined-tipoMonitoraggio-simple">
                                        <FormattedMessage id="FILTER.SEARCH_TYPE_MONITORING" />
                                    </InputLabel>
                                    <Select
                                        name="typeMonitoring"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeMonitoring")}
                                        input={<OutlinedInput value={formik.typeMonitoring} labelWidth={labelWidth} name="tipoMonitoraggio" id="outlined-tipoMonitoraggio-simple" />}
                                    >
                                        <MenuItem value={''}><FormattedMessage id="FIELD.TYPE_MONITORING_ALL" /></MenuItem>
                                        <MenuItem value={'C19'}><FormattedMessage id="FIELD.TYPE_MONITORING_C19" /></MenuItem>
                                        <MenuItem value={'IRES'}><FormattedMessage id="FIELD.TYPE_MONITORING_IRES" /></MenuItem>
                                        <MenuItem value={'ICAR'}><FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-3  mt-5">
                                <button
                                    type="submit"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* END FILTRO */}

                    {/* START FILTRO */}
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LASTNAME" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FIRTSTNAME" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FISCAL_CODE" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_TYPE_MONITORING" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LAST_MEASURE" /></TableCell>
                                    <TableCell align="center"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">{row.lastName}</TableCell>
                                        <TableCell align="left">{row.firstName}</TableCell>
                                        <TableCell align="left">{row.fiscalCode}</TableCell>
                                        <TableCell align="left">{formatTypeMonitoring(row.typeMonitoring)}</TableCell>
                                        <TableCell align="left">{row.lastMeasurementDate ? dateFormat(new Date(row.lastMeasurementDate), 'dd/MM/yyyy HH:mm') : '---'}</TableCell>
                                        <TableCell align="left">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <NavLink
                                                    title="Monitoraggio storico"
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    to={`/patient-historic-measure-detail/${row.id}`}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
                                                        />
                                                    </span>
                                                </NavLink>
                                                <button
                                                    title="Notifications paziente"
                                                    className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3"
                                                    onClick={() => {openAlertDialog(row);}}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-warning">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")}
                                                        />
                                                    </span>
                                                </button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[C.rowsPerPage]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={C.rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                    {/* END FILTRO */}
                </CardBody>
            </Card>

            {/* Modale contenente gli alert del paziente selezionato  */}
            <HistoricAlertModal key={`alertyModal-${isOpenAlertModal}`} isModalOpen={isOpenAlertModal} handleClose={closeAlertDialog} selectedPatient={selectedAlertPatient} />

        </>
    );
}

export default injectIntl(PatientHistoricMonitoring);