import React, { useState } from 'react';
// import { FormattedMessage, injectIntl } from "react-intl";
import {
    // Button,
    Dialog, DialogContent, DialogContentText, DialogTitle, Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import * as Utils from '../../Utils';


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function CallingModal({ intl, ...props }) {
    const [open, setOpen] = useState(props.isModalOpen);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                maxWidth={false}
                fullWidth={true}
                PaperProps={{
                    style: {
                        width: '320px'
                    },
                }}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" />
                <DialogContent>
                    <DialogContentText>
                        <div className="voipContainer">
                            <div className="voipMedia">
                                <div className="d-flex align-items-end flex-column p-3">
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="layout-tooltip">
                                        {`${props.patientDetails.firstName} ${props.patientDetails.lastName}`}
                                    </Tooltip>}>
                                        <span className="symbol symbol-38 symbol-light-success mb-2">
                                            <span className="font-size-h3 font-weight-bold symbol-label">
                                                {`${props.patientDetails.firstName[0]} ${props.patientDetails.lastName[0]}`}
                                            </span>
                                            <div className="symbol-badge badge-success">
                                                &nbsp;
                                            </div>
                                        </span>
                                    </OverlayTrigger>
                                </div>


                                {/* IL VIDEO VA QUI <video> */}


                            </div>

                            <div className="voipDetail">
                                <div className="d-flex justify-content-center mb-3 voipActions">
                                    <button className="btn btn-sm btn-primary mr-2" onClick={() => { props.minimize(); }}>
                                        <span className="svg-icon svg-icon-md">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/minimize.svg")} />
                                        </span>
                                    </button>
                                    {props.isCallMuted ?
                                        <button className="btn btn-sm btn-primary mr-2" onClick={() => { props.unmute(); }}>
                                            <span className="svg-icon svg-icon-md">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/mic-mute.svg")} />
                                            </span>
                                        </button>
                                        :
                                        <button className="btn btn-sm btn-primary mr-2" onClick={() => { props.mute(); }}>
                                            <span className="svg-icon svg-icon-md">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/mic-on.svg")} />
                                            </span>
                                        </button>
                                    }
                                    <button className="btn btn-sm btn-danger mr-2" onClick={() => { props.endCall(); }}>
                                        <span className="svg-icon svg-icon-md svg">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Voip/telephone-out.svg")} />
                                        </span>
                                    </button>
                                </div>

                                <div className="mb-3">
                                    <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{`${props.patientDetails.firstName} ${props.patientDetails.lastName}`}</span>
                                    <div className="text-dark">Monitorato per: <span className="text-primary">{Utils.formatTypeMonitoring(props.patientDetails.typeMonitoring)}
                                    </span>
                                    </div>
                                </div>



                                <div>
                                    <div className="d-flex align-items-center font-weight-bolder mb-2 text-dark-75">
                                        <span className="font-weight-bold mr-2">Dettagli:</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="font-weight-bold mr-2">Email:</span>
                                        <a href="mailto:admin@demo.com" className="text-muted text-hover-primary">{props.patientDetails.email}</a>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="font-weight-bold mr-2">Genere:</span>
                                        <span className="text-muted">{props.patientDetails.gender}</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <span className="font-weight-bold mr-2">Età:</span>
                                        <span className="text-muted">{(new Date().getFullYear() - new Date(props.patientDetails.birthDate).getFullYear())} anni</span>
                                    </div>
                                    {/* <div className="d-flex align-items-center mt-5">
                                        <span className="font-weight-bold mr-2">Current location:</span>
                                        <span className="text-muted">Via Grande, 41, Milano</span>
                                    </div> */}
                                </div>


                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}
