import React, { useEffect, useState } from 'react';

import { injectIntl } from "react-intl";


import * as Constants from '../Constants';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';


import * as PatientService from '../services/PatientService';

import {
    AppBar, Checkbox, FormGroup, FormLabel, FormControlLabel, Radio, RadioGroup, Tabs, Tab,
    TextField, FormControl, Dialog, Slide, Toolbar
} from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import GeneralInfoTab from '../components/medicalCard/GeneralInfoTab';
import FamiliarityTab from '../components/medicalCard/FamiliarityTab'
import DrugsTab from '../components/medicalCard/DrugsTab'
import SurgeryTab from '../components/medicalCard/SurgeryTab'

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




function PatientMedicalHistoryCardDetail({ intl, ...props }) {

    const patientId = props.match.params.id;
    const [selectedPatient, setSelectedPatient] = useState('');
    useEffect(() => {

        const initialize = async () => {
            const rawData = await PatientService.findPatientById(patientId);
            if (rawData.status === Constants.STATUTS_200) {
                const data = await rawData.json();
                setSelectedPatient(data);
            }
        }
        initialize();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    //START DIALOG
    const openDialog = useState(true);
    //END DIALOG

    function getEta() {
        return new Date().getFullYear() - new Date(selectedPatient.birthDate).getFullYear();
    }


    return (

        <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    {/*<IconButton edge="start" color="inherit" aria-label="Close" onClick={handleCloseMedicalCardDialog}>
                        <CloseIcon />
                        </IconButton>*/}
                    <Typography variant="h6" className={classes.title}>
                        Scheda Anamnestica di: {`${selectedPatient.firstName} ${selectedPatient.lastName}, ${getEta()} anni`}
                    </Typography>
                </Toolbar>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Informazioni generali" />
                    <Tab label="Allergie" />
                    <Tab label="Patologie" />
                    <Tab label="Familiarità" />
                    <Tab label="Farmaci" />
                    <Tab label="Interventi" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabContainer dir={theme.direction}>
                    <GeneralInfoTab />
                </TabContainer>

                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <div className="my-4">
                            <h6 className="text-primary" style={{ margin: "0" }}>Tipo allergia</h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <FormControl component="fieldset">
                                    {/* <FormLabel component="legend">Sintomi</FormLabel> */}
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="rinite"
                                            control={<Checkbox color="primary" />}
                                            label="Rinite"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Congiuntivite"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="asma"
                                            control={<Checkbox color="primary" />}
                                            label="Asma"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Orticaria"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="dermatiteAtopica"
                                            control={<Checkbox color="primary" />}
                                            label="Dermatite atopica"
                                            labelPlacement="end"
                                        />

                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>








                        <div className="row">
                            <div className="col-12">
                                <div className="d-inline-flex">
                                    <FormControl component="fieldset">
                                        {/* <FormLabel component="legend">Allergia alimentare</FormLabel> */}
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="allergiaAlimentare"
                                                control={<Checkbox color="primary" />}
                                                label="Allergia alimentare"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <div className="ml-2">
                                        <TextField
                                            id="outlined-normal"
                                            label="Note"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            name="searchText"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>













                        <div className="my-4">
                            <h6 className="text-primary" style={{ margin: "0" }}>Sintomi</h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="row">
                            <div className="col">
                                <FormControl component="fieldset">
                                    {/* <FormLabel component="legend">Sintomi</FormLabel> */}
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="rinite"
                                            control={<Checkbox color="primary" />}
                                            label="Anafilassi"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Tosse"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="asma"
                                            control={<Checkbox color="primary" />}
                                            label="Diarrea"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Alvo irregolare"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Intolleranza alimentare"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="asma"
                                            control={<Checkbox color="primary" />}
                                            label="IBS"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Pancia Gonfia"
                                            labelPlacement="end"
                                        />

                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>



                        <div className="my-4">
                            <h6 className="text-primary" style={{ margin: "0" }}>Allergie Note</h6>
                            <hr style={{ margin: "0" }} />
                        </div>



                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    {/* <FormLabel component="legend">Sintomi</FormLabel> */}
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="rinite"
                                            control={<Checkbox color="primary" />}
                                            label="Allergia al latex"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Angiodema"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="asma"
                                            control={<Checkbox color="primary" />}
                                            label="Allergia imenotteri"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Dermatite da contatto"
                                            labelPlacement="end"
                                        />

                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>




                        <div className="row">
                            <div className="col-12">
                                <div className="d-inline-flex">
                                    <FormControl component="fieldset">
                                        {/* <FormLabel component="legend">Allergia alimentare</FormLabel> */}
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="allergiaAlimentare"
                                                control={<Checkbox color="primary" />}
                                                label="Altro"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <div className="ml-2">
                                        <TextField
                                            id="outlined-normal"
                                            label="Altro"
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            name="searchText"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Sintomi allergici da:</FormLabel>
                                    <RadioGroup
                                        name="consumoDiTabacco"
                                        className={classes.group}
                                        value={value}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel value="si" control={<Radio />} label="Meno di 1 anno" />
                                        <FormControlLabel value="no" control={<Radio />} label="Da 1 a 5 anni" />
                                        <FormControlLabel value="exfumatore" control={<Radio />} label="Più di 5 anni" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                        <label className="form-check-label" for="inlineCheckbox1">Allergia a farmaci</label>
                                    </div>
                                    <div className="ml-2">
                                        <div className="form-group ">
                                            <select id="inputState" className="form-control">
                                                <option selected>Paracetamolo</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="ml-2">
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie Cardiovascolari</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="rinite"
                                            control={<Checkbox color="primary" />}
                                            label="Ipertensione arteriosa"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Aritmie"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="asma"
                                            control={<Checkbox color="primary" />}
                                            label="Cardipatia ischemica"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Cardipatia valvolare"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Insufficienza valvolare"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Vasculopatia"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie Neuropsichiatriche</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="rinite"
                                            control={<Checkbox color="primary" />}
                                            label="Nevrosi"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Psicosi"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="asma"
                                            control={<Checkbox color="primary" />}
                                            label="Neuropatia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Cefalea"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie Uronefrologiche</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Glomerulonefrite"
                                            control={<Checkbox color="primary" />}
                                            label="Glomerulonefrite"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Infezioni ripetitive vie urinarie"
                                            control={<Checkbox color="primary" />}
                                            label="Infezioni ripetitive vie urinarie"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Insufficienza renale"
                                            control={<Checkbox color="primary" />}
                                            label="Insufficienza renale"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Litiasi"
                                            control={<Checkbox color="primary" />}
                                            label="Litiasi"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie respiratorie</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="BPCO"
                                            control={<Checkbox color="primary" />}
                                            label="BPCO"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Asma"
                                            control={<Checkbox color="primary" />}
                                            label="Asma"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Interstiziopatie polmonari"
                                            control={<Checkbox color="primary" />}
                                            label="Interstiziopatie polmonari"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>





                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie endocrino-metaboliche</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Diabete"
                                            control={<Checkbox color="primary" />}
                                            label="Diabete"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Dislipidemia"
                                            control={<Checkbox color="primary" />}
                                            label="Dislipidemia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Obesità"
                                            control={<Checkbox color="primary" />}
                                            label="Obesità"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Iperuricemia"
                                            control={<Checkbox color="primary" />}
                                            label="Iperuricemia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Tireopatia"
                                            control={<Checkbox color="primary" />}
                                            label="Tireopatia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie emapotoietiche</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Anemia"
                                            control={<Checkbox color="primary" />}
                                            label="Anemia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Piatropienia"
                                            control={<Checkbox color="primary" />}
                                            label="Piatropienia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Linfopenia"
                                            control={<Checkbox color="primary" />}
                                            label="Linfopenia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie enteropatiche</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Ulcera gastrica"
                                            control={<Checkbox color="primary" />}
                                            label="Ulcera gastrica"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Cirroso biliare"
                                            control={<Checkbox color="primary" />}
                                            label="Cirroso biliare"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Nash"
                                            control={<Checkbox color="primary" />}
                                            label="Nash"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Epatite (autoimmune - virali - ecc.)"
                                            control={<Checkbox color="primary" />}
                                            label="Epatite (autoimmune - virali - ecc.)"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Calcolosi"
                                            control={<Checkbox color="primary" />}
                                            label="Calcolosi"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Malattia di Chron"
                                            control={<Checkbox color="primary" />}
                                            label="Malattia di Chron"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Rettocolite ulcerosa"
                                            control={<Checkbox color="primary" />}
                                            label="Rettocolite ulcerosa"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>







                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie allergiche</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Rinite"
                                            control={<Checkbox color="primary" />}
                                            label="Rinite"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Congiuntivite"
                                            control={<Checkbox color="primary" />}
                                            label="Congiuntivite"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Dermatite atopica"
                                            control={<Checkbox color="primary" />}
                                            label="Dermatite atopica"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Asma"
                                            control={<Checkbox color="primary" />}
                                            label="Asma"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Orticaria"
                                            control={<Checkbox color="primary" />}
                                            label="Orticaria"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Allergie alimentari"
                                            control={<Checkbox color="primary" />}
                                            label="Allergie alimentari"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Allergie ai farmaci"
                                            control={<Checkbox color="primary" />}
                                            label="Allergie ai farmaci"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Malattie oculari</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Uveite"
                                            control={<Checkbox color="primary" />}
                                            label="Uveite"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Retinopatia"
                                            control={<Checkbox color="primary" />}
                                            label="Retinopatia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Maculopatia"
                                            control={<Checkbox color="primary" />}
                                            label="Maculopatia"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro"
                                            control={<Checkbox color="primary" />}
                                            label="Altro"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Neoplasia</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="Cutaneo"
                                            control={<Checkbox color="primary" />}
                                            label="Cutaneo"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Ematologico"
                                            control={<Checkbox color="primary" />}
                                            label="Ematologico"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Mammella"
                                            control={<Checkbox color="primary" />}
                                            label="Mammella"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Polmonare"
                                            control={<Checkbox color="primary" />}
                                            label="Polmonare"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Epatico"
                                            control={<Checkbox color="primary" />}
                                            label="Epatico"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Prostatica"
                                            control={<Checkbox color="primary" />}
                                            label="Prostatica"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Altro sede"
                                            control={<Checkbox color="primary" />}
                                            label="Altro sede"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <FamiliarityTab />
                    </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <DrugsTab />
                    </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <div className="mt-120">
                        <SurgeryTab />
                    </div>
                </TabContainer>
            </SwipeableViews>
        </Dialog>
    );
}
export default injectIntl(PatientMedicalHistoryCardDetail);