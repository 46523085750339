import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

/**
 *
 * 
 * @param {object} props
 * @param {string} messageId The id of the localized message to display in this header
 */
function AsideMenuListSectionHeader(props) {
    return (
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id={props.messageId} /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
    )
}

AsideMenuListSectionHeader.propTypes = {
    messageId: PropTypes.string.isRequired,
}

export default AsideMenuListSectionHeader;
