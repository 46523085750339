/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch } from "react-router-dom";
import { Layout, ContentRoute } from "../_metronic/layout";
import BasePage from "./BasePage";
import PatientHistoricMonitoringChart from './pages/PatientHistoricMonitoringChart';
import { AuthPage } from "./modules/Auth";

export function Routes() {
    // const { isAuthorized } = useSelector(
    //     ({ auth }) => ({
    //         isAuthorized: auth.user != null,
    //     }),
    //     shallowEqual
    // );

    //const isUserAuthenticated = true;
    const isUserAuthenticated = sessionStorage.getItem('loggedUserEmail') !== null && sessionStorage.getItem('loggedUserEmail') !== undefined;

    if (!isUserAuthenticated) {
        return (
            <Switch>
                <ContentRoute path="/patient-historic-monitoring-chart/:id/:token" component={PatientHistoricMonitoringChart} />
                <ContentRoute path="/" component={AuthPage} />
            </Switch>
        );
    } else {
        return (
            <Layout>
                <BasePage />
            </Layout>
        );
    }

    // return (
    // <Switch>
    //     {!isUserAuthenticated ? (
    //         /*Render auth page when user at `/auth` and not authorized.*/
    //         <Route>
    //             <AuthPage />
    //         </Route>
    //     ) : (
    //             /*Otherwise redirect to root page (`/`)*/
    //             <Redirect from="/auth" to="/" />
    //         )}

    //     <Route path="/error" component={ErrorsPage} />
    //     <Route path="/logout" component={Logout} />


    //     {!isUserAuthenticated ? (
    //         /*Redirect to `/auth` when user is not authorized*/
    //         <Redirect to="/auth/login" />
    //     ) : (
    //             <Layout>
    //                 <BasePage />
    //             </Layout>
    //         )}
    // </Switch>
    // );
}
