import * as Constants from '../Constants';

export async function getCurrentAlertsSettings() {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_ALERT_SETTINGS;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function updateAlertsSettings(alerts) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_ALERT_SETTINGS;
    return await fetch(api, {
        method: Constants.METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
        body: JSON.stringify(alerts)
    });
}

export async function getListAlerts(unread, paginationEnabled, pageNumber, pageSize) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_ALERT_LIST+`?new=${unread}&paginationEnabled=${paginationEnabled}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function getListAlertsByPatient(patientId, urgencyLevelList, paginationEnabled, pageNumber, pageSize) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_ALERT_LIST+`?patientId=${patientId}&urgencyLevelList=${urgencyLevelList}&paginationEnabled=${paginationEnabled}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return await fetch(api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function checkAlertAsRead(alertId) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_ALERT+`/${alertId}`;
    return await fetch (api, {
        method: Constants.METHOD_PUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
          },
    });
}