import { Card, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react"
import { FormattedMessage, injectIntl } from "react-intl";
import {useHistory} from "react-router-dom";
import { useFormik } from "formik";
import { Save } from "@material-ui/icons";
import * as Yup from "yup";
import {TextField, Button} from "@material-ui/core";
import { useAlert } from "react-alert";

import SectionHeader from "../../../components/layout/SectionHeader";
import * as TeamService from "../../../services/TeamService";
import * as Constants from "../../../Constants"
import * as AlertService from "../../../AlertService"
import useIsNew from "../../../hooks/UseIsNew";
import FormErrorBlock from "../../../components/form-control/FormErrorBlock";
import { PatientTable } from "../../Patient";
import usePagination from "../../../hooks/UsePagination";
import {findPatientsByFilter} from "../../../services/PatientService";

function TeamPage({intl, ...props}) {

    // state
    let [isNewTeam, existingTeamId] = useIsNew("/team-new", "/team-edit/:id");
    const [team, setTeam] = useState(null);
    
    const alert = useAlert();
    const history = useHistory();
    const {page, rows, setFilter, setPage, totalElements, commit} = usePagination(findPatientsByFilter);

    // populate fields effect
    useEffect(() => {
        async function fetchTeam() {
            if (!isNewTeam) {
                const fetchResult = await TeamService.findTeamById(existingTeamId);
                if (fetchResult.status === Constants.STATUTS_200) {
                    setTeam(await fetchResult.json());
                }
            } else {
                setTeam(null);
            }
        };
        fetchTeam();
    }, [isNewTeam, existingTeamId]);

    useEffect(() => {
        async function populateFields() {
            if (team) {
                formik.setFieldValue("name", team.name);
            } else {
                formik.resetForm();
            }
        };
        populateFields();
    }, [team]);

    useEffect(() => {
        async function setPatientFilter() {
            setFilter({team: existingTeamId});
            commit();
        }
        // don't perform the search if in new team page
        if (existingTeamId) {
            setPatientFilter();
        }
    }, [commit, setFilter, existingTeamId]);

    //
    async function handleSubmit(values) {
        let result;
        let success = false;
        if (isNewTeam) {
            result = await TeamService.addTeam(values);
            if (result.status === Constants.STATUTS_201) {
                let team = await result.json();
                history.push(`/team-edit/${team.id}`);
                success = true
            }
        } else {
            result = await TeamService.updateTeam(existingTeamId, values);
            if (result.status === Constants.STATUTS_200) {
                success = true
            }
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        commit();
    };

    // formik
    const formSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema: formSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <Card.Body>
                    <SectionHeader messageId="GENERAL.DATA"/>
                    <Row>
                        <Col md={6}>
                            <TextField
                                label={intl.formatMessage({id: "FIELD.TEAM_NAME"})}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                {...formik.getFieldProps("name")}
                            />
                            <FormErrorBlock
                                formik={formik}
                                fieldName="name"
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!formik.isValid || !formik.dirty}
                        endIcon={<Save/>}
                    >
                        <FormattedMessage id="ACTIONS.SAVE" />
                    </Button>
                </Card.Footer>
            </form>
        </Card>
        {!isNewTeam &&
            <Card>
                <Card.Body>
                <PatientTable
                    page={page}
                    rows={rows}
                    totalElements={totalElements}
                    handleChangePage={handleChangePage}
                    {...props}
                />
                </Card.Body>
            </Card>
        }
        </>
    )
}

export default injectIntl(TeamPage); 
