import React from "react";
import { VictoryContainer, VictoryLine } from "victory";

/**
 *
 * @param props read source for detailed description
 * @param {boolean} props.isConnected Whether the sensor is connected or not
 * @param props.isEcgLive Whether the ecg data is live or old
 * @param props.ecg The ecg data
 * @param props.domain A Victory domain object specifying the portion of data to be shown.
 * @param props.dimensions An object with width and height properties for the dimensions of the graph.
 * @returns
 */
export function EcgChart({ isConnected, isEcgLive, ecg, dimensions, domain }) {
    return (
        <>
            {isConnected ? (
                isEcgLive && ecg.length > 0 ? (
                    <VictoryLine
                        padding={{ top: 10, bottom: 10, right: 0, left: 0 }}
                        height={dimensions.height}
                        width={dimensions.width}
                        domain={domain}
                        containerComponent={
                            <VictoryContainer responsive={false} />
                        }
                        style={{ data: { stroke: "#02FC02", strokeWidth: 1 } }}
                        data={ecg}
                    />
                ) : (
                    <div style={{ padding: "50px" }}>
                        <h1 className="text-white">MEASURE IN THE PAST</h1>
                    </div>
                )
            ) : (
                <div style={{ padding: "50px" }}>
                    <h1 className="text-white">SENSOR DISCONNECTED</h1>
                </div>
            )}
        </>
    );
}
