import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { injectIntl } from "react-intl";
import { SipProvider } from 'react-sip';


function VoipQuattro() {
    const subHeader = useSubheader();
    subHeader.setTitle("Voip");

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loggedUserVoipUsername = sessionStorage.getItem('loggedUserVoipUsername');
    const loggedUserVoipPassword = sessionStorage.getItem('loggedUserVoipPassword');

    return (
        <>
            <SipProvider
                host="voip.patientmonitoring.eositaly.it"
                port={7443}
                pathname="" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
                user={loggedUserVoipUsername}
                password={loggedUserVoipPassword} // usually required (e.g. from ENV or props)
                autoRegister={true} // true by default, see jssip.UA option register
                autoAnswer={false} // automatically answer incoming calls; false by default
                iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
                sessionTimersExpires={120} // value for Session-Expires header; 120 by default
                extraHeaders={{ // optional sip headers to send
                    register: ['X-Foo: foo', 'X-Bar: bar'],
                    invite: ['X-Foo: foo2', 'X-Bar: bar2']
                }}
                iceServers={[ // optional
                    { urls: ['stun:a.example.com', 'stun:b.example.com'] },
                ]}
                debug={true} // whether to output events to console; false by default

            >
            </SipProvider>
        </>
    );
}


export default injectIntl(VoipQuattro);