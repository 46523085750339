import * as Constants from "../Constants";
import { getAPIIncludeAuth, postAPIIncludeAuth } from "./Shared";

export async function addVisit(patientId, visit) {
    const api = Constants.BACKEND_URL + Constants.API_VISIT + `/${patientId}`;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(visit),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    );
}

export async function getReportVisits({teamId, gpId}) {
    if (teamId) {
        let api = Constants.BACKEND_URL + Constants.API_VISIT + `/report/${teamId}`;
        api = gpId ? `${api}?gpId=${gpId}` : api;
        return getAPIIncludeAuth(
            api,
            {
                'Accept': 'application/json',
            }
        );
    }
    return {}
}

export async function getLatestVisits() {
    const api = Constants.BACKEND_URL + Constants.API_VISIT + "/latest";
    return getAPIIncludeAuth(
        api,
        {
            'Accept': 'application/json',
        }
    );
}

export async function printVisit(id) {
    const api = Constants.BACKEND_URL + Constants.API_VISIT + `/print/${id}`;
    return getAPIIncludeAuth(
        api,
        {
            'Accept': 'application/pdf',
        }
    );
}

export async function getVisit(id) {
    const api = Constants.BACKEND_URL + Constants.API_VISIT + `/${id}`;
    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/json",
        }
    );
}

export async function sendMail(id) {
    const api = Constants.BACKEND_URL + Constants.API_VISIT + `/mail/${id}`;
    return postAPIIncludeAuth(api);
}
