import React from "react";
import { VictoryLabel, VictoryPie } from "victory";

export function PathologyPieChart({data, }) {

    const colors = Object.entries({
        "Plump Purple": "#6f42c1",
        "Tiffany Blue": "#1bc5bd",
        "Azure": "#007bff",
        "Pakistan Green": "#036d19",
        "Xiketic": "#18020c",
        "Amber SAE ECE": "#ff7d00",
        "Jonquil": "#f9c80e",
        "Rich Black FOGRA 29": "#071013",
        "Cordovan": "#984447",
        "Imperial Red": "#ef233c"
    }).map((color) => color[1]);

    return (
        <>
        <svg viewBox="0 0 400 400">
            <VictoryPie
                standalone={false}
                colorScale={colors}
                labels={({ datum }) => datum.count}
                data={data}
                x={"therapy.historicName"}
                y={"count"}
                innerRadius={80}
            />
            <VictoryLabel
                textAnchor="middle"
                style={{
                    fontSize: "40px"
                }}
                x={200} y={200}
                text={data.reduce((prev, curr) => prev + curr.count, 0)}
            />
        </svg>
        <ul style={{ listStyleType: "none" }}>
            {data.map((item, index) => (
                <li style={{ marginBottom: "6px" }} key={item._id}>
                    <div className="li-type-monitoring-pie" style={{ backgroundColor: colors[index % colors.length] }} />
                    {item?.pathology?.historicName}
                </li>
            ))}
        </ul>
        </>
    )
}
