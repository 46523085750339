import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { injectIntl } from "react-intl";

import * as JsSIP from 'jssip'




function Voiptre() {
    const subHeader = useSubheader();
    subHeader.setTitle("Voip Tre");


    useEffect(() => {
        // doctorPhone.start();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    var socket = new JsSIP.WebSocketInterface('wss://voip.patientmonitoring.eositaly.it:7443');
    var configuration = {
        sockets: [socket],
        uri: 'sip:1011@voip.patientmonitoring.eositaly.it',
        password: 'UAiWFkJsmf',
        realm: 'voip.patientmonitoring.eositaly.it'
        // session_timers: false
    };

    var doctorPhone = new JsSIP.UA(configuration);
    doctorPhone.start();

    // var remoteAudio = document.getElementById('remoteAudio');

    const audio = new window.Audio();
    const video = document.getElementById("remoteVideo");

    doctorPhone.on('connected', function (e) {
        console.log("Dottore Connesso");
        console.log(doctorPhone.isRegistered());
    });

    doctorPhone.on('registrationFailed', function (e) {
        console.log(e);
    });

    doctorPhone.on('newRTCSession', function (evt) {
        var session = evt.session;

        //Verifico se la chiamata è in uscita o in entrata
        if (session.direction === "incoming") {
            session.terminate();
        } else {
            console.log("Stai chiamando");
        }
    });

    const call = () => {
        const session = doctorPhone.call('sip:1001@voip.patientmonitoring.eositaly.it', {
            'eventHandlers': {
                'progress': function (e) {
                    console.log('call is in progress');
                },
                'failed': function (e) {
                    console.log('call failed with cause: ' + JSON.stringify(e));
                },
                'ended': function (e) {
                    console.log('call ended with cause: ' + e.cause);
                },
                'confirmed': function (e) {
                    // const remoteAudio = document.createElement('audio');
                    // remoteAudio.src = window.URL.createObjectURL(e.stream);
                    // remoteAudio.play();
                    console.log("Call confirmed");
                },
            },
            'mediaConstraints': { 'audio': true, 'video': true },
            'sessionTimersExpires': 120,
            'extraHeaders': ['X-Foo: foo', 'X-Bar: bar'],
            'pcConfig': {
                'iceServers': [
                    {
                        'urls': ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
                    }
                ]
            }
        });

        if (session.connection) {
            console.log('Connection is valid')

            session.connection.addEventListener("track", e => {
                console.log('Add connection track')
                if (e.track.kind === "audio") {
                    audio.srcObject = e.streams[0]
                    audio.play()
                } else if (e.track.kind === "video") {
                    video.srcObject = e.streams[0]
                    video.play()
                } else {
                    console.log("added track was neither audio nor video")
                }
            })
            session.connection.addEventListener('peerconnection', e => {
                console.log('Peer connection')
                audio.srcObject = e.stream
                audio.play()
            })
        } else {
            console.log('Connection is null')
        }
    }

    return (
        <>
            {/* <video id="remoteVideo"></video>
            <video id="localVideo" muted="muted"></video> */}
            <audio id="remoteAudio"></audio>
            <video id="remoteVideo"></video>
            <button className="btn-action btn-success" onClick={call}>Call</button>
        </>
    );
}


export default injectIntl(Voiptre);
