import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { useFormik } from "formik";
import SVG from "react-inlinesvg";
import {
    TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from "@material-ui/core";

import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import * as GrandPrixService from "./../../../services/GrandPrixService";
import * as Constants from "../../../Constants";
import usePagination from "../../../hooks/UsePagination";
import { useLang } from "../../../../_metronic/i18n";
import { ConfirmOrCancelDialog } from "../../../components/dialogs/ConfirmOrCancelDialog";
import "./GrandPrixList.css"

function GrandPrixList({intl, ...props}) {
    // state
    const {rows, page, setPage, setFilter, commit, totalElements} = usePagination(GrandPrixService.findGrandsPrixByFilter);
    const language = useLang();
    const [isActivateDialogOpen, setActivateDialogOpen] = useState(false);
    const [selectedGrandPrix, setSelected] = useState({});

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    // formik
    const formik = useFormik({
        initialValues: {
            name: "",
        },
        onSubmit: values => {
            setFilter(values);
            setPage(0);
            commit();
        },
    });

    // handler
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        commit();
    };

    const handleOpenActivateDialog = async (gp) => {
        setSelected(gp);
        setActivateDialogOpen(true);
    }

    const handleCloseActivateDialog = async () => {
        setActivateDialogOpen(false);
    }

    const handleActivateGrandPrix = async () => {
        await GrandPrixService.activateGrandPrix(selectedGrandPrix.id);
        setActivateDialogOpen(false);
        window.location.reload();
    }

    return(
        <Card>
            <CardBody>
                {/* START FILTRO */}
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="name"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("name")}
                            />
                        </div>
                        <div className="col-lg-3 mt-5">
                            <button
                                type="submit"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                    />
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
                {/* END FILTRO */}

                {/* START TABLE */}
                <TableContainer>
                    <Table aria-label="grand prix table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_GRAND_PRIX_NAME" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_START_DATE" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_END_DATE" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_ACTIVE" /></TableCell>
                                <TableCell align="center"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{new Date(row.startDate).toLocaleDateString(language)}</TableCell>
                                    <TableCell align="left">{new Date(row.endDate).toLocaleDateString(language)}</TableCell>
                                    <TableCell align="left">
                                        {row.active &&
                                        <span className="svg-icon svg-icon-xl svg-icon-success">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg")}
                                            />
                                        </span>
                                        }
                                    </TableCell>
                                    <TableCell align="left" className="grand-prix-table_icon-column">
                                        <NavLink
                                            title="Modifica Grand prix"
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            to={`/grand-prix-edit/${row.id}`}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                />
                                            </span>
                                        </NavLink>
                                        <span
                                            title={intl.formatMessage({id: "MENU.ACTIVATE_GRAND_PRIX"})}
                                            className="btn btn-icon btn-light btn-hover-success btn-sm"
                                            onClick={async () => handleOpenActivateDialog(row)}
                                            style={row.active ? {visibility: "hidden"} : {}}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-success">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Electric/Shutdown.svg")}
                                                />
                                            </span>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[Constants.rowsPerPage]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={Constants.rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
                {/* END TABLE */}
                <ConfirmOrCancelDialog
                    title={
                        <FormattedMessage
                        id="MENU.ACTIVATE_GRAND_PRIX_DIALOG_TITLE"
                        values={{name: selectedGrandPrix.name}}
                        />
                    }
                    content={
                        <FormattedMessage
                        id="MENU.ACTIVATE_GRAND_PRIX_DIALOG_CONTENT"
                        />
                    }
                    cancelMessage={
                        <FormattedMessage
                        id="ACTIONS.CANCEL"
                        />
                    }
                    confirmMessage={
                        <FormattedMessage
                        id="ACTIONS.CONFIRM"
                        />
                    }
                    open={isActivateDialogOpen}
                    handleClose={handleCloseActivateDialog}
                    handleConfirm={handleActivateGrandPrix}
                />
            </CardBody>
        </Card>
    );
}

export default injectIntl(GrandPrixList);
