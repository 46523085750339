import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

/**
 * 
 * @param {object} props
 * @param {bool} props.open Controls the open state of the dialog.
 * @param {JSX | string} props.title A string or a JSX element that serves as the title of the dialog.
 * @param {JSX | string} props.content
 * @param {JSX | string} props.cancelMessage
 * @param {JSX | string} props.confirmMessage
 * @param {Callable} props.handleClose A handler that will be called when the dialog is closed.
 * @param {Callable} props.handleConfirm A handler that will be called when the confirm option is chosen.
 * @param {boolean} props.danger If truthy, the dialog's style is modified to indicate that it requires confirming a dangerous action
 */
export function ConfirmOrCancelDialog({open, title, content, cancelMessage, confirmMessage, handleClose, handleConfirm, danger}) {

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
                    {cancelMessage}
                </button>
                <button type="button" className={`btn ${danger ? "btn-danger": "btn-primary"}`} onClick={handleConfirm}>
                    {confirmMessage}
                </button>
            </DialogActions>
        </Dialog>
    );
}
