import React, { useEffect, useState } from 'react';
import { injectIntl } from "react-intl";

import * as StringUtils from '../../StringUtils';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    toAbsoluteUrl
} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import {
    TextField
} from "@material-ui/core";

import * as UtilService from '../../services/UtilService';

function FamiliarityTab({ intl, ...props }) {

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [diagnosis, setDiagnosis] = useState([]);
    // const [selectedDrug, setSelectedDrug] = useState(null);

    // const [selectedDrugs, setSelectedDrugs] = useState([]);

    const chooseDiagnosi = async (e, value) => {
        if (!StringUtils.isEmpty(value) && value.length >= 3) {
            const listDiagnosis = await (await UtilService.getDiagnosi({ findAnywhere: value })).json();
            setDiagnosis(listDiagnosis.data);
        }
    }

    // const ChangeSelectedFarmaco = (e, value) => {
    //     if (value) {
    //         setSelectedDrug({
    //             ...selectedDrug,
    //             description: value.descrizione,
    //             package: value.confezione,
    //             minsanCode: value.codMin,
    //             activePrincipleCode: value.principioAttivo,
    //             activePrincipleDescription: value.principioAttivoDescrizioneEstesa,
    //             company: value.descrizioneDitta
    //         });

    //         setSelectedDrugs(selectedDrugs => [...selectedDrugs, { farmaco: value.descrizione, confezione: value.confezione }]);
    //     } else {
    //         setSelectedDrug(null);
    //     }
    //     setDrugs([])
    // }




    return (
        <>
            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Familiarità</h6>
                <hr style={{ margin: "0" }} />
            </div>

            <form >
                <div className="row">
                    <div className="col-md-6">
                        <Autocomplete
                            id="combo-box-demo"
                            onInputChange={chooseDiagnosi}
                            // onChange={ChangeSelectedFarmaco}
                            onClose={() => { setDiagnosis([]) }}
                            options={diagnosis}
                            noOptionsText=""
                            getOptionLabel={(option) => option.codicediagnosi + ' - ' + option.descrizionediagnosi}
                            renderInput={(params) => <TextField
                                {...params}
                                label={`Inserisci codice o descrizione patologia`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />}
                        />
                    </div>
                    <div className="col-md-3">
                        <TextField
                            id="outlined-normal"
                            label={`Grado di parentela`}
                            margin="normal"
                            variant="outlined"
                            size="small"
                            name="findAnywhere"
                        />
                    </div>
                    <div className="col-md-3 mt-5">
                        <button
                            type="button"
                            disabled={true}
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
                            </span>
                        </button>
                    </div>
                </div>
            </form>

            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Patologie ereditarie</h6>
                <hr style={{ margin: "0" }} />
            </div>
            <div className="row">
                <div className="col-md-12 d-flex mb-3">
                    <div className="labelInterventi ml-0">Codice:</div> V84.04 <div className="labelInterventi">Patologia:</div> SUSCETTIBILITA GENETICA AL TUMORE MALIGNO DELL'ENDOMETRIO <div className="labelInterventi">Parentela:</div> Zio materno
                                    <div style={{ position: "absolute", right: "15px" }}>
                        <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                        </span>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                        </span>
                    </div>
                </div>
                <div className="col-md-12 d-flex mb-3">
                    <div className="labelInterventi ml-0">Codice:</div> 524.64 <div className="labelInterventi">Patologia:</div> RUMORI DELL'ARTICOLAZIONE TEMPOROMANDIBOLARE NELL'APERTURA E/O CHIUSURA DELLA MANDIBOLA <div className="labelInterventi">Parentela:</div> Nonno paterno
                                    <div style={{ position: "absolute", right: "15px" }}>
                        <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                        </span>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                        </span>
                    </div>
                </div>
                <div className="col-md-12 d-flex mb-3">
                    <div className="labelInterventi ml-0">Codice:</div> 327.23 <div className="labelInterventi">Patologia:</div> APNEA OSTRUTTIVA NEL SONNO (DELL'ADULTO) (PEDIATRICA) <div className="labelInterventi">Parentela:</div> Padre
                                    <div style={{ position: "absolute", right: "15px" }}>
                        <span className="svg-icon svg-icon-md svg-icon-primary mr-3">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")} />
                        </span>
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")} />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(FamiliarityTab);