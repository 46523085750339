import React from "react";
import PropTypes from "prop-types";

/**
 * FormikErrorComponent is a better choice to use with Formik's own
 * `<ErrorMessage component={FormikErrorComponent}/>`.
 * This was created before I knew about ErrorMessage.
 */
function FormErrorBlock({ formik, fieldName }) {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
        return (
            <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                    {formik.errors[fieldName]}
                </div>
            </div>
        )
    }
    return null;
}

FormErrorBlock.propTypes = {
    formik: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
};

export default FormErrorBlock;
