import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

function DownloadApps() {
    return (
        <Card>
            <CardContent>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <h3>App Medico</h3>
                            <div className="d-flex justify-content-between">
                                <a href={()=> {}} className="btn btn-info"><AndroidIcon /></a>
                                <a href={()=> {}} className="btn btn-primary"><AppleIcon /></a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <h3>Formula Medicine</h3>
                            <div className="d-flex justify-content-between">
                                <a href={()=> {}} className="btn btn-info"><AndroidIcon /></a>
                                <a href={()=> {}} className="btn btn-primary"><AppleIcon /></a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </CardContent>
        </Card>
    );
}

export default DownloadApps;