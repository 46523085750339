import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";

import { useFormik } from "formik";
import * as Yup from "yup";

import { format as dateFormat } from 'date-fns';

import { formatTypeCovidExam, formatOutcomeCovidExam } from '../../Utils';

import * as Constants from '../../Constants';

import {
    TextField, MenuItem, FormControl, InputLabel, OutlinedInput, Select,
    RadioGroup, FormControlLabel, Radio, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TablePagination
} from "@material-ui/core";

import {
    toAbsoluteUrl
} from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import { makeStyles } from '@material-ui/core/styles';

import * as AlertService from '../../AlertService';
import { useAlert } from 'react-alert';

import * as CovidExamService from '../../services/CovidExamService';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function ExamsTab({ intl, ...props }) {
    const alert = useAlert();
    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
        search(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [rows, setRows] = useState([]);

    const search = async (page) => {
        const filter = {
            "patientId": props.patientId,
            "page": page,
            "limit": 5,
        }

        const rawResp = await CovidExamService.getCovidExamsByPatientId(filter);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            if (resp.data.length === 0) {
                setRows([]);
                setTotalElements(0);
            } else {
                setRows(resp.data);
                setTotalElements(resp.totalElements);
            }
        } else {
            setRows([]);
        }
    }

    // Table pagination properties
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        search(newPage);
    };


    // Nuovo Esame
    const newExamSchema = Yup.object().shape({
        typeExam: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        outcomeExam: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        dateExam: Yup.date()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    });

    const formik = useFormik({
        initialValues: {
            typeExam: '',
            outcomeExam: '',
            dateExam: '',
            patientId: props.patientId,
        },
        validationSchema: newExamSchema,
        onSubmit: values => {
            addCovidExam(values);
        },
    });

    const addCovidExam = async (newCovidExam) => {
        const rawData = await CovidExamService.addCovidExam(newCovidExam)
        if (rawData.status === Constants.STATUTS_201) {
            AlertService.success(alert);
            search(0);
            cleanFields();
        } else {
            AlertService.error(alert);
        }
    }

    const cleanFields = () => {
        formik.setFieldValue("typeExam", "");
        formik.setFieldValue("outcomeExam", "");
        formik.setFieldValue("dateExam", "");
    }


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Nuovo Esame</h6>
                        <hr style={{ margin: "0" }} />
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-3">
                                <TextField
                                    id="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                    label={`${intl.formatMessage({ id: 'COVID_CARD.DATE' })}`}
                                    type="date"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    className={classes.textField}
                                    name="dateExam"
                                    onChange={formik.dateExam}
                                    {...formik.getFieldProps("dateExam")}
                                />
                            </div>
                            <div className="col-md-3">
                                <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="outlined-tipoMonitoraggio-simple">
                                        <FormattedMessage id="COVID_CARD.EXAM_TYPE_LABEL" />
                                    </InputLabel>
                                    <Select
                                        name="typeExam"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeExam")}
                                        input={<OutlinedInput value={formik.typeExam} labelWidth={labelWidth} name="tipoMonitoraggio" id="outlined-tipoMonitoraggio-simple" />}
                                    >
                                        <MenuItem value={'TSR'}><FormattedMessage id="COVID_CARD.TYPE_EXAM_TSR" /></MenuItem>
                                        <MenuItem value={'IGC'}><FormattedMessage id="COVID_CARD.TYPE_EXAM_IGC" /></MenuItem>
                                        <MenuItem value={'IGM'}><FormattedMessage id="COVID_CARD.TYPE_EXAM_IGM" /></MenuItem>
                                        <MenuItem value={'TMP'}><FormattedMessage id="COVID_CARD.TYPE_EXAM_TMP" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-3 mt-5">
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="informazioni"
                                        className={classes.group}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("outcomeExam")}
                                        row
                                    >
                                        <FormControlLabel value="N" control={<Radio />} label={intl.formatMessage({ id: 'COVID_CARD.TYPE_EXAM_OUTCOME_NEGATIVE' })} />
                                        <FormControlLabel value="P" control={<Radio />} label={intl.formatMessage({ id: 'COVID_CARD.TYPE_EXAM_OUTCOME_POSITIVE' })} />
                                    </RadioGroup>
                                </FormControl>
                                <button
                                    type="submit"
                                    disabled={!formik.isValid || !formik.dirty}
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="my-4">
                        <h6 className="text-primary" style={{ margin: "0" }}>Lista Esami</h6>
                        <hr style={{ margin: "0" }} />
                    </div>
                    <div className="row">
                        <TableContainer>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"><FormattedMessage id="COVID_CARD.DATE" /></TableCell>
                                        <TableCell align="left"><FormattedMessage id="COVID_CARD.EXAM_TYPE" /></TableCell>
                                        <TableCell align="left"><FormattedMessage id="COVID_CARD.EXAM_OUTCOME" /></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="left">{row.dateExam ? dateFormat(new Date(row.dateExam), 'dd/MM/yyyy') : '---'}</TableCell>
                                            <TableCell align="left">{formatTypeCovidExam(row.typeExam)}</TableCell>
                                            <TableCell align="left">{formatOutcomeCovidExam(row.outcomeExam)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[Constants.rowsPerPage]}
                            component="div"
                            count={totalElements}
                            rowsPerPage={Constants.rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(ExamsTab);