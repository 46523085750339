import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { format as dateFormat } from 'date-fns';

import * as Constants from '../../Constants';

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

import * as PatientService from '../../services/PatientService';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function SaturationTab({ intl, ...props }) {
    const classes = useStyles();

    useEffect(() => {
        search(0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [rows, setRows] = useState([]);

    const search = async (page) => {
        const rawResp = await PatientService.getMeasurePaginationByMeasureType(props.patientId, Constants.MEASUREMENTS_TYPE_SPO2, page, Constants.rowsPerPage);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            if (resp.data.data.length === 0) {
                setRows([]);
                setTotalElements(0);
            } else {
                setRows(resp.data.data);
                setTotalElements(resp.data.totalElements);
            }
        } else {
            setRows([]);
        }
    }

    // Table pagination properties
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        search(newPage);
    };

    return (
        <>
            <div className="row">
                <div className="bg-light col px-6 py-8 rounded-xl mr-7">
                    {/* START TABLE */}
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><FormattedMessage id="COVID_CARD.DATE_HOUR" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="COVID_CARD.SATURATION_VALUE" /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">{row.timestamp ? dateFormat(new Date(row.timestamp), 'dd/MM/yyyy HH:mm') : '---'}</TableCell>
                                        <TableCell align="left">{row.value}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[Constants.rowsPerPage]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={Constants.rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                    {/* END TABLE */}
                </div>
            </div>
        </>
    );
}

export default injectIntl(SaturationTab);