import { createContext, useContext} from "react";

export const UserContext = createContext(null);

/*
 * A little bit of a hack to get intellisense
 */
class User {
    name;
    email;
    role;
}

/**
 * 
 * @returns {User}
 */
export default function useUser() {
    return useContext(UserContext);
}
