import React, { useState } from 'react';
import HistoricAlertModalBody from './HistoricAlertModalBody';
import CloseIcon from '@material-ui/icons/Close';
import { injectIntl } from "react-intl";
import { IconButton, Dialog, DialogContent, DialogTitle, Toolbar } from "@material-ui/core";

function HistoricAlertModal({ intl, ...props }) {

    const [open, setOpen] = useState(props.isModalOpen);

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };


    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="Close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle id="form-dialog-title"> Notifications di: <strong>{props.selectedPatient.firstName} {props.selectedPatient.lastName}</strong></DialogTitle>
                </Toolbar>
                <DialogContent>
                    <HistoricAlertModalBody patientId={props.selectedPatient.id} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default injectIntl(HistoricAlertModal);
