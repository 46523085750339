import React from 'react';
import { injectIntl } from "react-intl";
import { format as dateFormat } from 'date-fns';

import { useFormik } from "formik";

import {
    TextField, Checkbox, FormGroup, FormLabel, FormControlLabel, FormControl
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function SymptomsTab({ intl, ...props }) {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            typeExam: '',
            outcomeExam: '',
            dateExam: '',
            patientId: props.patientId,
        },
        //validationSchema: newExamSchema,
        onSubmit: values => {
            //addCovidExam(values);
        },
    });

    return (
        <>
            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Sintomi generali</h6>
                <hr style={{ margin: "0" }} />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Generali</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Dolori osseo articolari"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="Cefalea"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="asma"
                                control={<Checkbox color="primary" />}
                                label="Anosmia"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="orticaria"
                                control={<Checkbox color="primary" />}
                                label="Disgeusia"
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Apparato respiratorio</h6>
                <hr style={{ margin: "0" }} />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Apparato respiratorio</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Rinite"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Tosse"
                                control={<Checkbox color="primary" />}
                                label="Tosse"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Dispnea"
                                control={<Checkbox color="primary" />}
                                label="Dispnea"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="orticaria"
                                control={<Checkbox color="primary" />}
                                label="Costrizione toracica"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="orticaria"
                                control={<Checkbox color="primary" />}
                                label="Espettorato"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="orticaria"
                                control={<Checkbox color="primary" />}
                                label="Emottisi"
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
            <div className="my-4">
                <h6 className="text-primary" style={{ margin: "0" }}>Sintomi gastrointestinali</h6>
                <hr style={{ margin: "0" }} />
            </div>
            <div className="row mb-3">
                <div className="col-12">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Sintomi gastrointestinali</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Nausea"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="Vomito"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="asma"
                                control={<Checkbox color="primary" />}
                                label="Diarrea"
                                labelPlacement="end"
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Isolamento domiciliare</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`Periodo isolamento da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Vaccino influenzale</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`Data vaccino`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">BPCO/ASMA</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">IRC</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Diabete</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Ipertensione</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Cardiopatia ischemica</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Scompenso cardiaco</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className="col-md-4">
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Fibrillazione atriale</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                value="rinite"
                                control={<Checkbox color="primary" />}
                                label="Sì"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="congiuntivite"
                                control={<Checkbox color="primary" />}
                                label="No"
                                labelPlacement="end"
                            />
                            <TextField
                                id="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ max: dateFormat(new Date(), 'yyyy-MM-dd') }}
                                label={`A partire da`}
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                className={classes.textField + " mx-5"}
                                name="dateExam"
                                onChange={formik.dateExam}
                                {...formik.getFieldProps("dateExam")}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        </>
    );
}

export default injectIntl(SymptomsTab);