import { useRouteMatch } from "react-router-dom";

/**
 * Allows checking whether the current page is meant to create a new resource or edit an existing one.
 * 
 * @param {string} pathIfNew
 * @param {string} pathIfEdit Must contain an `:id` parameter for the resource to edit
 * @returns [isNew, oldResourceId] oldResourceId is undefined if the resource is new
 */
export default function useIsNew(pathIfNew, pathIfEdit) {
    const match = useRouteMatch();
    let newResource;
    if (match.path === pathIfEdit) {
        newResource = false;
    } else if (match.path === pathIfNew) {
        newResource = true;
    } else {
        newResource = true;
    }
    return [newResource, match.params.id];
}
