import React, { useState } from 'react';
import { useAlert } from 'react-alert'; 
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage, injectIntl } from "react-intl";
import {
    TextField, IconButton, Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Toolbar,
} from "@material-ui/core";
import * as Constants from '../../Constants';

import * as PatientService from '../../services/PatientService';
import * as AlertService from '../../AlertService';

function NotificationModal({ intl, ...props }) {
    
    const alert = useAlert();

    const [open, setOpen] = useState(props.isModalOpen);

    const [selectedPatient] = useState(props.selectedPatient);
    const [messageNotification, setMessageNotification] = useState('');

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    };

    const sendNotification = () => {
        PatientService.sendNotification(selectedPatient.id, { 'message': messageNotification })
            .then(resp => {
                if (resp.status === Constants.STATUTS_201) {
                    AlertService.success(alert, `Notifica inviata con successo a ${selectedPatient.firstName} ${selectedPatient.lastName}`);
                    handleClose();
                } else {
                    AlertService.error(alert, `Errore durante l'invio della notifica a ${selectedPatient.firstName} ${selectedPatient.lastName}`);
                }
            });
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="Close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <DialogTitle id="form-dialog-title"><FormattedMessage id="NOTIFICATION.NOTIFICATION_FOR" /> {selectedPatient.firstName} {selectedPatient.lastName}</DialogTitle>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="NOTIFICATION.NOTIFICATION_DETAIL" /> {selectedPatient.firstName} {selectedPatient.lastName}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        label={`${intl.formatMessage({ id: 'NOTIFICATION.NOTIFICATION' })}`}
                        margin="dense"
                        variant="outlined"
                        size="small"
                        name="searchText"
                        fullWidth
                        onChange={(e) => { setMessageNotification(e.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-primary" disabled={messageNotification === '' || messageNotification === null || messageNotification === undefined}
                        onClick={sendNotification} color="primary">
                        <FormattedMessage id="ACTIONS.SEND" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default injectIntl(NotificationModal);
