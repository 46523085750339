import * as Constants from "../Constants";

import {getAPIIncludeAuth, postAPIIncludeAuth, deleteAPIIncludeAuth} from "./Shared";

export async function addUserDocument(file) {
    const api = Constants.BACKEND_URL + Constants.API_USER_DOCUMENT;
    const body = new FormData();
    body.set("file", file);

    return postAPIIncludeAuth(
        api,
        body,
    );
}

export async function deleteUserDocument(id) {
    const api = Constants.BACKEND_URL + Constants.API_USER_DOCUMENT + `/${id}`;

    return deleteAPIIncludeAuth(api);
}

export async function getAllUserDocuments() {
    const api = Constants.BACKEND_URL + Constants.API_USER_DOCUMENT;

    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/json",
        }
    );
}

export async function getUserDocument(id) {
    const api = Constants.BACKEND_URL + Constants.API_USER_DOCUMENT + `/${id}`;

    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/pdf",
        }
    );
}
