import * as Constants from "../Constants";
import { postAPIIncludeAuth } from "./Shared"

export async function printReport({teamId, ...values}) {
    let api = Constants.BACKEND_URL + Constants.API_REPORT + `/${teamId}`;
    values = {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, ...values}
    return postAPIIncludeAuth(
        api,
        JSON.stringify(values),
        {
            "Accept": "application/pdf",
            'Content-Type': 'application/json',
        },
    );
}
