import * as Constants from '../Constants';

export async function findDoctorsByFilter(filter) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_DOCTOR+`?findAnywhere=${filter.findAnywhere}&pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function findDoctorById(id) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_DOCTOR+`/${id}`;
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function addDoctor(doctor) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_DOCTOR;
    return await fetch (api, {
        method: Constants.METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
          },
        body: JSON.stringify(doctor)
    });
}

export async function updateDoctor(id, doctor) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_DOCTOR+`/${id}`;
    return await fetch (api, {
        method: Constants.METHOD_PUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
          },
        body: JSON.stringify(doctor)
    });
}

export async function deleteDoctor(id) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_DOCTOR+`/${id}`;
    return await fetch (api, {
        method: Constants.METHOD_DELETE,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}