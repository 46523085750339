import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@material-ui/core";
//Dialog
import { makeStyles } from '@material-ui/core/styles';
import { format as dateFormat } from 'date-fns';
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Card, CardBody } from "../../_metronic/_partials/controls";
import * as C from "../Constants";
import * as PatientService from '../services/PatientService';
import * as Utils from '../Utils';
import { formatTypeMonitoring, useQuery } from '../Utils';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

function PatientLiveMonitoring({ intl }) {
    let queryString = useQuery();

    useEffect(() => {
        search(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const [rows, setRows] = useState([]);
    let idsOnAir = [];

    const defineIdsOnAir = async () => {
        const respIdsOnAir = await (await PatientService.getIdsOnAir()).json();
        const arr = respIdsOnAir;
        return arr;
    }

    const search = async (page) => {
        const filter = {
            "findAnywhere": formik.getFieldProps("findAnywhere").value,
            "typeMonitoring": formik.getFieldProps("typeMonitoring").value,
            "enabled": formik.getFieldProps("enabled").value,
            "online": formik.getFieldProps("online").value,
            "lastMeasureAlert": formik.getFieldProps("lastMeasureAlert").value,
            "lastTemperatureAlert": formik.getFieldProps("lastTemperatureAlert").value,
            "lastSaturationAlert": formik.getFieldProps("lastSaturationAlert").value,
            "page": page,
            "limit": C.rowsPerPage,
        }

        const rawResp = await PatientService.findPatientsByFilter(filter);
        if (rawResp.status === 200) {
            const resp = await rawResp.json();
            if (resp.data.length === 0) {
                setRows([]);
                setTotalElements(0);
            } else {

                idsOnAir = await defineIdsOnAir();

                let patients = resp.data;
                patients.forEach(element => {
                    if (idsOnAir.includes(element.id)) {
                        element.isLive = true;
                    } else {
                        element.isLive = false;
                    }
                });

                setRows(resp.data);
                setTotalElements(resp.totalElements);
            }
        } else {
            setRows([]);
        }
    }

    //Pagination
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        search(newPage);
    };

    const formik = useFormik({
        initialValues: {
            findAnywhere: '',
            typeMonitoring: '',
            enabled: !Utils.isBlank(queryString.get('enabled')) ? 'S' : null,
            online: !Utils.isBlank(queryString.get('online')) ? queryString.get('online') : null,
            lastMeasureAlert: !Utils.isBlank(queryString.get('lastMeasureAlert')) ? queryString.get('lastMeasureAlert') : null,
            lastTemperatureAlert: !Utils.isBlank(queryString.get('lastTemperatureAlert')) ? queryString.get('lastTemperatureAlert') : null,
            lastSaturationAlert: !Utils.isBlank(queryString.get('lastSaturationAlert')) ? queryString.get('lastSaturationAlert') : null,
        },
        onSubmit: values => {
            search(0)
        },
    });

    //is on air signal
    const isOnAirSymbol = (isLive) => {
        if (isLive != null && isLive) {
            return (
                <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                </span>
            );
        } else {
            return (
                <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                </span>
            );
        }
    }

    //is on air signal


    return (
        <>
            <Card>
                <CardBody>
                    {/* START FILTRO */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="findAnywhere"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("findAnywhere")}
                                />
                            </div>
                            <div className="col-lg-3">
                                <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="outlined-tipoMonitoraggio-simple">
                                        <FormattedMessage id="FILTER.SEARCH_TYPE_MONITORING" />
                                    </InputLabel>
                                    <Select
                                        name="typeMonitoring"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeMonitoring")}
                                        input={<OutlinedInput value={formik.typeMonitoring} labelWidth={labelWidth} name="tipoMonitoraggio" id="outlined-tipoMonitoraggio-simple" />}
                                    >
                                        <MenuItem value={''}><FormattedMessage id="FIELD.TYPE_MONITORING_ALL" /></MenuItem>
                                        <MenuItem value={'C19'}><FormattedMessage id="FIELD.TYPE_MONITORING_C19" /></MenuItem>
                                        <MenuItem value={'IRES'}><FormattedMessage id="FIELD.TYPE_MONITORING_IRES" /></MenuItem>
                                        <MenuItem value={'ICAR'}><FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <div className="col-lg-3">
                                <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                                    <InputLabel ref={inputLabel} htmlFor="outlined-tipoMonitoraggio-simple">
                                        <FormattedMessage id="FILTER.SEARCH_TYPE_MONITORING" />
                                    </InputLabel>
                                    <Select
                                        name="typeMonitoring"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeMonitoring")}
                                        input={<OutlinedInput value={formik.typeMonitoring} labelWidth={labelWidth} name="tipoMonitoraggio" id="outlined-tipoMonitoraggio-simple" />}
                                    >
                                        <MenuItem value={''}><FormattedMessage id="FIELD.TYPE_MONITORING_ALL" /></MenuItem>
                                        <MenuItem value={'C19'}><FormattedMessage id="FIELD.TYPE_MONITORING_C19" /></MenuItem>
                                        <MenuItem value={'IRES'}><FormattedMessage id="FIELD.TYPE_MONITORING_IRES" /></MenuItem>
                                        <MenuItem value={'ICAR'}><FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </div> */}
                            <div className="col-lg-3 mt-5">
                                <button
                                    type="submit"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* END FILTRO */}

                    {/* START TABLE */}
                    <TableContainer>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LASTNAME" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FIRTSTNAME" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FISCAL_CODE" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_TYPE_MONITORING" /></TableCell>
                                    <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LAST_MEASURE" /></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="center"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">{row.lastName}</TableCell>
                                        <TableCell align="left">{row.firstName}</TableCell>
                                        <TableCell align="left">{row.fiscalCode}</TableCell>
                                        <TableCell align="left">{formatTypeMonitoring(row.typeMonitoring)}</TableCell>
                                        <TableCell align="left">{row.lastMeasurementDate ? dateFormat(new Date(row.lastMeasurementDate), 'dd/MM/yyyy HH:mm') : '---'}</TableCell>
                                        <TableCell align="left">{isOnAirSymbol(row.isLive)}</TableCell>
                                        <TableCell align="left">
                                            <span className="svg-icon svg-icon-md svg-icon-secondary">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Heart.svg")}></SVG>
                                            </span>
                                        </TableCell>
                                        <TableCell align="left">
                                            <span className="svg-icon svg-icon-md svg-icon-secondary">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Saturation.svg")}></SVG>
                                            </span>
                                        </TableCell>
                                        <TableCell align="left">
                                            {/* <div className="d-flex justify-content-between"> */}
                                            <div className="d-flex justify-content-center">
                                                <NavLink
                                                    title="Monitoraggio Live"
                                                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                                    to={`/patient-live-monitoring-detail/${row.id}`}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-scan.svg")}
                                                        />
                                                    </span>
                                                </NavLink>
                                                {/* <NavLink
                                                    title="Monitoraggio Live"
                                                    className="btn btn-icon btn-light btn-hover-warning btn-sm"
                                                    to={`/patient-live-monitoring-detail-rt/${row.id}`}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-warning">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-scan.svg")}
                                                        />
                                                    </span>
                                                </NavLink>
                                                <NavLink
                                                    title="Monitoraggio Live"
                                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                                    to={`/patient-live-monitoring-detail-old/${row.id}`}
                                                >
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-scan.svg")}
                                                        />
                                                    </span>
                                                </NavLink> */}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[C.rowsPerPage]}
                        component="div"
                        count={totalElements}
                        rowsPerPage={C.rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                    />
                    {/* END TABLE */}
                </CardBody>
            </Card>
        </>
    );
}

export default injectIntl(PatientLiveMonitoring);