import React, { useEffect } from "react";
import { useFormik } from "formik";
import SVG from "react-inlinesvg";
import {
    TextField, MenuItem, FormControl, InputLabel, OutlinedInput, Select
} from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";

import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import * as PatientService from '../../../services/PatientService';
import usePagination from "../../../hooks/UsePagination";
import PatientTable from "../components/PatientTable";
import {useTeams} from "../../Team";

function PatientListPage({ intl, ...props }) {
    const {rows, page, setPage, totalElements, setFilter, commit} = usePagination(PatientService.findPatientsByFilter);
    const teams = useTeams();

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        commit();
    };

    const formik = useFormik({
        initialValues: {
            findAnywhere: '',
            typeMonitoring: '',
        },
        onSubmit: values => {
            setFilter(values);
            setPage(0);
            commit();
        },
    });

    return (
        <>
            {/* <CallingModal key={isOpenCallingModal} isModalOpen={isOpenCallingModal} /> */}
            <Card>
                <CardBody>
                    {/* START FILTRO */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FILTER.SEARCH' })}`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="findAnywhere"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("findAnywhere")}
                                />
                            </div>
                            <div className="col-lg-3">
                                <FormControl margin="normal" size="small" variant="outlined">
                                    <InputLabel htmlFor="outlined-tipoMonitoraggio-simple">
                                        <FormattedMessage id="FILTER.SEARCH_TYPE_MONITORING" />
                                    </InputLabel>
                                    <Select
                                        name="typeMonitoring"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeMonitoring")}
                                        input={<OutlinedInput value={formik.typeMonitoring} name="tipoMonitoraggio" id="outlined-tipoMonitoraggio-simple" />}
                                    >
                                        <MenuItem value={''}><FormattedMessage id="FIELD.TYPE_MONITORING_ALL" /></MenuItem>
                                        <MenuItem value={'C19'}><FormattedMessage id="FIELD.TYPE_MONITORING_C19" /></MenuItem>
                                        <MenuItem value={'IRES'}><FormattedMessage id="FIELD.TYPE_MONITORING_IRES" /></MenuItem>
                                        <MenuItem value={'ICAR'}><FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-3 mt-5">
                                <button
                                    type="submit"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                        />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* END FILTRO */}
                    <PatientTable
                        rows={rows}
                        teams={teams}
                        handleChangePage={handleChangePage}
                        page={page}
                        totalElements={totalElements}
                        intl={intl}    
                    />
                    
                </CardBody>
            </Card>

        </>
    );
}

export default injectIntl(PatientListPage);
